// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-btn-link {
  color: var(--grayscale-light-secondary, #666);
  /* Desk/L */
  font-family: "Arabic Pro";
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em; }

.custom-btn-primary {
  padding: 16px 24px;
  border-radius: 8px;
  border: none;
  background-color: #2750fb; }
  .custom-btn-primary:hover, .custom-btn-primary:active, .custom-btn-primary:focus {
    background-color: #2750fb;
    color: #fff;
    opacity: 0.7; }

.custom-btn-default {
  border: 1px solid #e6e6e6;
  color: #050505;
  padding: 16px 24px;
  border-radius: 8px; }
  .custom-btn-default:hover, .custom-btn-default:focus {
    color: #050505;
    border: 1px solid #999999; }
`, "",{"version":3,"sources":["webpack://./src/components/common/menu/headerMenu/components/buttons/buttonCustom/buttonCustom.scss"],"names":[],"mappings":"AAAA;EACE,6CAA6C;EAC7C,WAAA;EACA,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB,EAAA;;AAEpB;EAEE,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB,EAAA;EAL3B;IAUI,yBAAyB;IACzB,WAAW;IACX,YAAY,EAAA;;AAGhB;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,kBAAkB,EAAA;EAJpB;IASI,cAAc;IACd,yBAAyB,EAAA","sourcesContent":[".custom-btn-link {\n  color: var(--grayscale-light-secondary, #666);\n  /* Desk/L */\n  font-family: \"Arabic Pro\";\n  font-size: 1em;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 1.5em;\n}\n.custom-btn-primary {\n  // @apply bg-[#2750fb] hover:bg-[#20B7F5] active:bg-[#20B7F5] focus:bg-[#20B7F5] border-none rounded-[8px] px-[24px] py-[16px];\n  padding: 16px 24px;\n  border-radius: 8px;\n  border: none;\n  background-color: #2750fb;\n\n  &:hover,\n  &:active,\n  &:focus {\n    background-color: #2750fb;\n    color: #fff;\n    opacity: 0.7;\n  }\n}\n.custom-btn-default {\n  border: 1px solid #e6e6e6;\n  color: #050505;\n  padding: 16px 24px;\n  border-radius: 8px;\n  // @apply rounded-[8px] px-[24px] py-[16px];\n\n  &:hover,\n  &:focus {\n    color: #050505;\n    border: 1px solid #999999;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
