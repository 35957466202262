import { createAsyncThunk } from "@reduxjs/toolkit"
import { StatusCodeResponse } from "../constants"
import CategoryServices from "./services"

export const getCategoryList = createAsyncThunk(
  "GET_CATEGORY_LIST",

  async (params: any, { rejectWithValue, dispatch }) => {
    const res = await CategoryServices.getCategoryList(params)

    if (res.status === StatusCodeResponse.CREATED) {
      return res.data.data.docs
    }

    return rejectWithValue(null)
  }
)
