export const Endpoint = {
  LOGIN: "/v1/user/login",
  CREATE_NONCE: "/v1/user/create-nonce",
  REGISTER: "/v1/user/register",
  SEND_OTP: "/v1/user/send-otp",
  LIST_ON_SALE: "/market-place/list-item",
  DETAIL_ON_MARKET: "market-place/item",
  DETAIL_ON_INVENTORY: "item/",
  DETAIL_ON_EVEMT: "/item/event/",
  LIKE_NFT: "market-place/like",
  DISLIKE_NFT: "market-place/dislike",
  USER_COLLECTION: "item/user-collection",
  LIST_COLLECTION: "item/list-collection",
  USER_TOP_SELLER: "/v1/user/top-seller",
  USER_TOP_CREATER: "/v1/user/top-creator",
  USER_TOP_COLLECTION: "/v1/user/top-collection",
  LIKE_COLLECTION: "item/collection/like",
  DISLIKE_COLLECTION: "item/collection/dislike",
  NOTIFICATION_LIST: "/notifications/search",
  READ_NOTIFICATION_LIST: "/notification/change-status",
  READ_ALL_NOTIFICATION_LIST: "notification/read-all",
  GET_NOTIFICATION_SETTING_LIST: "notification/setting",
  POST_NOTIFICATION_SETTING_LIST: "notification/update-setting",
  HOT_BRANDS: "market-place/list-brand",
  HOT_BRANDS_NEW: "market-place/list-brand-image",
  HOT_AUCTIONS: "market-place/list-item",
  BANNERS: "market-place/setting/default",
  CATEGORY_LIST: "categories/search",
  NFT: "nfts",
}
