import { Typography } from "antd"
import React from "react"

interface ICartTitleProps {
  countItems: number
  children: React.ReactNode
}

const CartTitle = ({ countItems, children }: ICartTitleProps) => {
  return (
    <div className="cart-title">
      <Typography>{countItems} items</Typography>
      {children}
    </div>
  )
}

export default CartTitle
