import { createSlice } from "@reduxjs/toolkit"
import * as actions from "./actions"

export interface ModalAppState {
  modalBuyNow: {
    toggle: boolean
    data: any
  }
  modalBidNow: {
    toggle: boolean
    data: any
  }
  modalSuccess: {
    toggle: boolean
    data: any
  }
  modalAddFund: {
    toggle: boolean
    data?: any
  }
  modalConnectWallet: {
    toggle: boolean
  }
}

const initialState: ModalAppState = {
  modalBuyNow: {
    toggle: false,
    data: {},
  },
  modalBidNow: {
    toggle: false,
    data: {},
  },
  modalSuccess: {
    toggle: false,
    data: {},
  },
  modalAddFund: {
    toggle: false,
    data: {},
  },
  modalConnectWallet: {
    toggle: false,
  },
}

const slice = createSlice({
  name: "modal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.setModalBuyNow, (state, { payload }) => {
      state.modalBuyNow = payload
    })
    builder.addCase(actions.setModalBidNow, (state, { payload }) => {
      state.modalBidNow = payload
    })
    builder.addCase(actions.setModalSuccess, (state, { payload }) => {
      state.modalSuccess = payload
    })
    builder.addCase(actions.setModalAddFund, (state, { payload }) => {
      state.modalAddFund = payload
    })
    builder.addCase(actions.setModalConnectWallet, (state, { payload }) => {
      state.modalConnectWallet = payload
    })
  },
})

export default slice.reducer
