import {
  getAccessToken,
  getAuthorizationHeader,
  getUserId,
} from "../../utils/authentication"
import { getParamsForApiList } from "../../utils/util"
import { get, patch, post } from "../axiosClient"
import {
  CreateProfilePayload,
  FollowParams,
  FollowResponse,
  GetNFTItemsParams,
  GetNFTItemsResponse,
  GetUserDetailResponse,
  ListTabProfileResponse,
  TabParams,
  UpdateProfileParams,
  UpdateProfileResponse,
  UserViewParams,
} from "./types"

const myProfileServices = {
  getUserDetail(params: UserViewParams): Promise<GetUserDetailResponse> {
    return get(
      `/v1/user/detail${getParamsForApiList(params)}`,
      getAccessToken()
        ? {
            headers: getAuthorizationHeader(),
          }
        : {}
    )
  },

  getNFTItems(params: GetNFTItemsParams): Promise<GetNFTItemsResponse> {
    return get(
      `/item/list${getParamsForApiList(params)}`,
      getUserId() !== ""
        ? {
            headers: getAuthorizationHeader(),
          }
        : {}
    )
  },

  changeProfile(params: UpdateProfileParams): Promise<UpdateProfileResponse> {
    return patch("/v1/user/change-profile", params, {
      headers: getAuthorizationHeader(),
    })
  },

  getListTabProfile(params: TabParams): Promise<ListTabProfileResponse> {
    return get(
      `/v1/user/profile-statistic${getParamsForApiList(params)}`,
      params?.user_id || getUserId() === ""
        ? {}
        : { headers: getAuthorizationHeader() }
    )
  },

  getUserView(params: UserViewParams): Promise<GetUserDetailResponse> {
    return get(
      `/v1/user/detail${getParamsForApiList(params)}`,
      params?.user_id || getUserId() === ""
        ? {}
        : { headers: getAuthorizationHeader() }
    )
  },

  postFollow(params: FollowParams): Promise<FollowResponse> {
    return post("/v1/user/follow", params, {
      headers: getAuthorizationHeader(),
    })
  },

  postUnFollow(params: FollowParams): Promise<FollowResponse> {
    return post("/v1/user/unfollow", params, {
      headers: getAuthorizationHeader(),
    })
  },

  getMyProfile(): Promise<any> {
    return get("/profiles/current/me", { headers: getAuthorizationHeader() })
  },

  createMyProfile(payload: CreateProfilePayload): Promise<any> {
    return post("/profiles", payload, {
      headers: getAuthorizationHeader(),
    })
  },

  updateProfile(id: string, payload: CreateProfilePayload): Promise<any> {
    return patch(`profiles/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    })
  },
}

export default myProfileServices
