import type {
  ExternalProvider,
  JsonRpcFetchFunc,
} from "@ethersproject/providers"
import { Web3Provider } from "@ethersproject/providers"

import {
  useWeb3React,
  Web3ReactHooks,
  Web3ReactProvider,
} from "@web3-react/core"
import type { MetaMask } from "@web3-react/metamask"
import type { TrustWallet } from "@trustwallet/web3-react-trust-wallet"
import type { Network } from "@web3-react/network"

import {
  metaMask,
  hookMetamask,
  trustwallet,
  hooksTrust,
  network,
  hookNetwork,
} from "./connectors"

export default function getLibrary(
  provider: ExternalProvider | JsonRpcFetchFunc
) {
  let nameProvider = localStorage.getItem("provider") || "metamask"
  if (nameProvider == "metamask") {
    let provider: any = window.ethereum
    return new Web3Provider(provider)
  } else if (nameProvider == "bitkeep") {
    return new Web3Provider(window.bitkeep.ethereum)
  } else {
    let provider: any = window.ethereum
    return new Web3Provider(provider)
  }
}

export const connectors: [MetaMask | TrustWallet | Network, Web3ReactHooks][] =
  [
    [metaMask, hookMetamask],
    [trustwallet, hooksTrust],
    [network, hookNetwork],
  ]
