import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";
import { useEthersSigner } from "./useEthersSigner";
import { provider } from "utils/providers";
import { supportChain } from "@/utils/constant";
import BigNumber from "bignumber.js";

export default function useContract<T extends Contract = Contract>(
  address: string,
  ABI: any
): T | null {
  const signer = useEthersSigner();

  return useMemo(() => {
    if (!address || !ABI || !provider) {
      return null;
    }

    try {
      const library =
        !!signer &&
        new BigNumber(signer.provider.network.chainId).eq(supportChain)
          ? signer
          : provider;
      return new Contract(address, ABI, library);
    } catch (error) {
      console.error("Failed To Get Contract", error);

      return null;
    }
  }, [address, ABI, signer, provider]) as T;
}
