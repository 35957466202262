import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../app/type"
import { getActivity } from "../../../network/activity/thunk"
import { MetaAuction } from "../../../network/event/event1m/type"
import { ActivityStore } from "./type"

const initialState: ActivityStore = {
  activity: [],
  metaActivity: <MetaAuction>{},
}

export const slice = createSlice({
  name: "activity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivity.fulfilled, (state, action) => {
      if (action.type === "activity/fulfilled") {
        state.activity = [...action.payload.data]
        state.metaActivity = {
          ...state.metaActivity,
          limit: action.payload?.limit,
          page: action.payload?.page,
          totalRecord: action.payload?.totalRecord,
          meta: action.payload?.meta_data,
        }
      }
    })
  },
})

export const selectActivity = (state: Store) => state.activity.activity

export const selectMetaActivity = (state: Store) => state.activity.metaActivity

export default slice.reducer
