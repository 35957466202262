import Web3 from "web3"
import { PROVIDER_NETWORK_URL } from "./constant"

class Web3Contract {
  address: string

  web3: any

  contract: any

  constructor(address: string, abi?: any) {
    this.address = address
    this.web3 = new Web3(new Web3.providers.HttpProvider(PROVIDER_NETWORK_URL))
    this.contract = new this.web3.eth.Contract(abi, address)
  }

  async getInfo(funcName: any) {
    try {
      return await this.contract.methods[funcName]().call()
    } catch (error) {
      console.error("getInfo", error)
      return false
    }
  }

  async symbol() {
    const response = await this.contract.methods.symbol().call()
    return response
  }

  async decimals() {
    const response = await this.contract.methods.decimals().call()
    return response
  }

  async balanceOf(address: string) {
    const response = await this.contract.methods.balanceOf(address).call()
    return response
  }

  async getFullInfo() {
    return Promise.all([await this.symbol(), await this.decimals()])
      .then(([symbol, decimals]) => {
        return { address: this.address, symbol, decimals }
      })
      .catch((error) => {
        console.error("E0089", error)
        return null
      })
  }

  async totalSupply() {
    const decimals = await this.decimals()
    const response =
      (await this.contract.methods.totalSupply().call()) / 10 ** decimals
    return response
  }

  async getLastedBlockNumber() {
    try {
      return await this.web3.eth.getBlockNumber()
    } catch (error) {
      return false
    }
  }

  async getEventLogContract(event: any, fromBlock: any, toBlock: any) {
    try {
      const topic = this.contract.events[event]
      return await this.contract.getPastEvents(event, {
        fromBlock,
        toBlock,
        topic,
      })
    } catch (error) {
      return false
    }
  }
}

export default Web3Contract
