import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../../app/type"
import {
  getCollectionDetail,
  getListCollection,
  getListNFTCollection,
  getUserCollection,
  getUserCollectionSearch,
} from "../../../../network/collection/thunk"
import { CollectionStore, MetaCollection } from "./type"

const initialState: CollectionStore = {
  collectionList: [],
  meta: <MetaCollection>{},
  collections: [],
  collectionDetail: {},
  listNFTCollection: [],
  metaListNFTCollection: {},
  collectionListSearch: [],
}

const slice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    clearCollectionList: (state) => {
      state.collectionList = []
    },
    clearCollectionListNFT: (state) => {
      state.listNFTCollection = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserCollection.fulfilled, (state, action) => {
      if (Array.isArray(action.payload.data)) {
        state.collectionList = [...action.payload.data]
        state.collectionListSearch = [...action.payload?.data]
      }

      state.meta = {
        ...action.payload.meta,
      }
    })

    builder.addCase(getListCollection.fulfilled, (state, action) => {
      if (Array.isArray(action.payload.docs)) {
        state.collections = [...action.payload.docs]
      }
    })

    builder.addCase(getCollectionDetail.fulfilled, (state, action) => {
      state.collectionDetail = action.payload
    })

    builder.addCase(getListNFTCollection.fulfilled, (state, action) => {
      state.listNFTCollection = action.payload.data
      state.metaListNFTCollection = {
        ...action.payload.meta,
      }
    })
    builder.addCase(getUserCollectionSearch.fulfilled, (state, action) => {
      if (Array.isArray(action.payload?.data)) {
        state.collectionListSearch = [...action.payload.data]
      }
    })
  },
})

export const { clearCollectionList, clearCollectionListNFT } = slice.actions

export const selectCollectionList = (state: Store) =>
  state.collection.collectionList
export const selectMetaCollection = (state: Store) => state.collection.meta
export const selectListCollection = (state: Store) =>
  state.collection.collections
export const selectCollectionDetail = (state: Store) =>
  state.collection.collectionDetail
export const selectListNFTCollection = (state: Store) =>
  state.collection.listNFTCollection
export const selectMetaListNFTCollection = (state: Store) =>
  state.collection.metaListNFTCollection
export const selectCollectionListSearch = (state: Store) =>
  state.collection.collectionListSearch

export default slice.reducer
