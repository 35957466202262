import { get } from "../axiosClient"
import { GetActivityResponse } from "./type"
import { getParamsForApiList } from "../../utils/util"
import { getAuthorizationHeader } from "../../utils/authentication"
import { GetActivitiesParams } from "../myProfile/types"

const activitiesServices = {
  getActivitiesProfile(
    params: GetActivitiesParams
  ): Promise<GetActivityResponse> {
    return get(`v1/user/activities${getParamsForApiList(params)}`, {
      headers: getAuthorizationHeader(),
    })
  },

  getActivity(params: GetActivitiesParams): Promise<GetActivityResponse> {
    return get(`common/activities${getParamsForApiList(params)}`)
  },

  getActivityCollection(
    params: GetActivitiesParams
  ): Promise<GetActivityResponse> {
    const newParams = {
      type: params.type,
    }
    return get(
      `item/collection/${params.id}/history${getParamsForApiList(newParams)}`
    )
  },
}

export default activitiesServices
