import { getUnreadNotification } from "@/components/common/menu/notification/store/slice"
import { selectMyProfile } from "@/components/myProfile/store/slice"
import notificationServices from "@/network/notifications/services"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const useCheckNotification = (): any => {
  const [data, setData] = useState(false)
  const myProfile = useSelector(selectMyProfile)
  const dispatch = useDispatch()

  const getData = useCallback(async () => {
    if (myProfile) {
      try {
        const res = await notificationServices.getAllNotifications({
          skip: 0,
          limit: 10,
          sort: { field: "created_at", order: -1 },
          filter: [
            { name: "user", value: myProfile?.user?.id, operator: "eq" },
            { name: "isRead", value: false, operator: "eq" },
          ],
        })

        dispatch(getUnreadNotification(!!res?.data?.data?.docs?.length))
      } catch (error) {
        console.error("useCheckNotification", error)
      }
    }
  }, [myProfile])

  useEffect(() => {
    getData()
  }, [getData])

  return { checkUnreadNotification: data, getUnreadNotification: getData }
}

export default useCheckNotification
