import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../../../app/type"
import { CheckUpload } from "./type"

const initialState: CheckUpload = {
  checkUpload: true,
}

const slice = createSlice({
  name: "checkUpload",
  initialState,
  reducers: {
    checkUploadSuccess: (state) => {
      state.checkUpload = true
    },
    checkUploadFail: (state) => {
      state.checkUpload = false
    },
  },
})

export const { checkUploadSuccess, checkUploadFail } = slice.actions
export const selectCheckUpload = (state: Store) => state.checkUpload

export default slice.reducer
