import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../../app/type"
import { EventProfileStore } from "./type"

const initialState: EventProfileStore = {
  listEvent: [
    {
      category_id: 3,
      collection_id: 13,
      contract_address: "0x8fEd6955669412751BAa84e079E24Cef3C0636f1",
      cover: "",
      created_at: "2022-04-28T09:25:27Z",
      created_user_id: 0,
      deleted_at: null,
      description: "dasd12 2",
      id: 11,
      image:
        "http://apipi.openlivenft.com/storage/4e384db4-1624-4b36-ba0f-d6ac382bff23.jpg",
      is_like: false,
      nft: "11",
      price: "2000000000000000000",
      seller: {
        avatar: "",
        cover: "",
        description: "",
        display_name: "",
        email: "",
        fb_link: "",
        ins_link: "",
        link_in_link: "",
        op: "",
        phone: "",
        ref: "",
        type: 0,
        wallet_address: "",
      },
      status: 2,
      title: "tiki",
      total_like: 0,
      transaction_id:
        "0x88c118bd6584e53aa2600b49c788eec0dc28d47237ca05ec88bfe712dbb170c4",
      updated_at: "2022-05-04T02:49:50Z",
      updated_user_id: 0,
      user_id: 10,
      user_likes: [],
      view: 0,
    },
    {
      category_id: 2,
      collection_id: 0,
      contract_address: "0x8fEd6955669412751BAa84e079E24Cef3C0636f1",
      cover: "",
      created_at: "2022-05-04T03:19:20Z",
      created_user_id: 0,
      deleted_at: null,
      description: "222222222222",
      id: 43,
      image:
        "http://apipi.openlivenft.com/storage/5c3974d6-33d1-4932-9e8b-4775a5bd0b43.jpg",
      is_like: false,
      nft: "34",
      price: "",
      seller: {
        avatar: "",
        cover: "",
        description: "",
        display_name: "",
        email: "",
        fb_link: "",
        ins_link: "",
        link_in_link: "",
        op: "",
        phone: "",
        ref: "",
        type: 0,
        wallet_address: "",
      },
      status: 5,
      title: "111111111111111111",
      total_like: 0,
      transaction_id:
        "0x0a5749492d6b13231fe7cd98568b0c0736f13c85928d3d1dc714fa162ad85f27",
      updated_at: "2022-05-17T09:01:01Z",
      updated_user_id: 0,
      user_id: 10,
      user_likes: [],
      view: 0,
    },
  ],
}

const slice = createSlice({
  name: "eventProfile",
  initialState,
  reducers: {},
})

export const selectListEvent = (state: Store) => state.eventProfile.listEvent

export default slice.reducer
