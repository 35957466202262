import { getAuthorizationHeader } from "../../utils/authentication"
import { getParamsForApiList } from "../../utils/util"
import { get, patch, post } from "../axiosClient"
import { Endpoint } from "../endpoint"
import {
  GetAllNotificationsRequest,
  GetNotificationsRequest,
  GetNotificationsResponse,
  PostNotificationsRequest,
  PostNotificationsResponse,
  PostNotificationsSettingRequest,
} from "./type"

const notificationServices = {
  getNotifications(
    params: GetNotificationsRequest
  ): Promise<GetNotificationsResponse> {
    return get(`${Endpoint.NOTIFICATION_LIST}${getParamsForApiList(params)}`, {
      headers: getAuthorizationHeader(),
    })
  },
  getAllNotifications(
    params: GetAllNotificationsRequest
  ) {
    return post(`${Endpoint.NOTIFICATION_LIST}`, params, {
      headers: getAuthorizationHeader(),
    })
  },
  postNotificationToken(
    token: string
  ) {
    return post("notification-token", {
      deviceToken: token,
      deviceType: "WEBSITE",
      isActive: true,
    }, {
      headers: getAuthorizationHeader(),
    })
  },
  postNotificationsReadOne(
    params: PostNotificationsRequest
  ): Promise<PostNotificationsResponse> {
    return post(Endpoint.READ_NOTIFICATION_LIST, params, {
      headers: getAuthorizationHeader(),
    })
  },
  postNotificationsReadAll(): Promise<PostNotificationsResponse> {
    return patch(
      "notifications/read-all",
      {},
      {
        headers: getAuthorizationHeader(),
      }
    )
  },
  getNotificationsSetting(): Promise<GetNotificationsResponse> {
    return get(Endpoint.GET_NOTIFICATION_SETTING_LIST, {
      headers: getAuthorizationHeader(),
    })
  },
  postNotificationsSetting(
    params: PostNotificationsSettingRequest
  ): Promise<PostNotificationsResponse> {
    return post(Endpoint.POST_NOTIFICATION_SETTING_LIST, params, {
      headers: getAuthorizationHeader(),
    })
  },
}

export default notificationServices
