import { getAuthorizationHeader } from "../../utils/authentication"
import { getParamsForApiList } from "../../utils/util"
import { get } from "../axiosClient"
import { Endpoint } from "../endpoint"
import {
  GetUserTopSellerRequest,
  GetUserTopSellerResponse,
  ParamsRanking,
} from "./type"

export const GetTopSellerServices = {
  getDataTopSeller(params: string): Promise<GetUserTopSellerResponse> {
    return get(`${Endpoint.USER_TOP_SELLER}${params}`, {
      headers: getAuthorizationHeader(),
    })
  },
  getDataTopCreater(params: ParamsRanking): Promise<GetUserTopSellerResponse> {
    return get(`${Endpoint.USER_TOP_CREATER}${getParamsForApiList(params)}`)
  },
  getDataTopCollection(
    params: ParamsRanking
  ): Promise<GetUserTopSellerResponse> {
    return get(`${Endpoint.USER_TOP_COLLECTION}${getParamsForApiList(params)}`)
  },
  getDataTopSellerNew(
    params: ParamsRanking
  ): Promise<GetUserTopSellerResponse> {
    return get(`${Endpoint.USER_TOP_SELLER}${getParamsForApiList(params)}`)
  },
}
