import useCheckWatchList from "@/hooks/useCheckWatchList"
import { StarFilled, StarOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"

const WatchListCell = ({
  onClick,
  data,
  myProfile,
  isWatchListData = false,
}: {
  onClick: any
  data: any
  myProfile: any
  isWatchListData?: boolean
}) => {
  const [isWatched, setIsWatched] = useState<boolean>(false)
  const { checkWatchList } = useCheckWatchList(data?.id, isWatchListData)

  useEffect(() => {
    if (isWatchListData) {
      setIsWatched(true)
      return
    }
    setIsWatched(checkWatchList)
  }, [checkWatchList, isWatchListData])

  return (
    <div
      className="icon-favorite"
      onClick={() => onClick(data, isWatched, setIsWatched)}
    >
      {isWatched ? <StarFilled /> : <StarOutlined />}
    </div>
  )
}

export default WatchListCell
