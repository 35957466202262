import { getParamsForApiList } from "../../../utils/util";
import { post, get } from "../../axiosClient";
import { searchParams, searchResponse } from "./type";

const searchActionServices = {
  searchProfile(params: searchParams): Promise<searchResponse> {
    return post("/profiles/search", params);
  },
  searchAddressProfile(params: any): Promise<searchResponse> {
    return get(`/profiles/wallet/${params.address}`);
  },

  searchCollection(params: searchParams): Promise<searchResponse> {
    return post("/collections/search", params);
  },

  searchNft(params: searchParams): Promise<searchResponse> {
    return post("/nfts/search", params);
  },
};

export default searchActionServices;
