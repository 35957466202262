export interface Token {
  name: string;
  symbol: string;
  address: string;
  decimals: number;
  icon: string;
}

export const uploadImageConstants = {
  typeInput: {
    MULTIPLE: true,
    SINGLE: false,
  },
  typeUpload: {
    COVER: "cover",
    AVATAR: "avatar",
    IMAGE: "item",
    COLLECTION: "collection",
  },
};

export const chain = [
  {
    id: "BNB_CHAIN",
    name: "BNB Smart Chain",
    link: process.env.REACT_APP_EXPLORER,
    hash: `${process.env.REACT_APP_EXPLORER}tx/`,
    address: `${process.env.REACT_APP_EXPLORER}address/`,
    token: `${process.env.REACT_APP_EXPLORER}token/`,
  },
];

export const RATIO_IMAG_SIZE = 0.88;

export const firebaseConfig = {
  apiKey: "AIzaSyDxDQQHqoRh8lraowGwXvNq3HLHuSSrOAU",
  authDomain: "test-cea64.firebaseapp.com",
  projectId: "test-cea64",
  storageBucket: "test-cea64.appspot.com",
  messagingSenderId: "135826731311",
  appId: "1:135826731311:web:016a3ec8286369d645f1da",
  measurementId: "G-3XYVBQ918B",
};

export const MonbaseCoin: Token = {
  name: "MonbaseCoin",
  symbol: "MBC",
  address: process.env.REACT_APP_MBC_ADDRESS,
  decimals: 18,
  icon: "https://assets.coingecko.com/coins/images/35838/standard/Token_MBC_BG_Square.png?1709976575",
};
