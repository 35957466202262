import { createSlice } from "@reduxjs/toolkit"

import { Store } from "./../../../../app/type"
import { getDiscover } from "./thunk"
import {
  Auction,
  MetaAuction,
} from "../../../../network/homepage/homeAuction/type"
import { HomeDiscoverStore } from "./type"

const initialState: HomeDiscoverStore = {
  nftDiscoverList: [],
  metaDiscover: <MetaAuction>{},
}

export const slice = createSlice({
  name: "homeDiscover",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDiscover.fulfilled, (state, action) => {
      if (action.type === "home/DISCOVER/fulfilled") {
        state.nftDiscoverList = [...action.payload.data]
        state.metaDiscover = {
          ...state.metaDiscover,
          limit: action.payload?.limit,
          page: action.payload?.page,
          totalRecord: action.payload?.totalRecord,
        }
      }
    })
  },
})

export const selectDiscoverList = (state: Store) =>
  state.homeDiscover.nftDiscoverList

export const selectMetaDiscover = (state: Store) =>
  state.homeDiscover.metaDiscover

export default slice.reducer
