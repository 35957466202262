import { ReactNode, useMemo } from "react";
import NavLinks from "./components/NavLinks";
import "./sideBar.scss";
import { useHistory } from "react-router-dom";
import Footer from "components/common/footer";
import { routes } from "router/constants";
import { getAccessToken } from "@/utils/authentication";
import ConnectWalletButton from "@/components/common/menu/headerMenu/components/buttons/ConnectWallet";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useAccount } from "wagmi";

interface ISideBarProps {
  children: ReactNode;
  setCloseNavbar: any;
  closeNavbar: boolean;
}

export const SIDEBAR_WIDTH_DEFAULT = 0;
export const SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE = 0;

const SideBarComponent = ({
  children,
  setCloseNavbar,
  closeNavbar,
}: ISideBarProps) => {
  const history = useHistory();
  const { address: account } = useAccount();

  const classNameClose = useMemo(() => {
    return closeNavbar ? "close" : "";
  }, [closeNavbar]);

  const isMobile = useMemo(() => {
    return window?.innerWidth < 768;
  }, [window?.innerWidth]);

  function handleBackToHome() {
    history.push({ pathname: routes.HOMEPAGE });
  }

  return (
    <>
      <nav className={`sidebar ${classNameClose}`}>
        <div className="flex items-center justify-between">
          <img
            src={require("../../../../../assets/images/new-design/logo-header1.png")}
            alt=""
            height={40}
            width={165}
            onClick={handleBackToHome}
          />
          <Button
            className="text-black"
            type="link"
            icon={<CloseOutlined />}
            onClick={() => setCloseNavbar(true)}
          />
        </div>

        <div className="menu_content">
          <NavLinks closeNavbar={closeNavbar} setCloseNavbar={setCloseNavbar} />
        </div>
        {(!account || !getAccessToken()) && (
          <div className="sidebar_footer">
            <ConnectWalletButton style={{ borderRadius: 30 }} />
          </div>
        )}
      </nav>
      <div className={isMobile ? "" : "body_content"}>{children}</div>

      <Footer closeNavbar={closeNavbar} isMobile={isMobile} />
    </>
  );
};

export const SideBar = SideBarComponent;
