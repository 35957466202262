import { getDiscover } from "./../../components/homepage/discover/store/thunk"
import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  hideLoading,
  showLoading,
} from "../../components/common/loading/store/slice"
import { StatusCodeResponse } from "../constants"
import NFTDetailServices from "./services"
import { LikeNFTRequest, NFTDetailEventRequest, NFTDetailRequest } from "./type"

export const getNFTDetail = createAsyncThunk(
  "NFT_DETAIL",
  async (params: NFTDetailRequest, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await NFTDetailServices.nftDetail(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data?.data
    }
    return rejectWithValue("")
  }
)

export const likeNFT = createAsyncThunk(
  "LIKE_NFT",
  async (params: LikeNFTRequest, { dispatch }) => {
    dispatch(showLoading())
    const res = await NFTDetailServices.likeNFT(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      if (params.typeAction === "listNFT") {
        dispatch(getDiscover(params.paramsDiscover || {}))
      } else {
        dispatch(
          getNFTDetail({
            contractAddress: params.contractAddress || "0",
            nft: params.nft || "",
            user_id: params.user_id,
          })
        )
      }
    }
  }
)

export const dislikeNFT = createAsyncThunk(
  "DISLIKE_NFT",
  async (params: LikeNFTRequest, { dispatch }) => {
    dispatch(showLoading())
    const res = await NFTDetailServices.dislikeNFT(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      if (params.typeAction === "listNFT") {
        dispatch(getDiscover(params.paramsDiscover || {}))
      } else {
        dispatch(
          getNFTDetail({
            contractAddress: params.contractAddress || "0",
            nft: params.nft || "",
            user_id: params.user_id,
          })
        )
      }
    }
  }
)

export const getNFTDetailEvent = createAsyncThunk(
  "NFT_DETAIL_EVENT",
  async (params: NFTDetailEventRequest, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await NFTDetailServices.nftDetailEvent(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data?.data
    }
    return rejectWithValue("")
  }
)

export const fetchNFTDetail = createAsyncThunk(
  "NFT/NFT_DETAIL",
  async (id: string, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await NFTDetailServices.getNftDetail(id)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data?.data
    }
    return rejectWithValue("")
  }
)
