import Web3Contract from "@/utils/Web3Contract"
import ABI from "../abi/Auction.json"
import { useState, useCallback } from "react"
import { AUCTION_CONTRACT } from "@/utils/constant"

const { contract: contractMethod } = new Web3Contract(AUCTION_CONTRACT, ABI)

const useGetAuction = (): [any, (input1: string, input2: any) => void] => {
  const [data, setData] = useState<any>({})

  const getData = useCallback(async (tokenId: string, nftToken: string) => {
    if (contractMethod) {
      try {
        const response = await contractMethod.methods
          .getAuction(tokenId, nftToken)
          .call()
        setData(response)
      } catch (error) {
        console.error("useGetAuction", error)
      }
    }
  }, [])

  return [data, getData]
}

export default useGetAuction
