import { createSlice } from "@reduxjs/toolkit"
import { ImageCollection, ImageStore } from "./type"
import {
  uploadImage,
  uploadVideo,
} from "../../../common/uploadImage/store/thunk"
import { uploadImageConstants } from "../../../../constants"
import { Store } from "../../../../app/type"

const initialState: ImageStore = {
  imageAvatar: "",
  imageNFT: "",
  imageCollection: {} as ImageCollection,
  imageFeatureCollection: "",
  typeFile: "",
}

const slice = createSlice({
  name: "imageUpload",
  initialState,
  reducers: {
    clearImageAvatar: (state) => {
      state.imageAvatar = ""
    },

    clearImageNFT: (state) => {
      state.imageNFT = ""
    },

    clearImageCollection: (state) => {
      state.imageCollection = {
        logo: "",
        feature: "",
        banner: "",
      }
    },
    clearImageCollectionLogo: (state) => {
      state.imageCollection = {
        ...state.imageCollection,
        logo: "",
      }
    },

    clearImageCollectionFeature: (state) => {
      state.imageCollection = {
        ...state.imageCollection,
        feature: "",
      }
    },

    clearImageBannerFeature: (state) => {
      state.imageCollection = {
        ...state.imageCollection,
        banner: "",
      }
    },

    setTypeFile: (state, action) => {
      state.typeFile = action.payload
    },

    clearAllImage: (state) => {
      state.imageAvatar = ""
      state.imageNFT = ""
      state.imageCollection = {
        logo: "",
        feature: "",
        banner: "",
      }
    },
    setNftAvatar: (state, action) => {
      state.imageNFT = action.payload
    },

    setAllImageCollection: (state, action) => {
      state.imageCollection = {
        logo: action.payload.logo,
        feature: action.payload.feature,
        banner: action.payload.banner,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadImage.fulfilled, (state, action) => {
      if (action.meta.arg.type === uploadImageConstants.typeUpload.AVATAR) {
        state.imageAvatar = action.payload.data.full_path
      } else if (
        action.meta.arg.type === uploadImageConstants.typeUpload.IMAGE
      ) {
        state.imageNFT = action.payload.data.full_path
      } else if (
        action.meta.arg.type === uploadImageConstants.typeUpload.COLLECTION
      ) {
        const collectionType: any = action.meta.arg.collectionType
        state.imageCollection = {
          ...state.imageCollection,
          [collectionType]: action.payload.data.full_path,
        }
      }
    })
    builder.addCase(uploadVideo.fulfilled, (state, action) => {
      if (action.meta.arg.type === uploadImageConstants.typeUpload.IMAGE) {
        state.imageNFT = action.payload.data.full_path
      }
    })
  },
})

export const selectImageAvatar = (state: Store) => state.imageUpload.imageAvatar
export const selectImageNFT = (state: Store) => state.imageUpload.imageNFT
export const selectTypefile = (state: Store) => state.imageUpload.typeFile
export const selectImageCollection = (state: Store) =>
  state.imageUpload.imageCollection

export const {
  clearImageAvatar,
  clearImageNFT,
  clearAllImage,
  setTypeFile,
  clearImageCollection,
  clearImageBannerFeature,
  clearImageCollectionFeature,
  clearImageCollectionLogo,
  setNftAvatar,
  setAllImageCollection,
} = slice.actions

export default slice.reducer
