import { changeMessage } from "@/components/common/toastMessage/store/slice"
import { selectMyProfile } from "@/components/myProfile/store/slice"
import CollectionServices from "@/network/collection/services"
import { StatusCodeResponse } from "@/network/constants"
import { Table, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import CollectionsCell from "./CollectionCell"
import WatchListCell from "./WatchListCell"
import "./table.scss"

export interface ICollection {
	id: string
	url: string
	name: string
	owner: string
}

interface DataType {
	key: string
	id: string
	collection?: ICollection
	price?: string
	volume?: string
	volChange?: string
	items?: string
	owners?: string
	isFavorite?: boolean | false
}

const TEXT_OPV = "MBC"
const TEXT_PERCENT = "%"
const PERCENT_VALUE_ZERO = 0

export const formatNumberToCommas = (number: number | string) => {
	const numberString = number.toString()
	const [integerPart, decimalPart] = numberString.split(".")

	const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

	let formattedDecimalPart = ""

	if (decimalPart) {
		formattedDecimalPart = parseFloat(`0.${decimalPart}`).toString().slice(2)
	}

	const formattedNumber = formattedDecimalPart
		? `${formattedIntegerPart}.${formattedDecimalPart}`
		: formattedIntegerPart

	return formattedNumber
}

const CollectionsTable = ({ collections }: any) => {
	const dispatch = useDispatch()
	const [data, setData] = useState<DataType[]>()
	const myProfile = useSelector(selectMyProfile)

	function convertToInternationalCurrencySystem(labelValue: any, type: any) {
		return Math.abs(Number(labelValue)) >= 1.0e3
			? formatNumberToCommas(
					(Math.abs(Number(labelValue)) / 1.0e3).toFixed(3)
			  ) +
					"K " +
					type
			: Math.abs(Number(labelValue)) + " " + type
	}

	const columns: ColumnsType<DataType> = [
		{
			title: "",
			width: 20,
			align: "center",
			className: "action-cell-mobile",
			render: (cell, data) => (
				<WatchListCell
					data={data?.collection}
					onClick={handleWatchList}
					myProfile={myProfile}
				/>
			),
		},
		{
			title: "#",
			dataIndex: "key",
			key: "key",
			align: "center",
			width: 40,
			render: (text) => text,
		},
		{
			title: "Collection",
			dataIndex: "collection",
			key: "collection",
			align: "left",
			className: "collection-cell",
			render: (collection: ICollection) => (
				<CollectionsCell collection={collection} />
			),
		},
		{
			title: "Floor price",
			dataIndex: "price",
			key: "price",
			align: "center",
			render: (text) => (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "1px",
						justifyContent: "center",
					}}
				>
					{`${convertToInternationalCurrencySystem(text, TEXT_OPV)}`}
				</div>
			),
		},
		{
			title: "Volume",
			dataIndex: "volume",
			key: "volume",
			align: "center",
			width: 160,
			render: (text) => (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "1px",
						justifyContent: "center",
					}}
				>
					{`${convertToInternationalCurrencySystem(text, TEXT_OPV)}`}
				</div>
			),
		},
		{
			title: "Vol change",
			dataIndex: "volChange",
			key: "volChange",
			align: "center",
			render: (text) => {
				let classNameByPercent = "percent-positive"
				if (text && parseFloat(text) < PERCENT_VALUE_ZERO) {
					classNameByPercent = "negative-percent"
				}

				return (
					<Typography
						className={classNameByPercent}
					>{`${text}${TEXT_PERCENT}`}</Typography>
				)
			},
		},
		{
			title: "Items",
			dataIndex: "items",
			key: "items",
			align: "center",
			render: (text) => (
				<div>{`${convertToInternationalCurrencySystem(text, "")}`}</div>
			),
		},
		{
			title: "Owners",
			dataIndex: "owners",
			key: "owners",
			align: "center",
			render: (text) => (
				<div>{`${convertToInternationalCurrencySystem(text, "")}`}</div>
			),
		},
		{
			align: "center",
			width: 40,
			className: "action-cell",
			render: (cell, data) => (
				<WatchListCell
					data={data?.collection}
					onClick={handleWatchList}
					myProfile={myProfile}
				/>
			),
		},
	]

	useEffect(() => {
		const dataCollection = collections?.data?.map(
			(item: any, index: number) => {
				return {
					key: index,
					collection: {
						name: item?.displayName
							? item?.displayName.length < 21
								? item?.displayName
								: `${item?.displayName.slice(0, 20)}...`
							: item?.name.length < 21
							? item?.name
							: `${item?.name.slice(0, 20)}...`,
						url: item?.logoUrl,
						id: item?.id,
						owner: item?.ownerAddress,
					},
					price: item?.floorPrice,
					volume: item?.volume?.value?.valueOPV,
					volChange: item?.volume?.changePercent,
					owners: item?.totalOwner,
					items: item?.totalNFT,
					isFavorite: item?.isFavorite,
				}
			}
		)

		setData(dataCollection)
	}, [collections])

	const handleWatchList = async (
		data: any,
		isWatched: boolean,
		setIsWatched: React.Dispatch<React.SetStateAction<boolean>>
	) => {
		if (myProfile) {
			if (data && Object.keys(data)?.length > 0) {
				if (myProfile?.user?.walletAddress === data?.owner) {
					dispatch(
						changeMessage({
							message: "Not watch your own collection",
							statusCode: 400,
						})
					)
					return
				}

				try {
					await CollectionServices.addWatchList(data.id)
						.then((res: any) => {
							if (res?.status === StatusCodeResponse.CREATED) {
								if (isWatched) {
									setIsWatched(false)
									dispatch(
										changeMessage({
											message: "Removed from watchlist",
											statusCode: 200,
										})
									)
								} else {
									setIsWatched(true)
									dispatch(
										changeMessage({
											message: "Add to watch list successfully!",
											statusCode: 200,
										})
									)
								}
							}
						})
						.catch((e: any) => {
							dispatch(
								changeMessage({
									message: "Add/Remove watch list failed!",
									statusCode: 400,
								})
							)
						})
				} catch (error) {
					dispatch(
						changeMessage({
							message: "Add/Remove watch list failed!",
							statusCode: 400,
						})
					)
				}
			}
		}
	}

	return (
		<Table
			className="table-custom"
			columns={columns}
			dataSource={data}
			pagination={false}
			scroll={{ x: 800 }}
		/>
	)
}

export default CollectionsTable
