import { useEffect, useState } from "react";
import { ethers } from "ethers";
import ERC20_ABI from "@/abi/ERC20.json";
import Web3 from "web3";
import { Contract } from "@ethersproject/contracts";
import { useSelector } from "react-redux";
import { selectMyProfile } from "@/components/myProfile/store/slice";
import { selectWalletAddress } from "@/components/connectWallet/store/slice";

// Hàm lấy số dư của token từ hợp đồng thông minh token
async function getTokenBalance(
  walletAddress: string,
  tokenContractAddress: string
) {
  const provider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_RPC_URL
  );
  const web3 = new Web3(process.env.REACT_APP_RPC_URL as string);

  const tokenAbi = ["function balanceOf(address) view returns (uint256)"];
  // const tokenContract = new ethers.Contract(tokenContractAddress, ERC20_ABI.abi, provider);
  // const tokenContract = new web3.eth.Contract(ERC20_ABI.abi, tokenContractAddress);
  const tokenContract = new Contract(
    tokenContractAddress,
    ERC20_ABI.abi,
    provider
  );
  const decimals = await tokenContract.decimals();

  const balance = await tokenContract.balanceOf(walletAddress);
  return ethers.utils.formatUnits(balance, decimals); // Đơn vị token có 18 chữ số thập phân (tùy thuộc vào đơn vị của token)
}

// Custom hook để lấy số dư của token
function useTokenBalance(tokenSymbol: string) {
  const [tokenBalance, setTokenBalance] = useState("0");

  const walletAddress = useSelector(selectWalletAddress);

  const tokenContractAddress = process.env[`REACT_APP_${tokenSymbol}_ADDRESS`];

  useEffect(() => {
    // Kiểm tra xem ví đã kích hoạt và có địa chỉ hay chưa
    // if (account && tokenContractAddress) {
    if (walletAddress && tokenContractAddress) {
      // Gọi hàm để lấy số dư của token khi component được hiển thị
      getTokenBalance(walletAddress, tokenContractAddress).then((balance) => {
        setTokenBalance(balance);
      });
    }
  }, [walletAddress, tokenContractAddress]);

  return tokenBalance;
}

export default useTokenBalance;
