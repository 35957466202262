/* eslint-disable no-constant-condition */
import { setCart } from "@/app/cart/actions"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"

export const useAddToCart = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { cart } = useSelector((state: any) => state.cart)

  const addToCart = useCallback(
    (nftId) => {
      if (!cart?.find((item: string) => item === nftId)) {
        dispatch(setCart([...cart, nftId]))
        localStorage.setItem("cart", JSON.stringify([...cart, nftId]))
        addToast("Add to cart success", {
          appearance: "success",
        })
      } else {
        dispatch(setCart(cart?.filter((item: string) => item !== nftId)))
        localStorage.setItem(
          "cart",
          JSON.stringify(cart?.filter((item: string) => item !== nftId))
        )
        addToast("Remove to cart success", {
          appearance: "success",
        })
      }
    },
    [addToast, cart, dispatch]
  )

  return addToCart
}

export const useRemoveAddToCart = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { cart } = useSelector((state: any) => state.cart)

  const removeAddToCart = useCallback(
    (nftId) => {
      dispatch(setCart(cart?.filter((item: string) => item !== nftId)))
      localStorage.setItem(
        "cart",
        JSON.stringify(cart?.filter((item: string) => item !== nftId))
      )
      addToast("Remove to cart success", {
        appearance: "success",
      })
    },
    [addToast, cart, dispatch]
  )

  return removeAddToCart
}

export const useClearCart = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const clearCart = useCallback(() => {
    dispatch(setCart([]))
    localStorage.setItem("cart", JSON.stringify([]))
    addToast("Clear cart success", {
      appearance: "success",
    })
  }, [addToast, dispatch])

  return clearCart
}

export const useLoadCart = () => {
  const dispatch = useDispatch()

  const getLoadCart = useCallback(() => {
    const dataCart = localStorage.getItem("cart")
    if (dataCart) {
      const jsonDataCart = JSON.parse(dataCart)
      dispatch(setCart(jsonDataCart))
    }
  }, [dispatch])

  useEffect(() => {
    getLoadCart()
  }, [getLoadCart])

  return getLoadCart
}
