import { createAsyncThunk } from "@reduxjs/toolkit"
import { GetAuctionParams } from "./../../../../network/homepage/homeAuction/type"
import homeAuctionServices from "../../../../network/homepage/homeAuction/services"
import { StatusCodeResponse } from "../../../../network/constants"
import { hideLoading, showLoading } from "../../../common/loading/store/slice"
import bannerServices from "@/network/banners/services"
import searchActionServices from "@/network/homepage/searchAction/services"

export const getAuction = createAsyncThunk(
  "home/AUCTION",
  async (params: GetAuctionParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await homeAuctionServices.getAuction(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data
    }
    return rejectWithValue("Error")
  }
)

export const getBanner = createAsyncThunk(
  "banner/SEARCH",
  async (params: any, { rejectWithValue, dispatch }) => {
    const res = await bannerServices.searchBanner(params)
    if (res.status === StatusCodeResponse.CREATED) {
      return res.data.data
    }
    return rejectWithValue("Error")
  }
)

export const searchProfile = createAsyncThunk(
  "search/PROFILE",
  async (params: any, { rejectWithValue, dispatch }) => {
    let res: any
    if (params?.address) {
      res = await searchActionServices.searchAddressProfile(params)
      if (res.status === StatusCodeResponse.SUCCESS) {
        return res.data.data
      }
    } else {
      res = await searchActionServices.searchProfile(params)
      if (res.status === StatusCodeResponse.CREATED) {
        return res.data.data
      }
    }

    return rejectWithValue("Error")
  }
)
export const searchCollection = createAsyncThunk(
  "search/COLLECTION",
  async (params: any, { rejectWithValue, dispatch }) => {
    const res = await searchActionServices.searchCollection(params)
    if ((res.status, StatusCodeResponse.CREATED)) {
      return res.data.data
    }
    return rejectWithValue("Error")
  }
)

export const searchNfts = createAsyncThunk(
  "search/NFT",
  async (params: any, { rejectWithValue, dispatch }) => {
    const res = await searchActionServices.searchNft(params)
    if ((res.status, StatusCodeResponse.CREATED)) {
      return res.data.data
    }
    return rejectWithValue("Error")
  }
)
