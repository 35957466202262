import { createAsyncThunk } from "@reduxjs/toolkit"
import { StatusCodeResponse } from "../../../../network/constants"
import { UpdateProfileParams } from "../../../../network/myProfile/types"
import { UploadImageParams } from "../../../../network/uploadImage/type"
import { changeProfile } from "../../../myProfile/store/thunk"
import { uploadImageConstants } from "./../../../../constants/index"
import uploadImageServices from "../../../../network/uploadImage/services"
import { changeMessage } from "../../toastMessage/store/slice"
import { setTypeFile } from "./slice"
import { hideLoading, showLoading } from "../../loading/store/slice"

export const uploadCoverImage = createAsyncThunk(
  "UPLOAD_COVER_IMAGE",
  async (params: UploadImageParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await uploadImageServices.uploadImage(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.CREATED) {
        dispatch(
          changeMessage({
            message: "Upload Cover image successfully",
            statusCode: res.status,
          })
        )
        if (params.type === uploadImageConstants.typeUpload.COVER) {
          const payload: UpdateProfileParams = {
            cover: res.data.data.full_path,
            updateType: params.type,
          }
          // dispatch(changeProfile(payload))
        }
        dispatch(setTypeFile(params.typeFile))
        return res.data
      } else {
        dispatch(
          changeMessage({
            message: "Upload Cover image failed",
            statusCode: res.status,
          })
        )
        return rejectWithValue(res.status)
      }
    } catch (error) {
      dispatch(
        changeMessage({
          message: "Upload image failed",
          statusCode: StatusCodeResponse.SYSTEM_ERROR,
        })
      )
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)
