// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart-footer-wrapper {
  font-size: 0.875em;
  position: fixed;
  bottom: 0;
  margin-bottom: 24px;
  min-width: 90%; }
  .cart-footer-wrapper .cart-footer-container {
    display: flex;
    flex-direction: column;
    gap: 1em; }
`, "",{"version":3,"sources":["webpack://./src/components/common/menu/headerMenu/components/dropdowns/cartFooter.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,SAAS;EACT,mBAAmB;EACnB,cAAc,EAAA;EALhB;IAOI,aAAa;IACb,sBAAsB;IACtB,QAAQ,EAAA","sourcesContent":[".cart-footer-wrapper {\n  font-size: 0.875em;\n  position: fixed;\n  bottom: 0;\n  margin-bottom: 24px;\n  min-width: 90%;\n  .cart-footer-container {\n    display: flex;\n    flex-direction: column;\n    gap: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
