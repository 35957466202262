import { usePushNotifications } from "@/contexts/PushNotificationProvider";
import { routes } from "@/router/constants";
import { PlusCircleFilled, ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ConnectWalletButton from "./buttons/ConnectWallet";
import { CartDropdown } from "./dropdowns/CartDropdown";
import NotificationPopup from "./dropdowns/NotificationPopup";
import PopupSearch from "./dropdowns/PopupSearch";
import ProfilePopup from "./dropdowns/ProfilePopup";
import useCheckNotification from "@/hooks/useCheckNotification";
import { useSelector } from "react-redux";
import { selectIsHasNotification } from "../../notification/store/slice";
import { useAccount } from "wagmi";

interface IHeaderRightProps {
	setCloseNavbar: (closeNavbar: boolean) => void;
	closeNavbar: boolean;
	isSigned: boolean;
}

const HeaderRight = ({
	closeNavbar,
	setCloseNavbar,
	isSigned,
}: IHeaderRightProps) => {
	const history = useHistory();
	const location = useLocation();

	const [visibleCart, setVisibleCart] = useState(true);
	const [visibleProfile, setVisibleProfile] = useState(true);
	const [visibleSearch, setVisibleSearch] = useState(true);
	const [visibleNotification, setVisibleNotification] = useState(true);

	const { hasNewNotification, setHasNewNotification } = usePushNotifications();
	const { getUnreadNotification } = useCheckNotification();
	const checkUnreadNotification = useSelector(selectIsHasNotification);

	const handleOpenCart = () => {
		setVisibleCart(false);
	};

	const handleOpenProfile = () => {
		setVisibleProfile(false);
	};

	const handleOpenNotification = useCallback(() => {
		setVisibleNotification(false);
		setHasNewNotification(false);
	}, [setHasNewNotification]);

	const isMobile = useMemo(() => {
		return window?.innerWidth < 992;
	}, []);

	const { isConnected } = useAccount();
	const isConnect = useMemo(() => {
		return isConnected && isSigned;
	}, [isConnected, isSigned]);

	const handleClickMenu = useCallback(() => {
		setCloseNavbar(!closeNavbar);
	}, [closeNavbar, setCloseNavbar]);

	const iconShopping = useMemo(
		() => (
			<>
				<ShoppingCartOutlined
					className="anticon-shopping"
					onClick={handleOpenCart}
				/>
				<CartDropdown visible={visibleCart} setVisible={setVisibleCart} />
			</>
		),
		[visibleCart]
	);

	const iconSearch = useMemo(
		() => (
			<>
				<img
					className="anticon-search"
					src={require("../../../../../assets/images/search.png")}
					alt=""
					onClick={() => setVisibleSearch((prev: boolean) => !prev)}
				/>
				<PopupSearch visible={visibleSearch} setVisible={setVisibleSearch} />
			</>
		),
		[visibleSearch]
	);

	const iconMenu = useMemo(
		() => (
			<img
				className="anticon-menu"
				src={require("../../../../../assets/images/menu.png")}
				alt=""
				onClick={handleClickMenu}
			/>
		),
		[handleClickMenu]
	);

	const iconUser = useMemo(
		() => (
			<>
				<img
					className="anticon-user"
					src={require("../../../../../assets/images/User.png")}
					alt=""
					onClick={handleOpenProfile}
				/>
				<ProfilePopup visible={visibleProfile} setVisible={setVisibleProfile} />
			</>
		),
		[visibleProfile]
	);

	const menuCreate = (
		<Menu>
			<Menu.Item onClick={() => handleMenuCreate?.(routes.CREATE_NFT)} key={1}>
				<p
					className={`p-0.5 text-base font-bold ${
						location.pathname === routes.CREATE_NFT
							? "text-[#2750FB]"
							: "text-text"
					}`}
				>
					NFT
				</p>
			</Menu.Item>
			<Menu.Item
				onClick={() => handleMenuCreate?.(routes.CREATE_COLLECTION)}
				key={2}
			>
				<p
					className={`p-0.5 text-base font-bold ${
						location.pathname === routes.CREATE_COLLECTION
							? "text-[#2750FB]"
							: "text-text"
					}`}
				>
					Collection
				</p>
			</Menu.Item>
		</Menu>
	);

	const renderButtonCreate = useMemo(() => {
		return (
			<Dropdown
				overlay={menuCreate}
				placement="bottom"
				trigger={["hover", "click"]}
				overlayClassName="header-dropdown"
			>
				{/* <Button className="btn-create" type="link" icon={<PlusCircleFilled />}> */}
				<Button type="link" className="btn-menu !p-0">
					Create
				</Button>
			</Dropdown>
		);
	}, [location]);

	const iconNotification = useMemo(
		() => (
			<>
				<span className="relative">
					<img
						className="anticon-bell"
						src={require("../../../../../assets/images/bell-simple.png")}
						alt=""
						onClick={handleOpenNotification}
					/>
					{checkUnreadNotification && (
						<span className="absolute top-1.5 right-1.5 w-2 h-2 rounded-full bg-red-500"></span>
					)}
				</span>
				{!visibleNotification && (
					<NotificationPopup
						visible={visibleNotification}
						setVisible={setVisibleNotification}
						refetchData={getUnreadNotification}
					/>
				)}
			</>
		),
		[
			handleOpenNotification,
			checkUnreadNotification,
			visibleNotification,
			getUnreadNotification,
		]
	);

	const renderNoConnectWallet = useMemo(() => {
		if (isMobile) {
			return (
				<div className="no-connect-wallet-wrapper">
					{iconSearch}
					{iconMenu}
				</div>
			);
		}

		return (
			<div className="no-connect-wallet-wrapper">
				<Button
					type="link"
					className="btn-menu !p-0"
					onClick={() => window.location.replace("https://swap.monbase.com/")}
				>
					Swap
				</Button>
				<ConnectWalletButton />
			</div>
		);
	}, [iconMenu, iconNotification, iconSearch, iconShopping, isMobile]);

	const renderConnectWallet = useMemo(() => {
		if (isMobile) {
			return (
				<div className="connect-wallet-wrapper">
					{iconSearch}
					{iconNotification}
					{iconUser}
					{iconMenu}
				</div>
			);
		}
		return (
			<div className="connect-wallet-wrapper">
				<Button
					type="link"
					className="btn-menu !p-0"
					onClick={() => window.location.replace("https://swap.monbase.com")}
				>
					Swap
				</Button>
				<Button
					type="link"
					className="btn-menu !p-0"
					onClick={() => history.push(routes.VESTING)}
				>
					Vesting
				</Button>
				{renderButtonCreate}
				{iconNotification}
				{iconUser}
			</div>
		);
	}, [
		iconMenu,
		iconNotification,
		iconSearch,
		iconShopping,
		iconUser,
		isMobile,
		location,
	]);

	function handleMenuCreate(path: string) {
		history.push({ pathname: path });
	}

	if (!isConnect) {
		return renderNoConnectWallet;
	}

	return (
		<>
			<div className="header-right-wrapper">{renderConnectWallet}</div>
		</>
	);
};

export default HeaderRight;
