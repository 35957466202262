import { get } from "../axiosClient"
import { GetCategoryNFTResponse } from "./type"

const categoryNFTServices = {
  getCategoryNFT(): Promise<GetCategoryNFTResponse> {
    return get("item/list-category")
  },
}

export default categoryNFTServices
