import { createSlice } from "@reduxjs/toolkit"
import * as actions from "./actions"

export interface cartAppState {
  cart: any
}

const initialState: cartAppState = {
  cart: [],
}

const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.setCart, (state, { payload }) => {
      state.cart = payload
    })
  },
})

export default slice.reducer
