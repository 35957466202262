import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../app/type"
import { DataDetailStore } from "./type"

const initialState: DataDetailStore = {
  dataProfileView: [],
}

const booksSlice = createSlice({
  name: "profileView",
  initialState,
  reducers: {
    addDataProfileView: (state, action) => {
      state.dataProfileView = { ...action.payload }
    },
  },
})

export const { addDataProfileView } = booksSlice.actions
export const selectProfileView = (state: Store) =>
  state.profileView.dataProfileView
export default booksSlice.reducer
