import { getCategoryList } from "@/network/categories/thunk"
import { CategoryType } from "@/network/categories/type"
import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../app/type"

export interface CategoryStore {
  allCategories: CategoryType[]
}

const initialState: CategoryStore = {
  allCategories: [],
}

const slice = createSlice({
  name: "nftDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategoryList.fulfilled, (state, action) => {
      state.allCategories = action.payload
    })
  },
})

export const selectAllCategories = (state: Store) =>
  state.category.allCategories

export default slice.reducer
