import { post } from "@/network/axiosClient"
import { getAuthorizationHeader } from "@/utils/authentication"
import { useCallback } from "react"

export default function useGetSaveBid() {
  const getSaveBid = useCallback(async (payload: any, cbs?: any, cbe?: any) => {
    const results = await post(`/transactions/bid`, payload, {
      headers: getAuthorizationHeader(),
    })

    if (results?.status === 201) {
      if (cbs) cbs(results?.data?.data)
    } else {
      if (cbe) cbe()
    }
  }, [])

  return getSaveBid
}
