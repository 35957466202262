import { Layout } from "antd";
import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import DesignByYourWay from "../../homepage/designBy";
import "./menu.scss";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { getMyProfile } from "../../myProfile/store/thunk";
import { getAccessToken } from "../../../utils/authentication";
import HeaderMenu from "./headerMenu";
import VerticalLayout from "../layout/verticalLayout";
import useConnectWallet from "./hook/useConnectWallet";
import SignWalletModal from "./headerMenu/components/modals/SignWallect";
import useCategories, { ICategory } from "./hook/useCategories";

export interface LayoutContextProps {
  currentAccount?: MutableRefObject<string>;
  isConnectMetamask?: boolean;
  signMessage?: (callBack?: () => void) => void;
  checkSignConnectWallet?: () => boolean;
  checkMetamaskProvider?: () => boolean;
  walletAddress?: String | null;
  signAndAccept?: (
    callBack?: () => void,
    user_id?: number,
    nounce?: string
  ) => Promise<void>;
  setOpenSignWallet?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConnectMetamask?: React.Dispatch<React.SetStateAction<boolean>>;
  setWalletAddress?: React.Dispatch<React.SetStateAction<string>>;
  setCategories?: React.Dispatch<React.SetStateAction<ICategory[]>>;
  categories?: ICategory[];
}

export const LayoutContext = React.createContext<LayoutContextProps>({});

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);

  if (context === undefined) {
    throw new Error(
      "useCompanyListContext must be used within a CompanyListContext.Provider"
    );
  }
  return context;
};

const Menu = ({ children }: any) => {
  const {
    walletAddress,
    setWalletAddress,
    currentAccount,
    contextHolder,
    isConnectMetamask,
    checkSignConnectWallet,
    openSignWallet,
    setOpenSignWallet,
    checkMetamaskProvider,
    signAndAccept,
    checkAccessToken,
    setIsConnectMetamask,
  } = useConnectWallet();

  const { categories, setCategories } = useCategories();

  const [closeNavbar, setCloseNavbar] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth);

  const location = useLocation();
  const dispatch = useDispatch();

  const isSigned = useMemo(() => {
    return checkAccessToken();
  }, [checkAccessToken()]);

  const handleResize = () => {
    setIsMobile(window.innerWidth);
  };

  const getProfile = () => {
    dispatch(getMyProfile({}));
  };

  useEffect(() => {
    if (getAccessToken()) {
      getProfile();
    }
  }, [getAccessToken()]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  const handleCloseSideBar = () => {
    setCloseNavbar(!closeNavbar);
  };

  const values = useMemo(
    () => ({
      currentAccount,
      isConnectMetamask,
      checkSignConnectWallet,
      checkMetamaskProvider,
      walletAddress,
      signAndAccept,
      setOpenSignWallet,
      setIsConnectMetamask,
      categories,
      setCategories,
    }),
    [
      currentAccount,
      isConnectMetamask,
      walletAddress,
      setWalletAddress,
      signAndAccept,
      setOpenSignWallet,
      setIsConnectMetamask,
      setCategories,
      categories,
    ]
  );

  return (
    <>
      <LayoutContext.Provider value={values}>
        {contextHolder}
        <Layout className="layout-container">
          {isMobile && (
            <div
              className={`overlay  ${!closeNavbar ? "open" : "close"}`}
              onClick={handleCloseSideBar}
            />
          )}
          <HeaderMenu
            closeNavbar={closeNavbar}
            setCloseNavbar={setCloseNavbar}
            isMobile={isMobile < 768}
            isSigned={isSigned}
          />

          <VerticalLayout
            closeNavbar={closeNavbar}
            setCloseNavbar={setCloseNavbar}
          >
            {children}
          </VerticalLayout>

          {location.pathname === "/" && <DesignByYourWay />}
          <SignWalletModal open={openSignWallet} setOpen={setOpenSignWallet} />
        </Layout>
      </LayoutContext.Provider>
    </>
  );
};

export default Menu;
