import { InjectedConnector } from "@web3-react/injected-connector"
import { initializeConnector } from "@web3-react/core"
import { MetaMask } from "@web3-react/metamask"
import { TrustWallet } from "@trustwallet/web3-react-trust-wallet"
import { Network } from "@web3-react/network"
import { URLS } from "./chains"

const supportChain = parseInt(process?.env?.REACT_APP_SUPPORT_CHAIN!) || 56

export const injected = new InjectedConnector({
  supportedChainIds: [supportChain],
})

export const [metaMask, hookMetamask] = initializeConnector<MetaMask>(
  (actions) => new MetaMask({ actions })
)
export const [trustwallet, hooksTrust] = initializeConnector<TrustWallet>(
  (actions) => new TrustWallet({ actions })
)
export const [network, hookNetwork] = initializeConnector<Network>(
  (actions) => new Network({ actions, urlMap: URLS })
)
