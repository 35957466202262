import { Store } from "./../../../../app/type"
import { MetaAuction } from "./../../../../network/event/event1m/type"
import { data } from "./../../../activities/constants"
import {
  getActivityCollection,
  getActivityProfile,
} from "./../../../../network/activity/thunk"
import { ActivityProfileStore } from "./type"
import { createSlice } from "@reduxjs/toolkit"

const initialState: ActivityProfileStore = {
  activityList: [],
  metaActivityList: <MetaAuction>{},
}

export const slice = createSlice({
  name: "activityProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivityProfile.fulfilled, (state, action) => {
      if (action.type === "activityProfile/fulfilled") {
        state.activityList = [...action.payload.data]
        state.metaActivityList = {
          ...state.metaActivityList,
          limit: action.payload?.limit,
          page: action.payload?.page,
          totalRecord: action.payload?.totalRecord,
          meta: action.payload?.meta_data,
        }
      }
    })
    builder.addCase(getActivityCollection.fulfilled, (state, action) => {
      if (action.type === "activityCollection/fulfilled") {
        state.activityList = [...action.payload.data]
      }
    })
  },
})

export const selectActivitiesList = (state: Store) =>
  state.activityProfile.activityList

export const selectMetaActivityList = (state: Store) =>
  state.activityProfile.metaActivityList

export default slice.reducer
