import { Avatar, Typography } from "antd"
import { ICollection } from "./Collections"
import "./collectionCell.scss"
import { Link } from "react-router-dom"

interface InformationCelProps {
  collection: ICollection
}

const CollectionsCell = ({ collection }: InformationCelProps) => {
  return (
    <Link
      to={`/collections/${collection?.id}/detail`}
      className="collectionCell-wrapper"
    >
      <Avatar
        className="collectionCell-avatar"
        shape="square"
        src={collection?.url ?? ""}
      />
      <Typography className="collectionCell-title">
        {collection?.name ?? ""}
      </Typography>
    </Link>
  )
}

export default CollectionsCell
