// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collectionCell-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  overflow: hidden; }
  .collectionCell-wrapper:hover img {
    transition: 0.3s;
    transform: scale(1.1); }
  .collectionCell-wrapper .collectionCell-avatar.ant-avatar {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    color: #ffffff; }
  .collectionCell-wrapper .collectionCell-avatar.ant-avatar-square {
    border-radius: 4px; }
  .collectionCell-wrapper .collectionCell-title {
    flex-shrink: 0;
    flex: 1 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600; }
`, "",{"version":3,"sources":["webpack://./src/containers/homePage/components/tables/collectionCell.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;EACnB,gBAAgB,EAAA;EALlB;IAQM,gBAAgB;IAChB,qBAAqB,EAAA;EAT3B;IAaI,WAAW;IACX,YAAY;IACZ,cAAc;IACd,cAAc,EAAA;EAhBlB;IAmBI,kBAAkB,EAAA;EAnBtB;IAsBI,cAAc;IACd,SAAO;IACP,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB,EAAA","sourcesContent":[".collectionCell-wrapper {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n  align-items: center;\n  overflow: hidden;\n  &:hover {\n    img {\n      transition: 0.3s;\n      transform: scale(1.1);\n    }\n  }\n  .collectionCell-avatar.ant-avatar {\n    width: 40px;\n    height: 40px;\n    flex-shrink: 0;\n    color: #ffffff;\n  }\n  .collectionCell-avatar.ant-avatar-square {\n    border-radius: 4px;\n  }\n  .collectionCell-title {\n    flex-shrink: 0;\n    flex: 1;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
