import { useState, useEffect, useRef, useCallback } from 'react';

const useInfiniteScroll = (fetchData: () => Promise<void>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const prevScrollY = useRef<number>(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      if (scrollTop > prevScrollY.current && scrollTop + clientHeight >= scrollHeight - 20) {
        fetchData();
      }

      prevScrollY.current = scrollTop;
    }
  }, [fetchData]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return {
    containerRef,
    loading,
    setLoading,
  };
};

export default useInfiniteScroll;
