import { Tooltip } from "antd"
import React, { PropsWithChildren, useEffect, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"

type Props = {
  value: string
}

function CopyTooltip({ children, value, ...props }: PropsWithChildren<Props>) {
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      const hideTooltip = () => {
        setCopied(false)
      }

      const timeout = setTimeout(hideTooltip, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [copied])

  return (
    <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
      <div className="cursor-pointer relative">
        {children}
        {copied && (
          <span className="absolute left-[50%] bottom-[calc(100%+6px)] border rounded px-4 py-2 text-xs">
            Copied
          </span>
        )}
      </div>
    </CopyToClipboard>
  )
}

export default CopyTooltip
