import BigNumber from "bignumber.js";

export const numberFormat = (
  value: number | string | BigNumber,
  maxFraction = 0
) => {
  const opts = {
    maximumFractionDigits: maxFraction,
  };
  if (typeof value === "object") value = value.toNumber();
  else value = Number(value);
  return value.toLocaleString("en-US", opts);
};

export const formatNumberPrice = (
  value: string | number | BigNumber,
  maxFraction = 10
): string => {
  return numberFormat(value, maxFraction);
};

export function abbreviateNumber(value: number) {
  const prefixes = ["", "k", "M", "B", "T", "P", "E"];
  const num = (Math.log10(value) / 3) | 0;

  if (num == 0) return value;

  const prefix = prefixes[num];
  const scale = Math.pow(10, num * 3);
  const scaled = value / scale;
  return scaled.toFixed(1) + prefix;
}
