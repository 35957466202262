import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../app/type"
import { getWatchList } from "../../../network/watchList/thunk"
import { WatchListStore } from "./type"

const initialState: WatchListStore = {
  dataWatchList: [],
}

const slice = createSlice({
  name: "watchList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWatchList.fulfilled, (state, action) => {
      state.dataWatchList = action.payload?.data
    })
  },
})

export const selectWatchList = (state: Store) => state.watchList.dataWatchList

export default slice.reducer
