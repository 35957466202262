import { createSlice } from "@reduxjs/toolkit"
import { ImageCollection, ImageCoverStore } from "./type"
import { uploadImage } from "../../../common/uploadImage/store/thunk"
import { uploadImageConstants } from "../../../../constants"
import { Store } from "../../../../app/type"
import { uploadCoverImage } from "./thunk"

const initialState: ImageCoverStore = {
  imageCover: "",
  imageNFT: "",
  imageCollection: {} as ImageCollection,
  typeFile: "",
}

const slice = createSlice({
  name: "imageCoverUpload",
  initialState,
  reducers: {
    clearImageCover: (state) => {
      state.imageCover = ""
    },

    clearImageNFT: (state) => {
      state.imageNFT = ""
    },
    setTypeFile: (state, action) => {
      state.typeFile = action.payload
    },
    clearAllImage: (state) => {
      state.imageCover = ""
      state.imageNFT = ""
      state.imageCollection = {
        logo: "",
        feature: "",
        banner: "",
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadCoverImage.fulfilled, (state, action) => {
      if (action.meta.arg.type === uploadImageConstants.typeUpload.COVER) {
        state.imageCover = action.payload.data.full_path
      } else if (
        action.meta.arg.type === uploadImageConstants.typeUpload.IMAGE
      ) {
        state.imageNFT = action.payload.data.full_path
      } else if (
        action.meta.arg.type === uploadImageConstants.typeUpload.COLLECTION
      ) {
        const collectionType: any = action.meta.arg.collectionType
        state.imageCollection = {
          ...state.imageCollection,
          [collectionType]: action.payload.data.full_path,
        }
      }
    })
  },
})

export const selectImageCover = (state: Store) =>
  state.imageCoverUpload.imageCover
export const selectImageCoverNFT = (state: Store) =>
  state.imageCoverUpload.imageNFT
export const selectTypeCoverfile = (state: Store) =>
  state.imageCoverUpload.typeFile
export const selectImageCoverCollection = (state: Store) =>
  state.imageCoverUpload.imageCollection

export const { clearImageCover, clearImageNFT, clearAllImage, setTypeFile } =
  slice.actions

export default slice.reducer
