import { Button } from "antd";
import "./connectWallet.scss";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import React from "react";

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const ConnectWalletButton = ({ className, style }: Props) => {
  const { openConnectModal } = useConnectModal();

  const handleConnect = () => {
    openConnectModal?.();
  };

  return (
    <Button
      className={`btn-connect-wallet ${className}`}
      style={style}
      onClick={handleConnect}
    >
      Connect Wallet
    </Button>
  );
};

export default ConnectWalletButton;
