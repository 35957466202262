import { useState } from "react"
import { Tabs } from "antd"
import "./styles.scss"

const { TabPane } = Tabs

interface TabItem {
  title: string
  key: string
  onClick?: () => void
}

interface TabSelectProps {
  tabs: TabItem[]
  onChange?: (value: string) => void
  defaultValue?: string
}

const TabSelect = ({ tabs, onChange, defaultValue }: TabSelectProps) => {
  const [activeTab, setActiveTab] = useState(defaultValue || tabs[0].key)

  return (
    <div className="flex items-center rounded-[8px] p-[4px] bg-[#E6E6E6] space-x-[4px] w-fit">
      {tabs.map((item) => {
        const { key, title } = item
        const isActive = activeTab === key
        return (
          <button
            key={key}
            onClick={() => {
              setActiveTab(key)
              item.onClick && item.onClick()
            }}
            className={[
              "rounded-[4px] py-[4px] px-[16px] text-[12px]",
              isActive ? "bg-[#FAFAFA] text-[#050505]" : "text-[#999999]",
            ].join(" ")}
          >
            {title}
          </button>
        )
      })}
    </div>
  )
}

export default TabSelect
