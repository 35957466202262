import { configureStore } from "@reduxjs/toolkit"
import auth from "../components/connectWallet/store/slice"
import homeAuction from "../components/homepage/auction/store/slice"
import myProfile from "../components/myProfile/store/slice"
import homeDiscover from "../components/homepage/discover/store/slice"
import imageUpload from "../components/common/uploadImage/store/slice"
import checkUpload from "../components/common/uploadImage/checkUpload/store/slice"
import toastMessage from "../components/common/toastMessage/store/slice"
import isLoading from "../components/common/loading/store/slice"
import categoryNFT from "../containers/createNFT/store/slice"
import nftDetail from "../containers/nftDetail/store/slice"
import latestTransaction from "../components/homepage/lastestTransactions/store/slice"
import collection from "../components/myProfile/collectibles/store/slice"
import listTopSellers from "../components/homepage/topSellers/store/slice"
import eventProfile from "../components/myProfile/event/store/slice"
import eventDiscover from "../components/allEvent/discoverEvent/store/slice"
import notification from "../components/common/menu/notification/store/slice"
import profileView from "../containers/profileView/store/slice"
import imageCoverUpload from "../components/common/UploadCoverImage/store/slice"
import explore from "../containers/exploreNFT/store/slice"
import watchList from "../containers/watchList/store/slice"
import activityProfile from "../components/collections/activitiesTab/store/slice"
import activity from "../components/activities/store/slice"
import searchValue from "../containers/searchResult/store/slice"
import modal from "./modal/slice"
import category from "../components/categories/store/slice"
import cart from "./cart/slice"
import vesting from "../containers/vesting/slice"

export const store = configureStore({
  reducer: {
    auth,
    homeAuction,
    myProfile,
    homeDiscover,
    imageUpload,
    checkUpload,
    toastMessage,
    isLoading,
    categoryNFT,
    nftDetail,
    latestTransaction,
    collection,
    listTopSellers,
    eventProfile,
    eventDiscover,
    notification,
    profileView,
    imageCoverUpload,
    explore,
    watchList,
    activityProfile,
    activity,
    searchValue,
    modal,
    category,
    cart,
    vesting,
  },
})
