import { getParamsForApiList } from "../../../utils/util"
import { get } from "../../axiosClient"
import { GetAuctionParams, GetAuctionResponse } from "./type"

const homeAuctionServices = {
  getAuction(params: GetAuctionParams): Promise<GetAuctionResponse> {
    return get(`/item/list-collection${getParamsForApiList(params)}is_hot=-1`)
  },
}

export const trendingCollectionServices = {
  getTrendingCollection(params: any): Promise<any> {
    return get(`collections/trending/${params}`)
  },
}
export const topCollectionServices = {
  getTopCollection(params: any): Promise<any> {
    return get(`collections/top/${params}`)
  },
}

export default homeAuctionServices
