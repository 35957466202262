// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-none {
  display: none; }

.loading-container {
  margin: 0 auto;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 99999999;
  transform: translate(-50%, -50%); }

.loading-overlay {
  opacity: 0.8;
  filter: alpha(opacity=80);
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  background-color: #f7f7f7;
  display: block;
  right: 0;
  bottom: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/common/loading/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;;AAGf;EACE,cAAc;EACd,eAAe;EACf,SAAS;EACT,QAAQ;EACR,iBAAiB;EACjB,gCAAgC,EAAA;;AAGlC;EACE,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,MAAM;EACN,OAAO;EACP,yBAAyB;EACzB,cAAc;EACd,QAAQ;EACR,SAAS,EAAA","sourcesContent":[".display-none {\n  display: none;\n}\n\n.loading-container {\n  margin: 0 auto;\n  position: fixed;\n  left: 50%;\n  top: 50%;\n  z-index: 99999999;\n  transform: translate(-50%, -50%);\n}\n\n.loading-overlay {\n  opacity: 0.8;\n  filter: alpha(opacity=80);\n  position: fixed;\n  z-index: 9999999;\n  top: 0;\n  left: 0;\n  background-color: #f7f7f7;\n  display: block;\n  right: 0;\n  bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
