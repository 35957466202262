import { OPV_PRICE_CONTRACT } from "@/utils/constant"
import Web3 from "web3"

const web3 = new Web3("https://bsc-dataseed.binance.org")

export default function useOPVPriceContract() {
  return new web3.eth.Contract(
    [
      {
        inputs: [
          {
            internalType: "address",
            name: "token0",
            type: "address",
          },
          {
            internalType: "address",
            name: "token1",
            type: "address",
          },
          {
            internalType: "uint24",
            name: "fee",
            type: "uint24",
          },
          {
            internalType: "address",
            name: "factory",
            type: "address",
          },
        ],
        name: "calculatePriceFromLiquidity",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    OPV_PRICE_CONTRACT
  )
}
