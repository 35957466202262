// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-design {
  background: #f7f7f7;
  padding: 16px;
  text-align: center; }
  .text-design h2 {
    text-transform: uppercase;
    color: #25b8e9;
    font-weight: 600;
    font-size: 39px;
    margin-bottom: 0; }
    @media (max-width: 476px) {
      .text-design h2 {
        font-size: 25px; } }
    @media (max-width: 414px) {
      .text-design h2 {
        font-size: 20px; } }
`, "",{"version":3,"sources":["webpack://./src/components/homepage/designBy/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,kBAAkB,EAAA;EAHpB;IAKI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,gBAAgB,EAAA;IAChB;MAVJ;QAWM,eAAe,EAAA,EAKlB;IAHC;MAbJ;QAcM,eAAe,EAAA,EAElB","sourcesContent":[".text-design {\n  background: #f7f7f7;\n  padding: 16px;\n  text-align: center;\n  h2 {\n    text-transform: uppercase;\n    color: #25b8e9;\n    font-weight: 600;\n    font-size: 39px;\n    margin-bottom: 0;\n    @media (max-width: 476px) {\n      font-size: 25px;\n    }\n    @media (max-width: 414px) {\n      font-size: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
