import AdminLogo from "@/assets/images/menu/admin-logo.png"
import SquareFourIcon from "@/components/icons/SquareFourIcon"

const AdminLayout = (props: any) => {
  return (
    <div className="flex bg-[#F0F0F0] w-screen min-h-screen">
      <div className="w-[20%] px-5 py-5 min-h-full bg-[#FAFAFA] border-r border-[#E6E6E6] flex flex-col gap-10">
        <a href="/">
          <img
            src={AdminLogo}
            alt="logo"
            className="w-full h-auto object-contain"
          />
        </a>

        <div className="flex flex-col gap-5">
          <div className="flex gap-3 items-center p-4 bg-[#C9EEFD] rounded-xl cursor-pointer">
            <SquareFourIcon className="text-[24px]" /> Overview
          </div>
        </div>
      </div>
      <div className="grow">
        <div className="h-[75px] bg-[#FAFAFA]"></div>
        {props?.children}
      </div>
    </div>
  )
}

export default AdminLayout
