import CopyTooltip from "@/components/common/copyTooltip";
import {
  clearToken,
  selectWalletAddress,
} from "@/components/connectWallet/store/slice";
import {
  clearMyProfile,
  selectMyProfile,
} from "@/components/myProfile/store/slice";
import useGetOpvPrice from "@/hooks/useGetOpvPrice";
import useTokenBalance from "@/hooks/useTokenBalance";
import useTokenPrice from "@/hooks/useTokenPrice";
import { CloseCircleOutlined, PoweroffOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, message, Row, Space } from "antd";
import { useLayoutContext } from "components/common/menu/Menu";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { shortenHexFor, showAddress } from "utils/util";
import styles from "./profile.module.scss";
import { Collection, Eye, HelpCenter, Setting, User } from "@/assets/icons";
import { formatNumberPrice } from "@/utils/numberFormat";
import { useDisconnect } from "wagmi";

interface ProfilePopupProps {
  children?: React.ReactNode;
  items?: any[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const ProfilePopup = ({ visible, setVisible }: ProfilePopupProps) => {
  const dispatch = useDispatch();
  const { disconnect } = useDisconnect();
  const { setWalletAddress } = useLayoutContext();
  const usdtPrice = useTokenPrice("USDT");
  const [opvPrice] = useGetOpvPrice();
  const usdtBalance = useTokenBalance("USDT");
  const opvBalance = useTokenBalance("MBC");
  const myProfile = useSelector(selectMyProfile);
  const walletAddress = useSelector(selectWalletAddress);

  const handleLogOut = () => {
    disconnect();
    setVisible(true);
    dispatch(clearToken());
    dispatch(clearMyProfile());
    localStorage.setItem("accessToken", "");
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("_accountId", "");
    setWalletAddress?.("");
    message.success("Logout Success", 3);
  };

  const handleCloseCart = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  return (
    <Drawer
      className={styles.profile}
      visible={!visible}
      onClose={() => setVisible(true)}
    >
      <Button
        className={styles.exit}
        // @ts-ignore
        icon={<CloseCircleOutlined />}
        type="link"
        onClick={() => setVisible(true)}
      />

      <div className={styles.navi}>
        <div className={styles.username}>
          <img
            src={
              !myProfile?.avatar
                ? require("@/assets/images/default-avatar.png")
                : myProfile.avatar
            }
            alt="avatar"
          />
          <p>
            {!myProfile?.displayName
              ? shortenHexFor(walletAddress ?? "")
              : myProfile.displayName}
          </p>
        </div>
        {[
          {
            title: "Profile",
            icon: <User />,
            path: "/profile",
          },
          {
            title: "Collection",
            icon: <Collection />,
            path: "/profile?view=collection",
          },
          {
            title: "Watchlist",
            icon: <Eye className={styles.eye} />,
            path: "/watchlist",
          },
          ...(myProfile?.user?.role === "Admin"
            ? [
                {
                  title: "Admin",
                  icon: (
                    <img
                      src={require(`@/assets/images/profile/UserCircleGear.png`)}
                      alt=""
                    />
                  ),
                  path: "/admin/overview",
                },
              ]
            : []),
          {
            title: "Help Center",
            icon: <HelpCenter />,
            path: "/help",
          },
          {
            title: "Setting",
            icon: <Setting />,
            path: "/setting",
          },
        ].map((item) => (
          <Link to={item.path} key={item.title} onClick={handleCloseCart}>
            <Button type="link" icon={item.icon} className={styles.navi_btn}>
              {item.title}
            </Button>
          </Link>
        ))}
      </div>

      <div className={styles.wallet}>
        <div className={styles.title}>
          <span>Connected wallet</span>
          <span>Manage Wallets</span>
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <img src="/assets/images/wallets/metamask.webp" width="48" alt="" />
            <div className={styles.name}>
              <p>BSC</p>
              <CopyTooltip value={walletAddress || ""}>
                <Space>
                  <span>{showAddress(walletAddress || "")}</span>
                  <img
                    src={require("@/assets/images/new-design/icon-copy-black.png")}
                    width="20"
                    alt=""
                  />
                </Space>
              </CopyTooltip>
            </div>
            {/* @ts-ignore */}
            <Button
              type="link"
              icon={<PoweroffOutlined style={{ fontSize: 20 }} />}
              onClick={handleLogOut}
            />
          </div>
          {[
            {
              symbol: "MBC",
              icon: "opv.png",
              balance: formatNumberPrice(opvBalance, 3),
              usdBalance: formatNumberPrice(
                (+opvBalance * +opvPrice)?.toFixed(3),
                3
              ),
            },
            {
              symbol: "USDT",
              icon: "usdt.png",
              balance: formatNumberPrice((+usdtBalance)?.toFixed(3), 3),
              usdBalance: formatNumberPrice(
                (+usdtBalance * usdtPrice.price)?.toFixed(3),
                3
              ),
            },
          ].map((item) => (
            <div className={styles.balance} key={item.symbol}>
              <Space size={8}>
                <img
                  src={require(`@/assets/images/coins/${item.icon}`)}
                  width="24"
                  alt=""
                />
                <span>
                  {item.balance} {item.symbol}
                </span>
              </Space>
              <p>${item.usdBalance}</p>
            </div>
          ))}
        </div>
      </div>

      {/* <div className={styles.fund}>
        <Wallet />
        <h1>Fund your wallet to purchase NFTs</h1>
        <Row gutter={10} style={{ width: '100%' }}>
          <Col span={12}>
            <Button
              className={styles.swap_btn}
              onClick={() => window.open("https://pancakeswap.finance/swap?outputCurrency=0x36C7B164F85D6F775cD128966D5819c7d36FEfF3&inputCurrency=0x55d398326f99059fF775485246999027B3197955")}
            >
              Swap
            </Button>
          </Col>
          <Col span={12}>
            <Button
              className={styles.add_btn}
              onClick={() => dispatch(setModalAddFund({ toggle: true }))}
            >
              Add Fund
            </Button>
          </Col>
        </Row>
      </div> */}
    </Drawer>
  );
};

export default ProfilePopup;
