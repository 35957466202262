import { uploadImageConstants } from "./../../../constants/index"
import {
  FollowParams,
  TabParams,
  UpdateProfileParams,
  UserViewParams,
} from "./../../../network/myProfile/types"
import { StatusCodeResponse } from "./../../../network/constants"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { GetNFTItemsParams } from "../../../network/myProfile/types"
import { changeMessage } from "../../common/toastMessage/store/slice"
import { hideLoading, showLoading } from "../../common/loading/store/slice"
import myProfileServices from "../../../network/myProfile/services"
import { setUserId, setUserType } from "../../../utils/authentication"
import { followSucceed, UnFollowSucceed } from "./slice"
import { clearImageAvatar } from "@/components/common/uploadImage/store/slice"
import { clearImageCover } from "@/components/common/UploadCoverImage/store/slice"

export const getUserDetail = createAsyncThunk(
  "myProfile/USER_DETAIL",
  async (params: UserViewParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await myProfileServices.getUserDetail(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        setUserId(res.data?.data?.id)
        setUserType(res.data?.data?.type)
        return res.data?.data
      }
      return rejectWithValue(res.status)
    } catch (error) {
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)

export const getNFTItems = createAsyncThunk(
  "myProfile/NFT_ITEMS",
  async (params: GetNFTItemsParams, { rejectWithValue, dispatch }) => {
    // dispatch(showLoading())
    const res = await myProfileServices.getNFTItems(params)
    // dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        return res.data
      }
      return rejectWithValue(res.status)
    } catch (error) {
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)

export const changeProfile = createAsyncThunk(
  "myProfile/CHANGE_PROFILE",
  async (params: UpdateProfileParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await myProfileServices.changeProfile(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        if (params.updateType === uploadImageConstants.typeUpload.AVATAR) {
          dispatch(
            changeMessage({
              message: "Update profile successfully.",
              statusCode: res.status,
            })
          )
        }
        dispatch(getUserDetail({}))
      } else {
        dispatch(
          changeMessage({
            message: "Update profile failed.",
            statusCode: res.status,
          })
        )
        return rejectWithValue(res.status)
      }
    } catch (error) {
      dispatch(
        changeMessage({
          message: "Update profile failed",
          statusCode: StatusCodeResponse.SYSTEM_ERROR,
        })
      )
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)

export const getListTabProfile = createAsyncThunk(
  "myProfile/TAB",
  async (params: TabParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await myProfileServices.getListTabProfile(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        return res.data
      }
      return rejectWithValue(res.status)
    } catch (error) {
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)

export const getUserView = createAsyncThunk(
  "myProfile/USER_VIEW",
  async (params: UserViewParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await myProfileServices.getUserView(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        return res.data?.data
      }
      return rejectWithValue(res.status)
    } catch (error) {
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)

export const postFollow = createAsyncThunk(
  "FOLLOW",
  async (params: FollowParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await myProfileServices.postFollow(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      dispatch(followSucceed())
      dispatch(
        changeMessage({
          message: "Follow successfully",
          statusCode: res.status,
        })
      )
    } else {
      dispatch(
        changeMessage({
          message: "Follow failed",
          statusCode: res.status,
        })
      )
      return rejectWithValue(res.status)
    }
  }
)

export const postUnFollow = createAsyncThunk(
  "UNFOLLOW",
  async (params: FollowParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await myProfileServices.postUnFollow(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      dispatch(UnFollowSucceed())
      dispatch(
        changeMessage({
          message: "Unfollow successfully",
          statusCode: res.status,
        })
      )
    } else {
      dispatch(
        changeMessage({
          message: "Unfollow failed",
          statusCode: res.status,
        })
      )
      return rejectWithValue(res.status)
    }
  }
)

export const getMyProfile = createAsyncThunk(
  "myProfile/MY_PROFILE",
  async (params: any, { rejectWithValue, dispatch }) => {
    const res = await myProfileServices.getMyProfile()
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        return res.data?.data
      }
      return rejectWithValue(res.status)
    } catch (error) {
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)

export const createMyProfile = createAsyncThunk(
  "myProfile/CREATE_MY_PROFILE",
  async (payload: any, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await myProfileServices.createMyProfile(payload)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.CREATED) {
        dispatch(getMyProfile({}))
        dispatch(clearImageAvatar())
        dispatch(clearImageCover())
        dispatch(
          changeMessage({
            message: "Update profile successfully.",
            statusCode: res.status,
          })
        )
      } else {
        dispatch(
          changeMessage({
            message: "Update profile failed.",
            statusCode: res.status,
          })
        )
        return rejectWithValue(res.status)
      }
    } catch (error) {
      dispatch(
        changeMessage({
          message: "Update profile failed",
          statusCode: StatusCodeResponse.SYSTEM_ERROR,
        })
      )
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)
