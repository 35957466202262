// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vertical-layout-wrapper {
  height: 100%;
  padding-left: 32px;
  padding-right: 32px; }
  @media (max-width: 1440px) {
    .vertical-layout-wrapper {
      padding-left: 32px;
      padding-right: 32px; } }
  @media (max-width: 991px) {
    .vertical-layout-wrapper {
      padding-left: 16px;
      padding-right: 16px; } }

.vertical-layout-full-wrapper {
  height: 100%;
  margin-left: 0px;
  margin-right: 0px; }
  @media (max-width: 768px) {
    .vertical-layout-full-wrapper {
      margin-left: 0px;
      margin-right: 0px; } }
`, "",{"version":3,"sources":["webpack://./src/components/common/layout/verticalLayout/verticalLayout.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB,EAAA;EAEnB;IALF;MAMI,kBAAkB;MAClB,mBAAmB,EAAA,EAOtB;EAJC;IAVF;MAWI,kBAAkB;MAClB,mBAAmB,EAAA,EAEtB;;AAED;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB,EAAA;EACjB;IAJF;MAKI,gBAAgB;MAChB,iBAAiB,EAAA,EAEpB","sourcesContent":[".vertical-layout-wrapper {\n  height: 100%;\n  padding-left: 32px;\n  padding-right: 32px;\n\n  @media (max-width: 1440px) {\n    padding-left: 32px;\n    padding-right: 32px;\n  }\n\n  @media (max-width: 991px) {\n    padding-left: 16px;\n    padding-right: 16px;\n  }\n}\n\n.vertical-layout-full-wrapper {\n  height: 100%;\n  margin-left: 0px;\n  margin-right: 0px;\n  @media (max-width: 768px) {\n    margin-left: 0px;\n    margin-right: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
