import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../app/type"
import {
  MetaNFTProfile,
  NFTItem,
  UserDetail,
} from "../../../network/myProfile/types"
import {
  getListTabProfile,
  getMyProfile,
  getNFTItems,
  getUserDetail,
  getUserView,
} from "./thunk"
import { MyProfileStore } from "./types"

const initialState: MyProfileStore = {
  userDetail: <UserDetail>{},
  nftList: <NFTItem[]>[],
  metaNftProfile: <MetaNFTProfile>{},
  dataTab: undefined,
  dataUserView: {},
  follow: false,
  unFollow: false,
  isCheckLike: false,
  isCheckComfirmMail: false,
  myProfile: undefined,
}

const slice = createSlice({
  name: "myProfile",
  initialState,
  reducers: {
    clearMyProfile: (state) => {
      state.myProfile = undefined
    },
    clearNFTList: (state) => {
      state.nftList = []
    },
    followSucceed: (state) => {
      state.follow = true
    },

    followFailed: (state) => {
      state.follow = false
    },
    UnFollowSucceed: (state) => {
      state.unFollow = true
    },
    senMailsuccess: (state) => {
      state.isCheckComfirmMail = true
    },
    unFollowFailed: (state) => {
      state.unFollow = false
    },
    clearFollow: (state) => {
      state.follow = false
      state.unFollow = false
    },
    CheckLikeSuccess: (state) => {
      state.isCheckLike = true
    },
    clearCheckLike: (state) => {
      state.isCheckLike = false
    },
    ClearSendMail: (state) => {
      state.isCheckComfirmMail = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyProfile.fulfilled, (state, action) => {
      state.myProfile = action.payload
    })
    builder.addCase(getUserDetail.pending, (state) => {})
    builder.addCase(getUserDetail.fulfilled, (state, action) => {
      state.userDetail = action.payload
    })
    builder.addCase(getUserDetail.rejected, (state) => {})
    builder.addCase(getUserView.pending, (state) => {})
    builder.addCase(getUserView.fulfilled, (state, action) => {
      state.dataUserView = action.payload
    })
    builder.addCase(getUserView.rejected, (state) => {})
    builder.addCase(getNFTItems.pending, (state) => {})
    builder.addCase(getNFTItems.fulfilled, (state, action) => {
      if (Array.isArray(action.payload?.data)) {
        state.nftList = [...action.payload.data]
      }
      state.metaNftProfile = {
        ...state.metaNftProfile,
        limit: action.payload?.limit,
        page: action.payload?.page,
        totalRecord: action.payload?.totalRecord,
      }
    })
    builder.addCase(getNFTItems.rejected, (state) => {})
    builder.addCase(getListTabProfile.fulfilled, (state, action) => {
      state.dataTab = action.payload.data
    })
  },
})

export const selectMyProfile = (state: Store) => state.myProfile.myProfile
export const selectUserDetail = (state: Store) => state.myProfile.userDetail
export const selectNFTList = (state: Store) => state.myProfile.nftList
export const selectMetaNFTProfile = (state: Store) =>
  state.myProfile.metaNftProfile
export const selectTabList = (state: Store) => state.myProfile.dataTab
export const selectUserView = (state: Store) => state.myProfile.dataUserView
export const selectFollow = (state: Store) => state.myProfile.follow
export const selectUnFollow = (state: Store) => state.myProfile.unFollow
export const selectCheckLike = (state: Store) => state.myProfile.isCheckLike
export const selectSendMail = (state: Store) =>
  state.myProfile.isCheckComfirmMail

export const {
  clearMyProfile,
  clearNFTList,
  followSucceed,
  followFailed,
  UnFollowSucceed,
  unFollowFailed,
  clearFollow,
  CheckLikeSuccess,
  clearCheckLike,
  ClearSendMail,
  senMailsuccess,
} = slice.actions

export default slice.reducer
