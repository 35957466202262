import { getCategoryNFT } from "./../../../network/categoryNFT/thunk"
import { createSlice } from "@reduxjs/toolkit"
import { CategoryNFT } from "../../../network/categoryNFT/type"
import { CategoryNFTStore } from "./type"
import { Store } from "../../../app/type"
import { createNFT } from "../../../network/NFT/thunk"

const initialState: CategoryNFTStore = {
  categoryNFTList: <CategoryNFT[]>[],
  createNFTFlag: false,
  newNFT: {},
}

const slice = createSlice({
  name: "categoryNFT",
  initialState,
  reducers: {
    createNFTSucceed: (state) => {
      state.createNFTFlag = true
    },

    createNFTFailed: (state) => {
      state.createNFTFlag = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoryNFT.fulfilled, (state, action) => {
      state.categoryNFTList = action.payload
    })

    builder.addCase(createNFT.fulfilled, (state, action) => {
      state.newNFT = action.payload
    })
  },
})

export const selectCategoryNFT = (state: Store) =>
  state.categoryNFT.categoryNFTList
export const selectCreateNFTFlag = (state: Store) =>
  state.categoryNFT.createNFTFlag
export const selectNewNFT = (state: Store) => state.categoryNFT.newNFT

export const { createNFTSucceed, createNFTFailed } = slice.actions

export default slice.reducer
