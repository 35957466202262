import { createSlice } from "@reduxjs/toolkit"
import * as actions from "./actions"

export interface VestingStore {
  vestingList: any
  vestingHistory: any
}

const initialState: VestingStore = {
  vestingList: undefined,
  vestingHistory: undefined,
}

const slice = createSlice({
  name: "vesting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.setVestingList, (state, { payload }) => {
      state.vestingList = payload
    })
    builder.addCase(actions.setVestingHistory, (state, { payload }) => {
      state.vestingHistory = payload
    })
  },
})

export default slice.reducer
