import { trustWallet } from "@rainbow-me/rainbowkit/wallets";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { injectedWallet } from "@rainbow-me/rainbowkit/wallets";
import { walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";
import { safeWallet } from "@rainbow-me/rainbowkit/wallets";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { WagmiProvider } from "wagmi";
import { bsc, bscTestnet } from "wagmi/chains";
import "./App.css";
import { store } from "./app/rootStore";
import { PushNotificationProvider } from "./contexts/PushNotificationProvider";
import Routes from "./router/Routes";

const config = getDefaultConfig({
  appName: "Monbase NFT",
  projectId: "6cbb1bd6f35e2fd220e52b90145cc38d",
  chains: [bsc, bscTestnet],
  wallets: [
    {
      groupName: "Recommended",
      wallets: [
        metaMaskWallet,
        injectedWallet,
        trustWallet,
        walletConnectWallet,
        safeWallet,
      ],
    },
  ],
});

const queryClient = new QueryClient();

const App = () => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <Provider store={store}>
            <PushNotificationProvider>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </PushNotificationProvider>
          </Provider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};

export default App;
