import { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import TheLoadingSpinner from "../components/common/loading";
import Menu from "../components/common/menu/Menu";
import {
  clearMessage,
  selectToastMessage,
} from "../components/common/toastMessage/store/slice";
import { setAuthentication } from "../components/connectWallet/store/slice";
import {
  getAccessToken,
  getRefreshToken,
  getWalletAddress,
} from "../utils/authentication";
import { supportChain } from "../utils/constant";
import { getMessageType } from "../utils/toastMessage";
import { routes } from "./constants";
import ProtectedRoutes from "./ProtectedRoutes";
import ModalBuyNow from "@/components/modalBuyNow";
import ModalBidNow from "@/components/modalBidNow";
import ModalSuccess from "@/components/modalSuccess";
import ModalAddFund from "@/components/modalAddFund";
import { useLoadCart } from "@/hooks/useCart";
import AdminLayout from "@/components/common/menu/AdminLayout";
import AdminOverview from "@/containers/admin/Overview";
import { useAccount, useChainId, useSwitchChain } from "wagmi";

const HomePage = lazy(() => import("../containers/homePage/HomePage"));
const NFTDetail = lazy(() => import("../containers/nftDetail"));
const Event = lazy(() => import("../containers/event/Event"));
const Collections = lazy(() => import("../containers/collections"));
const AllEvent = lazy(() => import("../containers/allEvent/AllEvent"));
const LatestTransactionsPage = lazy(
  () => import("../containers/lastestTransaction")
);
const Notifications = lazy(() => import("../containers/notifications"));
const Ranking = lazy(() => import("../containers/ranking"));
const ProfileView = lazy(() => import("../containers/myProfile"));
const DetailCollections = lazy(
  () => import("../containers/detailCollection/DetailCollection")
);
const Launchpad = lazy(() => import("../containers/launchpad/Launchpad"));
const ChangeChain = lazy(() => import("../components/changeChain/changeChain"));
const NFTDetailInventory = lazy(
  () => import("../containers/nftDetailInventory")
);
const NFTDetailEvent = lazy(() => import("../containers/nftDetailEvent"));
const CollectionDetailFromHP = lazy(
  () => import("../containers/collectionsDetailt")
);
const SearchResult = lazy(() => import("../containers/searchResult"));
const BrandCategory = lazy(
  () => import("../components/homepage/brandCategory")
);
const BrandDetails = lazy(() => import("../components/homepage/brandDetails"));
const SearchHelp = lazy(
  () => import("../containers/helpCenter/helpCenterSearch")
);
const Swap = lazy(() => import("../containers/swapToken"));
const HelpCenter = lazy(() => import("../containers/helpCenter"));
const HelpBuying = lazy(
  () => import("../containers/helpCenter/helpCenterBuying")
);
const HelpSelling = lazy(
  () => import("../containers/helpCenter/helpCenterSelling")
);
const HelpCreating = lazy(
  () => import("../containers/helpCenter/helpCenterCreating")
);
const HelpGetting = lazy(
  () => import("../containers/helpCenter/helpCenterGetting")
);
const HelpSearchResult = lazy(
  () => import("../containers/helpCenter/helpCenterSearch")
);
const HelpRequest = lazy(
  () => import("../containers/helpCenter/helpCenterSubmit")
);
// const Rewards = lazy(() => import("../containers/rewardLayout"));
const RewardHistoryLayout = lazy(
  () => import("../containers/rewardHistoryLayout")
);
const RewardLeaderboardLayout = lazy(
  () => import("../containers/rewardLeaderboardLayout")
);
const RewardViewRulesLayout = lazy(
  () => import("../containers/rewardViewRulesLayout")
);
const RewardsCollection = lazy(
  () => import("../containers/rewards/components/viewCollectionReward")
);
const ConfirmUser = lazy(() => import("../containers/confirmUser/index"));
const AutionLayout = lazy(() => import("../containers/auction"));
const Activities = lazy(() => import("../containers/activities"));
const Settings = lazy(() => import("../containers/settingsLayout"));
const DetailtNft = lazy(() => import("../containers/detailtNft"));
const Explore = lazy(() => import("../containers/searchResult"));
const LaunchpadDetail = lazy(
  () => import("../containers/launchpad/launchpadDetail/LaunchpadDetail")
);

const Lottery = lazy(() => import("../containers/lottery"));

const Routes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const loadCart = useLoadCart();
  const { address: account } = useAccount();
  const { switchChain } = useSwitchChain();
  const chainId = useChainId();

  const toastMessage = useSelector(selectToastMessage);

  useEffect(() => {
    loadCart();
  }, [loadCart]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  useEffect(() => {
    if (toastMessage.message && toastMessage.statusCode) {
      addToast(toastMessage.message, {
        appearance: getMessageType(toastMessage.statusCode),
      });
      dispatch(clearMessage());
    }
  }, [toastMessage]);

  useEffect(() => {
    window.onbeforeunload = function (e: any) {
      window.localStorage.unloadTime = JSON.stringify(new Date());
    };

    if (window.localStorage.unloadTime) {
      window.onload = function () {
        let loadTime = new Date();
        let unloadTime = new Date(JSON.parse(window.localStorage.unloadTime));
        let refreshTime = loadTime.getTime() - unloadTime.getTime();
        if (refreshTime > 1800000) {
          localStorage.clear();
        }
      };
    }

    dispatch(
      setAuthentication({
        accessToken: getAccessToken() || "",
        refreshToken: getRefreshToken() || "",
        walletAddress: getWalletAddress() || "",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (account && chainId && chainId.toString() !== supportChain) {
      switchChain({ chainId: parseInt(supportChain) });
    }
  }, [account, chainId]);

  return (
    <Suspense fallback={<TheLoadingSpinner isLoading={true} />}>
      <Route>
        <Switch>
          <Route
            path="/admin/:path?"
            exact
            render={() => {
              return (
                <AdminLayout>
                  <Switch>
                    <Route
                      path="/admin/overview"
                      exact
                      component={AdminOverview}
                    />
                  </Switch>
                </AdminLayout>
              );
            }}
          />

          <Route
            render={() => {
              return (
                <Menu>
                  <Switch>
                    <Route
                      path={routes.CHANGE_CHAIN}
                      component={ChangeChain}
                      exact
                    />
                    <Route path={routes.HOMEPAGE} component={HomePage} exact />
                    <Route path={routes.CATEGORY} component={HomePage} exact />

                    <Route
                      path={routes.LAUNCHPAD}
                      component={Launchpad}
                      exact
                    />
                    <Route path={routes.ALL_EVENT} component={AllEvent} exact />
                    <Route
                      path={routes.NFT_DETAIL_PARAMS}
                      component={NFTDetail}
                      exact
                    />
                    <Route
                      path={routes.COLLECTION_HOLD}
                      component={Collections}
                      exact
                    />
                    <Route path={routes.SWAP} component={Swap} exact />
                    <Route
                      path={routes.HELP_CENTER}
                      component={HelpCenter}
                      exact
                    />
                    <Route
                      path={routes.HELP_BUYING}
                      component={HelpBuying}
                      exact
                    />
                    <Route
                      path={routes.HELP_SELLING}
                      component={HelpSelling}
                      exact
                    />
                    <Route
                      path={routes.HELP_CREATING}
                      component={HelpCreating}
                      exact
                    />
                    <Route
                      path={routes.HELP_GETTING}
                      component={HelpGetting}
                      exact
                    />
                    <Route
                      path={routes.HELP_SEARCH_RESULT}
                      component={HelpSearchResult}
                      exact
                    />
                    <Route
                      path={routes.SEARCH_HELP}
                      component={SearchHelp}
                      exact
                    />
                    <Route
                      path={routes.HELP_REQUEST}
                      component={HelpRequest}
                      exact
                    />
                    <Route
                      path={routes.BRAND_CATEGORY}
                      component={BrandCategory}
                      exact
                    />
                    <Route
                      path={routes.BRAND_DETAILS}
                      component={BrandDetails}
                      exact
                    />
                    <Route
                      path={routes.LASTEST_TRANSACTIONS}
                      component={LatestTransactionsPage}
                      exact
                    />
                    <Route
                      path={routes.NOTIFICATIONS}
                      component={Notifications}
                      exact
                    />
                    <Route path={routes.RANKING} component={Ranking} exact />
                    <Route
                      path={routes.PROFILE_VIEW}
                      component={ProfileView}
                      exact
                    />
                    <Route path={routes.EVENT} component={Event} exact />
                    <Route
                      path={routes.DETAIL_COLLECTION_NFT}
                      component={DetailCollections}
                      exact
                    />
                    <Route
                      path={routes.NFT_INVENTORY_PARAMS}
                      component={NFTDetailInventory}
                      exact
                    />
                    <Route
                      path={routes.NFT_IN_EVENT}
                      component={NFTDetailEvent}
                      exact
                    />
                    <Route
                      path={routes.DETAIL_COLLECTION}
                      component={CollectionDetailFromHP}
                      exact
                    />
                    <Route
                      path={routes.SEARCH_RESULT}
                      component={SearchResult}
                      exact
                    />
                    <Route
                      path={routes.REWARDS_COLLECTION}
                      component={RewardsCollection}
                      exact
                    />
                    <Route
                      path={routes.CONFIRM_USER}
                      component={ConfirmUser}
                      exact
                    />
                    <Route
                      path={routes.AUCTION_LAYOUT}
                      component={AutionLayout}
                      exact
                    />
                    <Route
                      path={routes.ACTIVITIES}
                      component={Activities}
                      exact
                    />
                    <Route
                      path={routes.SETTINGS_LAYOUT}
                      component={Settings}
                      exact
                    />
                    <Route
                      path={routes.DETAIL_NFT}
                      component={DetailtNft}
                      exact
                    />
                    <Route
                      path={routes.EXPLORE_NFT}
                      component={Explore}
                      exact
                    />
                    {/* <Route path={routes.REWARDS} component={Rewards} exact /> */}
                    <Route
                      path={routes.REWARDS_HISTORY}
                      component={RewardHistoryLayout}
                      exact
                    />
                    <Route
                      path={routes.REWARDS_LEADER_BOARD}
                      component={RewardLeaderboardLayout}
                      exact
                    />
                    <Route
                      path={routes.REWARDS_VIEW_RULES}
                      component={RewardViewRulesLayout}
                      exact
                    />
                    <Route
                      path={routes.LAUNCHPAD_DETAIL}
                      component={LaunchpadDetail}
                      exact
                    />
                    <Route path={routes.LOTTERY} component={Lottery} exact />
                    <ProtectedRoutes />
                    {/* {getAccessToken() ? <ProtectedRoutes /> : <AuthRoutes />} */}
                  </Switch>
                </Menu>
              );
            }}
          />
        </Switch>
      </Route>

      <ModalBuyNow />
      <ModalBidNow />
      <ModalSuccess />
      <ModalAddFund />
    </Suspense>
  );
};

export default Routes;
