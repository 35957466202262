import { post } from "../axiosClient"
import { Endpoint } from "../endpoint"
import { GetCategoryListResponse } from "./type"

const CategoryServices = {
  getCategoryList(payload: any): Promise<GetCategoryListResponse> {
    return post(Endpoint.CATEGORY_LIST, payload)
  },
}

export default CategoryServices
