import { getAuthorizationHeader } from "../../utils/authentication"
import { deleteRequest, get, patch, post } from "../axiosClient"

const NFTServices = {
  createNFT(payload: any): Promise<any> {
    return post("nfts", payload, {
      headers: getAuthorizationHeader(),
    })
  },

  updateNFT(id: string, payload: any): Promise<any> {
    return patch(`nfts/${id}`, payload, {
      headers: getAuthorizationHeader(),
    })
  },

  searchNFT(params: any): Promise<any> {
    return post("nfts/search", params, {
      headers: getAuthorizationHeader(),
    })
  },

  searchNFTByOwner(params: any): Promise<any> {
    return post("nfts/search/owner", params, {
      headers: getAuthorizationHeader(),
    })
  },

  deleteNFT(id: string): Promise<any> {
    return deleteRequest(`nfts/${id}`, {
      headers: getAuthorizationHeader(),
    })
  },

  getNFTDetail(id: string): Promise<any> {
    return get(`nfts/${id}`, {
      headers: getAuthorizationHeader(),
    })
  },
}

export default NFTServices
