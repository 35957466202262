import { createSlice } from "@reduxjs/toolkit"
import {
  getUserTopCollection,
  getUserTopCreater,
  getUserTopSeller,
  getUserTopSellerNew,
} from "../../../../network/homeSellers/thunk"
import { Store } from "./../../../../app/type"
import { ListTopSeller, Seller } from "./type"

const initialState: ListTopSeller = {
  data: <Seller[]>[],
  dataCollection: [],
  dataCreate: [],
  dataSellerNew: [],
  totalCreate: 0,
  totalSeller: 0,
  totalCollection: 0,
}

export const slice = createSlice({
  name: "listTopSellers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserTopSeller.pending, (state) => {})
    builder.addCase(getUserTopSeller.fulfilled, (state, action) => {
      state.data = [...action.payload.data]
    })
    builder.addCase(getUserTopSeller.rejected, (state) => {})
    builder.addCase(getUserTopCreater.fulfilled, (state, action) => {
      state.dataCreate = [...action.payload.data.data.result]
      state.totalCreate = action.payload.data.data.total
    })
    builder.addCase(getUserTopCollection.fulfilled, (state, action) => {
      state.dataCollection = [...action.payload.data.data]
      state.totalCollection = action.payload.data.totalRecord
    })
    builder.addCase(getUserTopSellerNew.fulfilled, (state, action) => {
      state.dataSellerNew = [...action.payload.data.data]
      state.totalSeller = action.payload.data?.totalRecord
    })
  },
})

export const selectTopSellerList = (state: Store) => state.listTopSellers.data
export const selectTopCreateList = (state: Store) =>
  state.listTopSellers.dataCreate
export const selectTopCollectionList = (state: Store) =>
  state.listTopSellers.dataCollection
export const selectTopSellerListNew = (state: Store) =>
  state.listTopSellers.dataSellerNew
export const selectTopTotalCreate = (state: Store) =>
  state.listTopSellers.totalCreate
export const selectTopTotalSeller = (state: Store) =>
  state.listTopSellers.totalSeller
export const selectTopTotalCollection = (state: Store) =>
  state.listTopSellers.totalCollection

export default slice.reducer
