import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../../../app/type"
import {
  getNotifications,
  getNotificationsSetting,
} from "../../../../../network/notifications/thunk"
import { NotificationStore } from "./type"

const initialState: NotificationStore = {
  notificationList: [],
  readNotification: false,
  readAllNotification: false,
  notificationSetting: {},
  isHasNotification: false,
}

const slice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clearNotificaitonsList: (state) => {
      state.notificationList = {}
    },
    clearReadNotification: (state) => {
      state.readNotification = false
      state.readAllNotification = false
    },
    readNotificationSuccess: (state) => {
      state.readNotification = true
    },

    readNotificationFaild: (state) => {
      state.readNotification = false
    },
    readAllNotificationSuccess: (state) => {
      state.readAllNotification = true
    },
    readAllNotificationFaild: (state) => {
      state.readAllNotification = false
    },
    getUnreadNotification: (state, { payload }) => {
      state.isHasNotification = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notificationList = action.payload.data
    })
    builder.addCase(getNotificationsSetting.fulfilled, (state, action) => {
      state.notificationSetting = action.payload.data
    })
  },
})

export const selectNotificationList = (state: Store) =>
  state.notification.notificationList
export const selectReadNotification = (state: Store) =>
  state.notification.readNotification
export const selectReadAllNotification = (state: Store) =>
  state.notification.readAllNotification
export const selectNotificationSetting = (state: Store) =>
  state.notification.notificationSetting
export const selectIsHasNotification = (state: Store) =>
  state.notification.isHasNotification

export const {
  clearNotificaitonsList,
  readNotificationSuccess,
  readNotificationFaild,
  readAllNotificationSuccess,
  readAllNotificationFaild,
  clearReadNotification,
  getUnreadNotification,
} = slice.actions

export default slice.reducer
