import useClickOutside from '@/hooks/useClickOutside';
import React, { PropsWithChildren, useCallback, useState } from 'react';

interface Item {
  label: string
  icon: string
  onClick: () => void
}

interface PopoverProps {
  items: Item[]
}

function Popover({children, items, ...props}: PropsWithChildren<PopoverProps>) {
  const [open, setOpen] = useState(false)
  const handleClickOutside = () => {
    setOpen(false)
  }

  const ref = useClickOutside(handleClickOutside)

  return (
    <div className='relative cursor-pointer w-fit' onClick={() => setOpen(val => !val)} ref={ref}>
      {children}
      {open && <div className="absolute top-[calc(100%+10px)] right-0 bg-white rounded p-4 border border-[#E6E6E6] w-fit space-y-2">
        {
          items.map(item => {
            return (
              <div className="py-2 px-4 flex items-center space-x-2 hover:bg-[#F0F0F0] rounded-[4px] cursor-pointer"
                onClick={() => item.onClick()}
              >
                <img src={item.icon} width={24} alt="" />
                <div className="font-bold whitespace-nowrap flex-1">{item.label}</div>
              </div>
            )
          })
        }
      </div>}
    </div>
  );
}

export default Popover;