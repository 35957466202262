import { Endpoint } from "./../endpoint"
import { getAuthorizationHeader } from "../../utils/authentication"
import { get, patch, post } from "../axiosClient"
import {
  LikeNFTRequest,
  LikeNFTResponse,
  NFTDetailEventRequest,
  NFTDetailRequest,
  NFTDetailResponse,
} from "./type"

const NFTDetailServices = {
  nftDetail(params: NFTDetailRequest): Promise<NFTDetailResponse> {
    return get(
      `${Endpoint.DETAIL_ON_MARKET}/${params.contractAddress}/${
        params.nft
      }?user_id=${params.user_id}&target_user_id=${
        params?.target_user_id || ""
      }&show_in_created=${params?.show_in_created || ""}`
    )
  },

  likeNFT(params: LikeNFTRequest): Promise<LikeNFTResponse> {
    return post(Endpoint.LIKE_NFT, params, {
      headers: getAuthorizationHeader(),
    })
  },

  dislikeNFT(params: LikeNFTRequest): Promise<LikeNFTResponse> {
    return post(Endpoint.DISLIKE_NFT, params, {
      headers: getAuthorizationHeader(),
    })
  },
  nftDetailEvent(params: NFTDetailEventRequest): Promise<NFTDetailResponse> {
    return get(
      `${Endpoint.DETAIL_ON_EVEMT}${params.contractAddress}/${params.tokenId}?user_id=${params.user_id}`
    )
  },

  getNftDetail(id: string): Promise<any> {
    return get(`${Endpoint.NFT}/${id}`, {
      headers: getAuthorizationHeader(),
    })
  },

  getNftReveal(id: string): Promise<any> {
    return get(`${Endpoint.NFT}/reveal/${id}`, {
      headers: getAuthorizationHeader(),
    })
  },

  getNFTFreezeUri(id: string): Promise<any> {
    return patch(`nfts/freeze/${id}`, {})
  },
}

export default NFTDetailServices
