import Web3Contract from "@/utils/Web3Contract"
import ABI from "../abi/token_abi.json"
import { useState, useCallback, useEffect } from "react"
import { OPV } from "@/utils/constant"

const { contract: contractMethod } = new Web3Contract(OPV, ABI)

const useGetDecimalOPV = (): number => {
  const [data, setData] = useState<number>(0)

  const getData = useCallback(async () => {
    if (contractMethod) {
      try {
        const response = await contractMethod.methods.decimals().call()
        setData(+response)
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  return data
}

export default useGetDecimalOPV
