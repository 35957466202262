import {
  ICountUserBody,
  countNewUserByDate,
  countTotalUserByDate,
  countTotalVisitor,
} from "@/network/countUser"
import { DatePicker } from "antd"
import React, { useEffect, useState } from "react"
import { formatNumberToCommas } from "../homePage/components/tables/Collections"

const { RangePicker } = DatePicker

const Overview = () => {
  const [visitor, setVisitor] = useState<number>(0)
  const [totalUser, setTotalUser] = useState<number>(0)
  const [newUser, setNewUser] = useState<number>(0)

  const [filter, setFillter] = useState<ICountUserBody>({
    startDate: "2023, 8, 01",
    endDate: "2024, 01, 01",
  })

  const onRangePickerChange = (value: any) => {
    const startDate = value[0].format("YYYY, MM, DD")
    const endDate = value[1].format("YYYY, MM, DD")
    setFillter({ startDate, endDate })
  }

  useEffect(() => {
    countNewUserByDate(filter).then((res) => {
      setNewUser(res)
    })

    countTotalVisitor(filter).then((res) => {
      setVisitor(res)
    })

    countTotalUserByDate(filter).then((res) => {
      setTotalUser(res)
    })
  }, [filter])

  return (
    <div className="p-5">
      <div className="flex items-start justify-between">
        <h1 className="text-[40px] font-bold">Overview</h1>
        <RangePicker allowClear={false} onChange={onRangePickerChange} />
      </div>

      <div className="grid grid-cols-3 gap-5">
        <div className="bg-[#F5F5F5] border border-[#E6E6E6] rounded-2xl p-6 flex flex-col">
          <p className="text-2xl font-bold text-center m-0">
            {formatNumberToCommas(visitor)}
          </p>
          <p className="text-base font-semibold text-[#666666] text-center m-0">
            Total visitors
          </p>
        </div>
        <div className="bg-[#F5F5F5] border border-[#E6E6E6] rounded-2xl p-6 flex flex-col">
          <p className="text-2xl font-bold text-center m-0">
            {formatNumberToCommas(totalUser)}
          </p>
          <p className="text-base font-semibold Tổng số lượt ghé trangtext-[#666666] text-center m-0">
            Total users
          </p>
        </div>
        <div className="bg-[#F5F5F5] border border-[#E6E6E6] rounded-2xl p-6 flex flex-col">
          <p className="text-2xl font-bold text-center m-0">
            {" "}
            {formatNumberToCommas(newUser)}
          </p>
          <p className="text-base font-semibold text-[#666666] text-center m-0">
            New users
          </p>
        </div>
      </div>
    </div>
  )
}

export default Overview
