import { Menu } from "antd";
import navigation from "../../navigation";
import "./navLink.scss";
import { useHistory, useLocation } from "react-router-dom";

interface NavLinkProps {
  closeNavbar: boolean;
  setCloseNavbar: any;
}

const NavLinks = ({ setCloseNavbar }: NavLinkProps) => {
  const history = useHistory();
  const location = useLocation();

  const handleNavigate = (e: any) => {
    history.push(e.key);
    setCloseNavbar(true);
  };

  return (
    <Menu
      className="menu"
      defaultSelectedKeys={[location.pathname]}
      mode="inline"
      onClick={handleNavigate}
    >
      {navigation()?.map((item) => {
        if (item.subMenu) {
          return (
            <Menu.SubMenu key={item.title} title={item.title}>
              {item.subMenu.map((i) => (
                <Menu.Item key={i.path}>{i.title}</Menu.Item>
              ))}
            </Menu.SubMenu>
          );
        } else {
          return <Menu.Item key={item.path}>{item.title}</Menu.Item>;
        }
      })}
    </Menu>
  );
};

export default NavLinks;
