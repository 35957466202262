import { createAsyncThunk } from "@reduxjs/toolkit"
import { StatusCodeResponse } from "../../../../network/constants"
import { GetLatestTransactionParams } from "../../../../network/homepage/latestTransaction/latestTransaction"
import latestTransactionServices from "../../../../network/homepage/latestTransaction/services"
import { hideLoading, showLoading } from "../../../common/loading/store/slice"

export const getListTransaction = createAsyncThunk(
  "home/LATEST_TRANSACTION",
  async (params: GetLatestTransactionParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await latestTransactionServices.getListTransaction(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data
    }
    return rejectWithValue("Error")
  }
)
