import { useMemo } from "react";
import SearchInput from "./inputs/Search";
import { Button, Dropdown, Grid, Menu } from "antd";
import { routes } from "@/router/constants";
import { useHistory, useLocation } from "react-router-dom";
const { useBreakpoint } = Grid;

interface IHeaderLeftProps {}

const HeaderLeft = ({}: IHeaderLeftProps) => {
  const history = useHistory();
  const location = useLocation();
  const breakPoint = useBreakpoint();
  const groupButton = [
    {
      title: "Explore",
      path: routes.EXPLORE_NFT,
      type: "button",
      className: "btn-menu",
    },
    {
      title: "Stats",
      path: "/",
      type: "button",
      className: "btn-menu",
      submenu: [
        { title: "Rankings", key: "1", path: "/ranking" },
        { title: "Activities", key: "2", path: "/activities" },
      ],
    },
    {
      title: "Launchpad",
      path: routes.LAUNCHPAD,
      type: "button",
      className: "btn-menu",
    },
    {
      title: "Lottery",
      path: routes.LOTTERY,
      type: "button",
      className: "btn-menu",
    },
  ];

  const iconLogo = useMemo(() => {
    return (
      <img
        src={require("@/assets/images/new-design/logo-header1.png")}
        alt=""
        className="anticon-logo"
        onClick={() => handleChangePage(routes.HOMEPAGE)}
      />
    );
  }, []);

  const renderGroupButton = useMemo(() => {
    return (
      <>
        {iconLogo}
        {breakPoint?.lg && (
          <SearchInput
            whiteBg={[
              routes.LAUNCHPAD,
              routes.RANKING,
              routes.EXPLORE_NFT,
            ].includes(location.pathname)}
          />
        )}
        <div className="group-btn gap-6">
          {groupButton.map((_button) => {
            if (_button?.type === "img") {
              return (
                <img
                  key={_button?.title ?? ""}
                  className={_button?.className ?? ""}
                  src={require("@/assets/images/new-design/logo-header1.png")}
                  alt=""
                />
              );
            }
            if (_button?.submenu && _button?.submenu?.length > 0) {
              const menuCreate = (
                <Menu>
                  {_button?.submenu.map((_submenu) => {
                    return (
                      <Menu.Item
                        key={_submenu.title}
                        onClick={() => handleChangePage(_submenu?.path ?? "/")}
                      >
                        <p
                          className={`p-0.5 text-base font-bold ${
                            location.pathname === _submenu.path
                              ? "text-[#2750FB]"
                              : "text-text"
                          }`}
                        >
                          {_submenu?.title}
                        </p>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              );
              return (
                <Dropdown
                  overlay={menuCreate}
                  placement="bottom"
                  trigger={["hover", "click"]}
                  key={_button?.title}
                  overlayClassName="header-dropdown"
                >
                  <Button
                    key={_button?.type}
                    type="link"
                    className={`${_button?.className} !p-0`}
                  >
                    {_button?.title}
                  </Button>
                </Dropdown>
              );
            }
            return (
              <Button
                onClick={() => handleChangePage(_button?.path ?? "")}
                key={_button?.type}
                type="link"
                className={`${_button?.className} !p-0 ${
                  location.pathname === _button.path ? "!text-[#2750FB]" : ""
                }`}
              >
                {_button?.title}
              </Button>
            );
          })}
        </div>
      </>
    );
  }, [breakPoint?.lg, location.pathname]);

  function handleChangePage(path: string) {
    history.push({ pathname: path });
  }

  return <div className="header-left-wrapper">{renderGroupButton}</div>;
};

export default HeaderLeft;
