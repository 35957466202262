import { ethers } from "ethers"
import { useCallback } from "react"

export default function useGetGasPrice() {
  const provider = new ethers.providers.JsonRpcProvider(
    process.env.REACT_APP_RPC_URL
  )
  const getGasPrice = useCallback(async () => {
    const feeData = await provider.getFeeData()
    return feeData?.gasPrice ? feeData?.gasPrice.toNumber() / 10 ** 9 : 0
  }, [])
  return getGasPrice
}
