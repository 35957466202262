// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-connect-wallet {
  width: 100%;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #2750fb;
  height: 42px;
  color: #fafafa;
  /* Desk/L - Title */
  font-size: 16px;
  font-family: "Arabic Pro";
  font-style: normal;
  font-weight: 700;
  line-height: 24px; }

.ant-btn:hover,
.ant-btn:focus {
  border-color: #2750fb;
  color: #2750fb; }
`, "",{"version":3,"sources":["webpack://./src/components/common/menu/headerMenu/components/buttons/connectWallet.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EAEZ,cAAc;EACd,mBAAA;EACA,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAA;;AAGnB;;EAEE,qBAAqB;EACrB,cAAc,EAAA","sourcesContent":[".btn-connect-wallet {\n  width: 100%;\n  display: flex;\n  padding: 10px 16px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 8px;\n  background: #2750fb;\n  height: 42px;\n\n  color: #fafafa;\n  /* Desk/L - Title */\n  font-size: 16px;\n  font-family: \"Arabic Pro\";\n  font-style: normal;\n  font-weight: 700;\n  line-height: 24px;\n}\n\n.ant-btn:hover,\n.ant-btn:focus {\n  border-color: #2750fb;\n  color: #2750fb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
