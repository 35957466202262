import { getParamsForApiList } from "../../../utils/util"
import { get } from "../../axiosClient"
import { GetAuctionParams, GetAuctionResponse } from "./type"

const homeAuctionServices = {
  getAuction(params: GetAuctionParams): Promise<GetAuctionResponse> {
    return get(`/market-place/list-item${getParamsForApiList(params)}`)
  },
}

export default homeAuctionServices
