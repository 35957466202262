export { ReactComponent as VerifyBlueTick } from "./VerifyBlueTick.svg";
export { ReactComponent as BrowsersLink } from "./BrowsersLink.svg";
export { ReactComponent as RectangleGutter } from "./RectangleGutter.svg";
export { default as envelopeIcon } from "./envelope.svg";
export { ReactComponent as ArrowUp } from "./arrow_up.svg";
export { ReactComponent as BuyTicket } from "./buy_ticket.svg";
export { ReactComponent as CoinWallet } from "./coin_wallet.svg";
export { ReactComponent as DashedCircle } from "./dashed_circle.svg";
export { ReactComponent as ArrowDown } from "./arrow_down.svg";
export { ReactComponent as SmallLogo } from "./small_logo.svg";
export { ReactComponent as DashedArrow } from "./dashed_arrow.svg";
export { ReactComponent as TetherLogo } from "./tether_logo.svg";
export { ReactComponent as FilterIconNft } from "./FilterIconNft.svg";
export { ReactComponent as Copy } from "./copy.svg";
export { ReactComponent as BlueTick } from "./blue_tick.svg";
export { ReactComponent as AppOutlined } from "./AppOutlined.svg";
export { ReactComponent as User } from "./user.svg";
export { ReactComponent as Collection } from "./collection.svg";
export { ReactComponent as Eye } from "./eye.svg";
export { ReactComponent as HelpCenter } from "./help_center.svg";
export { ReactComponent as Setting } from "./setting.svg";
export { ReactComponent as Wallet } from "./wallet.svg";
export { ReactComponent as Bell } from "./bell.svg";
export { ReactComponent as Contract } from "./contract.svg";
export { ReactComponent as QRcode } from "./QRcode.svg";
export { ReactComponent as EditNew } from "./EditNew.svg";
