import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  hideLoading,
  showLoading,
} from "../../components/common/loading/store/slice"
import { StatusCodeResponse } from "../constants"
import { GetTopSellerServices } from "./services"
import { GetUserTopSellerRequest, ParamsRanking } from "./type"

export const getUserTopSeller = createAsyncThunk(
  "GET_USER_TOP_SELLER",
  async (params: string, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await GetTopSellerServices.getDataTopSeller(params)
    dispatch(hideLoading())

    if (res.status === StatusCodeResponse.SUCCESS) {
      return {
        data: res.data.data,
      }
    }
    return rejectWithValue(null)
  }
)

export const getUserTopCreater = createAsyncThunk(
  "GET_USER_TOP_CREATER",
  async (params: ParamsRanking, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await GetTopSellerServices.getDataTopCreater(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return {
        data: res.data,
      }
    }
    return rejectWithValue(null)
  }
)

export const getUserTopCollection = createAsyncThunk(
  "GET_USER_TOP_COLLECTION",
  async (params: ParamsRanking, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await GetTopSellerServices.getDataTopCollection(params)
    dispatch(hideLoading())

    if (res.status === StatusCodeResponse.SUCCESS) {
      return {
        data: res.data,
      }
    }
    return rejectWithValue(null)
  }
)

export const getUserTopSellerNew = createAsyncThunk(
  "GET_USER_TOP_SELLER_NEW",
  async (params: ParamsRanking, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await GetTopSellerServices.getDataTopSellerNew(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return {
        data: res.data,
      }
    }
    return rejectWithValue(null)
  }
)
