import { setModalAddFund } from "@/app/modal/actions"
import { Modal } from "antd"
import QRCode from "qrcode.react"
import { useDispatch, useSelector } from "react-redux"
import CopyTooltip from "../common/copyTooltip"
import { selectWalletAddress } from "../connectWallet/store/slice"
import "./styles.scss"

const ModalAddFund = () => {
  const dispatch = useDispatch()
  const { modalAddFund } = useSelector((state: any) => state.modal)
  const account = useSelector(selectWalletAddress)

  const handleCancel = () => {
    dispatch(
      setModalAddFund({
        toggle: false,
        data: {},
      })
    )
  }

  return (
    <Modal
      title="Add funds"
      visible={modalAddFund?.toggle}
      onOk={handleCancel}
      onCancel={handleCancel}
      className="modal-add-fund"
      footer={false}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-center md:w-[200px] md:h-[200px] h-[80px] w-[80px] mx-auto">
          <QRCode
            id="iconCheckmarkLg"
            renderAs="svg"
            value={account}
            includeMargin
            fgColor="#000"
            bgColor="#fff"
            width="100%"
            height="100%"
          />
        </div>

        <p className="m-0 w-full text-text text-xs md:text-base md:mt-[4px]">
          Transfer funds from an or another wallet to your wallet address
          bellow:
        </p>

        <div className="w-full">
          <CopyTooltip value={account || ""}>
            <div className="flex items-center space-x-[10px] w-full border border-[#E6E6E6] rounded-[8px] px-4 py-3">
              <p className="m-0 flex-1 text-text text-xs md:text-base text-ellipsis-custom">
                {account || ""}
              </p>
              <img
                src={require("@/assets/images/new-design/icon-copy-black.png")}
                width="20"
                alt=""
              />
            </div>
          </CopyTooltip>
        </div>

        <p className="m-0 text-[#999] text-xs md:text-base text-center">
          Using BEP 20 (BSC) network to add token{" "}
        </p>
      </div>
    </Modal>
  )
}

export default ModalAddFund
