import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  hideLoading,
  showLoading,
} from "../../components/common/loading/store/slice"
import {
  readAllNotificationSuccess,
  readNotificationSuccess,
} from "../../components/common/menu/notification/store/slice"
import { changeMessage } from "../../components/common/toastMessage/store/slice"
import { StatusCodeResponse } from "../constants"
import notificationServices from "./services"
import {
  GetNotificationsRequest,
  PostNotificationsRequest,
  PostNotificationsSettingRequest,
} from "./type"

export const getNotifications = createAsyncThunk(
  "GET_NOTIFICATIONS",
  async (params: GetNotificationsRequest, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await notificationServices.getNotifications(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return {
        data: res.data.data,
      }
    }
    return rejectWithValue(null)
  }
)

export const readNotificationOne = createAsyncThunk(
  "post/NOTIFICATIONS",
  async (params: PostNotificationsRequest, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await notificationServices.postNotificationsReadOne(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      dispatch(readNotificationSuccess())
      dispatch(
        changeMessage({
          message: "Read notification successfully",
          statusCode: res.status,
        })
      )
    } else {
      dispatch(
        changeMessage({
          message: "Read notification failed",
          statusCode: res.status,
        })
      )
      return rejectWithValue(res.status)
    }
  }
)

export const readNotificationAll = createAsyncThunk(
  "post/NOTIFICATIONS_REAL_ALL",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await notificationServices.postNotificationsReadAll()
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      dispatch(readAllNotificationSuccess())
      dispatch(
        changeMessage({
          message: "Read notification successfully",
          statusCode: res.status,
        })
      )
    } else {
      dispatch(
        changeMessage({
          message: "Read notification failed",
          statusCode: res.status,
        })
      )
      return rejectWithValue(res.status)
    }
  }
)

export const getNotificationsSetting = createAsyncThunk(
  "GET_NOTIFICATIONS_SETTING",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await notificationServices.getNotificationsSetting()
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return {
        data: res.data.data,
      }
    }
    return rejectWithValue(null)
  }
)

export const postNotificationSetting = createAsyncThunk(
  "post/NOTIFICATIONS",
  async (
    params: PostNotificationsSettingRequest,
    { rejectWithValue, dispatch }
  ) => {
    dispatch(showLoading())
    const res = await notificationServices.postNotificationsSetting(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      dispatch(
        changeMessage({
          message: "Update setting notification successfully",
          statusCode: res.status,
        })
      )
    } else {
      dispatch(
        changeMessage({
          message: "Update setting notification failed",
          statusCode: res.status,
        })
      )
      return rejectWithValue(res.status)
    }
  }
)
