import { getAuthorizationHeader, getUserId } from "../../utils/authentication"
import { getParamsForApiList } from "../../utils/util"
import { deleteRequest, get, patch, post } from "../axiosClient"
import { Endpoint } from "../endpoint"
import { GetUserCollectionRequest, GetUserCollectionResponse } from "./type"

const CollectionServices = {
  getUserCollection(
    params: GetUserCollectionRequest
  ): Promise<GetUserCollectionResponse> {
    return get(
      `${Endpoint.USER_COLLECTION}${getParamsForApiList(params)}`,
      getUserId() === "" ? {} : { headers: getAuthorizationHeader() }
    )
  },

  getListCollection(payload: any): Promise<any> {
    return post("collections/search", payload)
  },

  getListNFTCollection(params: any): Promise<any> {
    return get(
      `item/collection/item/${params.id}?status=${
        params.status !== undefined ? params.status : ""
      }&limit=${params.limit}&current_user_id=${params.user_id}&stts=${
        params.stts !== undefined ? params.stts : ""
      }&is_approval=${params.is_approval}&page=${params?.page || 1}&order_by=${
        params.order_by
      }&search=${params.search || ""}`,
      getUserId() === ""
        ? {}
        : {
            headers: getAuthorizationHeader(),
          }
    )
  },

  createCollection(payload: any): Promise<any> {
    return post("collections", payload, {
      headers: getAuthorizationHeader(),
    })
  },

  updateCollection(id: string, payload: any): Promise<any> {
    return patch(`collections/${id}`, payload, {
      headers: getAuthorizationHeader(),
    })
  },

  getCollectionDetail(id: string): Promise<any> {
    return get(`collections/${id}`, {
      headers: getAuthorizationHeader(),
    })
  },

  deleteCollection(id: string): Promise<any> {
    return deleteRequest(`collections/${id}`, {
      headers: getAuthorizationHeader(),
    })
  },

  addWatchList(id: string): Promise<any> {
    return post(
      `collections/watch-list/${id}`,
      {},
      {
        headers: getAuthorizationHeader(),
      }
    )
  },

  checkWatchList(id: string): Promise<any> {
    return get(`collections/watch-list/check/${id}`, {
      headers: getAuthorizationHeader(),
    })
  },

  // getStatsCollection(params: any): Promise<any> {
  //   return get(`item/collection/${params.id}/stats?time=${params.time}`, {
  //     headers: getAuthorizationHeader(),
  //   })
  // },
}

export default CollectionServices
