import { Button, Modal, Typography } from "antd";
import "./signWallet.scss";
import { useLayoutContext } from "../../../Menu";
import { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { changeMessage } from "@/components/common/toastMessage/store/slice";
import { useDispatch } from "react-redux";

interface SignWalletModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SignWalletModal = ({ open = false, setOpen }: SignWalletModalProps) => {
  const dispatch = useDispatch();
  const { signAndAccept } = useLayoutContext();
  const [isShowNote, setIsShowNote] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState<boolean>(false);
  const handleOk = async () => {
    try {
      setLoading(true);
      setIsShowNote(true);
      await signAndAccept?.(handleCancel);
    } catch (err) {
      console.error(err);
      if (err.code !== "ACTION_REJECTED") {
        dispatch(
          changeMessage({
            message: `Sign failed ${JSON.stringify(err)}`,
            statusCode: 400,
          })
        );
      } else {
        dispatch(
          changeMessage({
            message: "User declined the transaction!",
            statusCode: 400,
          })
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        title={<></>}
        className="sign-wallet-wrapper"
        visible={open}
        onOk={handleOk}
        footer={
          <>
            <Button className="sign-wallet-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className="sign-wallet-btn btn-active"
              disabled={loading}
              onClick={handleOk}
            >
              {loading ? "Signing..." : "Accept and sign"}
            </Button>
          </>
        }
        closable={false}
      >
        <img
          src={require("assets/images/new-design/logo-header1.png")}
          height="35%"
          width="35%"
          alt=""
          style={{
            objectFit: "cover",
            textAlign: "center",
          }}
        />
        <Typography className="sign-wallet-title">
          Welcome to Monbase NFT
        </Typography>

        <Typography className="sign-wallet-content">
          By connecting your wallet and using Monbase NFT, you agree to our{" "}
          <a>Term of Service</a> and <a>Privacy Policy</a>.
        </Typography>

        {isShowNote && (
          <div className="sign-wallet-note">
            <InfoCircleOutlined />
            <Typography className="sign-wallet-note-content">
              Check your wallet for a signature request
            </Typography>
          </div>
        )}
      </Modal>
    </>
  );
};

export default SignWalletModal;
