import { createAsyncThunk } from "@reduxjs/toolkit"

import { StatusCodeResponse } from "./../../../network/constants"
import { GetAuctionParams } from "../../../network/homepage/homeDiscover/type"
import homeAuctionServices from "../../../network/homepage/homeDiscover/services"
import {
  hideLoading,
  showLoading,
} from "../../../components/common/loading/store/slice"

export const getExplore = createAsyncThunk(
  "explore",
  async (params: GetAuctionParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await homeAuctionServices.getAuction(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        return res.data
      } else {
        return rejectWithValue(res.status)
      }
    } catch (error) {
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)
