import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  hideLoading,
  showLoading,
} from "../../components/common/loading/store/slice"
import { StatusCodeResponse } from "../constants"
import watchListServices from "./services"

export const getWatchList = createAsyncThunk(
  "get/WATCH_LIST",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await watchListServices.getWatchList()
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data
    }
    return rejectWithValue(res.status)
  }
)
