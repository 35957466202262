import { createAsyncThunk } from "@reduxjs/toolkit"

import { StatusCodeResponse } from "./../../../../network/constants"
import { GetAuctionParams } from "../../../../network/homepage/homeDiscover/type"
import homeAuctionServices from "../../../../network/homepage/homeDiscover/services"
import { hideLoading, showLoading } from "../../../common/loading/store/slice"

export const getDiscover = createAsyncThunk(
  "home/DISCOVER",
  async (params: GetAuctionParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await homeAuctionServices.getAuction(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        return res.data
      } else {
        return rejectWithValue(res.status)
      }
    } catch (error) {
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)

export const getAuction = createAsyncThunk(
  "home/AUCTION",
  async (params: GetAuctionParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await homeAuctionServices.getAuction(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data
    }
    return rejectWithValue("Error")
  }
)
