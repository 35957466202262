import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../../app/type"
import { LoadingStore } from "./type"

const initialState: LoadingStore = {
  isLoading: <boolean>false,
}

const slice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true
    },

    hideLoading: (state) => {
      state.isLoading = false
    },
  },
})

export const { showLoading, hideLoading } = slice.actions
export const selectIsLoading = (state: Store) => state.isLoading.isLoading

export default slice.reducer
