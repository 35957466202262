import { post } from "../axiosClient";
import { searchParams, searchResponse } from "./type";

const bannerServices = {
  searchBanner(params: searchParams): Promise<searchResponse> {
    return post("/nfts/search", params);
  },
};

export default bannerServices;
