import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../app/type"
import { SearchValue } from "./type"

const initialState: SearchValue = {
  value: ""
}

const slice = createSlice({
  name: "searchValue",
  initialState,
  reducers: {
    searchValue: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { searchValue } = slice.actions

export const selectSearchValue = (state: Store) =>  state.searchValue.value


export default slice.reducer
