import { StatusCodeResponse } from "./../constants"
import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  hideLoading,
  showLoading,
} from "../../components/common/loading/store/slice"
import activitiesServices from "./services"
import { GetActivitiesParams } from "../myProfile/types"

export const getActivityProfile = createAsyncThunk(
  "activityProfile",
  async (params: GetActivitiesParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await activitiesServices.getActivitiesProfile(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        return res.data
      }
      return rejectWithValue(res.status)
    } catch (error) {
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)

export const getActivity = createAsyncThunk(
  "activity",
  async (params: GetActivitiesParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await activitiesServices.getActivity(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        return res.data
      }
      return rejectWithValue(res.status)
    } catch (error) {
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)

export const getActivityCollection = createAsyncThunk(
  "activityCollection",
  async (params: GetActivitiesParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await activitiesServices.getActivityCollection(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.SUCCESS) {
        return res.data
      }
      return rejectWithValue(res.status)
    } catch (error) {
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)
