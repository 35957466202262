import { Button } from "antd"
import "./buttonCustom.scss"
import { PropsWithChildren } from "react"
import { twMerge } from "tailwind-merge"

interface IButtonCustomProps {
  nameButton?: string
  handleClick?: () => void
  className?: string
  type: "link" | "text" | "ghost" | "default" | "primary" | "dashed" | undefined
  disabled?: boolean
  block?: boolean
}
const ButtonCustom = ({
  nameButton,
  handleClick = undefined,
  className = "",
  type = "default",
  disabled = false,
  block = false,
  children,
}: PropsWithChildren<IButtonCustomProps>) => {
  const onClick = () => {
    handleClick?.()
  }
  return (
    <Button
      type={type}
      className={`custom-btn-${type} ${className}`}
      // className={twMerge([`custom-btn-${type}`,
      //   className].join(" "))}
      onClick={onClick}
      disabled={disabled}
      style={{ height: "auto", fontWeight: "bold" }}
      block={block}
    >
      {children}
    </Button>
  )
}

export default ButtonCustom
