import { createSlice } from "@reduxjs/toolkit"
import { Store } from "../../../app/type"
import {
  fetchNFTDetail,
  getNFTDetail,
  getNFTDetailEvent,
} from "../../../network/nftDetail/thunk"
import { NFTItem } from "../../../network/nftDetail/type"
import { NFTDetailStore } from "./type"

const initialState: NFTDetailStore = {
  nftItem: <NFTItem>{},
  nftEventItem: {},
  nftInfo: null,
}

const slice = createSlice({
  name: "nftDetail",
  initialState,
  reducers: {
    clearNftItem: (state) => {
      state.nftItem = {} as NFTItem
    },
    clearNftEventItem: (state) => {
      state.nftEventItem = {} as NFTItem
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNFTDetail.fulfilled, (state, action) => {
      state.nftItem = action.payload
    })
    builder.addCase(getNFTDetailEvent.fulfilled, (state, action) => {
      state.nftEventItem = action.payload
    })
    builder.addCase(fetchNFTDetail.fulfilled, (state, action) => {
      state.nftInfo = action.payload
    })
  },
})

export const selectNFTDetail = (state: Store) => state.nftDetail.nftItem
export const selectNFTInfo = (state: Store) => state.nftDetail.nftInfo
export const selectNFTEventDetail = (state: Store) =>
  state.nftDetail.nftEventItem

export const { clearNftItem, clearNftEventItem } = slice.actions

export default slice.reducer
