/* eslint-disable react-hooks/rules-of-hooks */
import { setModalSuccess } from "@/app/modal/actions";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./styles.scss";

const ModalSuccess = () => {
  // state & hook
  const dispatch = useDispatch();
  const history = useHistory();
  const { modalSuccess } = useSelector((state: any) => state.modal);

  const handleCancel = () => {
    dispatch(
      setModalSuccess({
        toggle: false,
        data: {},
      })
    );
  };

  const handleOk = () => {
    dispatch(
      setModalSuccess({
        toggle: false,
        data: {},
      })
    );
    history.push(`/nft/detail/${modalSuccess?.data?.nft?.id}`);
  };

  return (
    <Modal
      title=""
      visible={modalSuccess?.toggle}
      onOk={handleOk}
      onCancel={handleCancel}
      className="modal-success"
      footer={false}
    >
      <div className="flex flex-col lg:space-y-6 space-y-4">
        <div className="mx-auto w-[120px] md:w-[244px] h-[120px] md:h-[244px] text-center rounded-[16px] mb-[16px] md:mb-[24px] overflow-hidden">
          {modalSuccess?.data?.nft?.animationUrl &&
          modalSuccess?.data?.nft?.animationUrl !== "Unknown" ? (
            <iframe
              src={`${
                modalSuccess?.data?.nft?.animationUrl ||
                modalSuccess?.data?.nft?.mediaUrl
              }?autoplay=1&loop=1&autopause=0&controls=0&muted=1`}
              className="absolute top-0 left-0 w-full h-full object-cover duration-[2000s] pointer-events-none"
              allowFullScreen
              title={modalSuccess?.data?.nft?.name}
            />
          ) : (
            <img
              src={
                modalSuccess?.data?.nft?.mediaUrl !== "Unknown"
                  ? modalSuccess?.data?.nft?.mediaUrl
                  : require("@/assets/images/default-avatar.png")
              }
              alt={modalSuccess?.data?.nft?.name}
              className="mx-auto object-cover w-[120px] md:w-[244px] h-[120px] md:h-[244px] rounded-[16px]"
            />
          )}
        </div>

        <p className="m-0 text-text text-center text-[16px] leading=[24px] md:text-[24px] md:leading-[32px] font-bold">
          {modalSuccess?.data?.title}
        </p>

        <div className="flex flex-col items-center">
          <p className="m-0 w-full text-text text-xs md:text-base text-center mb-[8px] font-bold">
            Transaction ID
          </p>

          <a
            href={`${process.env.REACT_APP_EXPLORER}tx/${modalSuccess?.data?.transaction}`}
            target="_blank"
            rel="noreferrer"
            className="block mx-auto text-xs text-[#2750fb] text-center text-ellipsis-custom md:max-w-[330px] max-w-[250px]"
          >
            {modalSuccess?.data?.transaction}
          </a>
        </div>

        <div className="approve-nft-action">
          <button type="button" onClick={handleCancel}>
            Close
          </button>

          <button type="button" onClick={handleOk}>
            View Item
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSuccess;
