import { post } from "@/network/axiosClient"
import { message } from "antd"
import { AxiosResponse } from "axios"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  hideLoading,
  showLoading,
} from "@/components/common/loading/store/slice"

export interface ICategory {
  deleted_at?: string | null
  name?: string
  status?: string
  created_at?: string
  updated_at?: string
  id?: string
}

export interface ICategoryResponse extends AxiosResponse {
  data: {
    data: { docs: ICategory[] }
    hasError: number
    message: string
    success?: boolean
  }
}

const useCategories = () => {
  const dispatch = useDispatch()

  const [categories, setCategories] = useState<ICategory[]>([])

  useEffect(() => {
    async function fetchData() {
      const params = {
        filter: [
          {
            name: "status",
            value: "SHOW",
            operator: "eq",
          },
        ],
        skip: 0,
        limit: 10,
        sort: {
          field: "created_at",
          order: 1,
        },
      }
      try {
        dispatch(showLoading())
        const res: ICategoryResponse = await post("/categories/search", params)
        if (res?.data.success === true) {
          setCategories(res?.data?.data?.docs ?? [])
        }
        dispatch(hideLoading())
      } catch (e) {
        message.error(e?.message)
        dispatch(hideLoading())
      }
    }
    fetchData()
  }, [])

  return { categories, setCategories }
}

export default useCategories
