import React, { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import { useToasts } from "react-toast-notifications";
import { firebaseConfig } from "@/constants/index";
import notificationServices from "@/network/notifications/services";
import { useSelector } from "react-redux";
import { selectMyProfile } from "@/components/myProfile/store/slice";

const PushNotificationContext = createContext<
  | {
      notificationPermission: NotificationPermission;
      notification: firebase.messaging.NotificationPayload | null;
      token: string | null;
      hasNewNotification: boolean;
      setHasNewNotification: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

firebase.initializeApp(firebaseConfig);

export const PushNotificationProvider = ({ children }: any) => {
  const [notificationPermission, setNotificationPermission] =
    useState<NotificationPermission>("default");
  const [notification, setNotification] =
    useState<firebase.messaging.NotificationPayload | null>(null);
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const { addToast } = useToasts();
  const myProfile = useSelector(selectMyProfile);

  useEffect(() => {
    const messaging = firebase?.messaging?.isSupported()
      ? firebase?.messaging()
      : null;

    const getToken = async () => {
      if (messaging) {
        try {
          const currentToken = await messaging?.getToken();
          if (currentToken) {
            setToken(currentToken);
          } else {
            console.log("No registration token available.");
          }
        } catch (error) {
          console.error("An error occurred while retrieving token:", error);
        }
      }
    };

    // messaging.onTokenRefresh(() => {
    //   getToken();
    // });

    messaging?.onMessage((payload) => {
      console.log("Message received. ", payload);
      setNotification(payload?.notification);
      setHasNewNotification(true);
      addToast(payload?.notification?.title, {
        appearance: "info",
        autoDismiss: true,
      });
    });

    const requestPermission = async () => {
      try {
        const permission = await Notification?.requestPermission();
        setNotificationPermission(permission);
        if (permission === "granted") {
          console.log("Notification permission granted.");
          getToken();
        } else {
          console.log("Notification permission denied.");
        }
      } catch (error) {
        console.error("An error occurred while requesting permission:", error);
      }
    };

    requestPermission();

    // return () => {
    //   messaging.onTokenRefresh(() => {});
    //   messaging.onMessage(() => {});
    // };
  }, [addToast]);

  useEffect(() => {
    if (token && myProfile?.user?.id) {
      (async () => {
        await notificationServices?.postNotificationToken(token);
      })();
    }
  }, [myProfile?.user?.id, token]);

  return (
    <PushNotificationContext.Provider
      value={{
        notification,
        notificationPermission,
        token,
        hasNewNotification,
        setHasNewNotification,
      }}
    >
      {children}
    </PushNotificationContext.Provider>
  );
};

export const usePushNotifications = () => {
  const context = useContext(PushNotificationContext);
  if (context === undefined) {
    throw new Error(
      "usePushNotifications must be used within a PushNotificationProvider"
    );
  }
  return context;
};
