import { useRef, useEffect, useCallback } from 'react';

// Hook để detect sự kiện click ra bên ngoài của element
const useClickOutside = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  }, [callback]);

  useEffect(() => {
    // Gắn sự kiện click vào document
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Hủy bỏ sự kiện khi unmount component
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return ref;
};

export default useClickOutside