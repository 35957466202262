import { createSlice } from "@reduxjs/toolkit"

import { Store } from "./../../../../app/type"
import { getDiscover } from "./thunk"
import { Auction, MetaAuction } from "../../../../network/event/event1m/type"
import { EventDiscoverStore } from "./type"

const initialState: EventDiscoverStore = {
  nftDiscoverList: [],
  metaDiscover: <MetaAuction>{},
}

export const slice = createSlice({
  name: "eventDiscover",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDiscover.fulfilled, (state, action) => {
      if (action.type === "event/DISCOVER/fulfilled") {
        state.nftDiscoverList = action.payload.data.data
        state.metaDiscover = {
          ...state.metaDiscover,
          limit: action.payload?.limit,
          page: action.payload?.page,
          totalRecord: action.payload?.totalRecord,
          meta: action.payload?.data.meta_data,
        }
      }
    })
  },
})

export const selectDiscoverList = (state: Store) =>
  state.eventDiscover.nftDiscoverList

export const selectMetaDiscover = (state: Store) =>
  state.eventDiscover.metaDiscover

export default slice.reducer
