import { createSlice } from "@reduxjs/toolkit"

import { Store } from "./../../../app/type"
import { getExplore } from "./thunk"
import {
  Auction,
  MetaAuction,
} from "../../../network/homepage/homeDiscover/type"
import { ExploreStore } from "./type"

const initialState: ExploreStore = {
  nftExploreList: [],
  metaExplore: <MetaAuction>{},
}

export const slice = createSlice({
  name: "explore",
  initialState,
  reducers: {
    clearNFTExploreList: (state) => {
      state.nftExploreList = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExplore.fulfilled, (state, action) => {
      if (action.type === "explore/fulfilled") {
        state.nftExploreList = [...action.payload.data]
        state.metaExplore = {
          ...state.metaExplore,
          limit: action.payload?.limit,
          page: action.payload?.page,
          totalRecord: action.payload?.totalRecord,
        }
      }
    })
  },
})

export const selectExploreList = (state: Store) => state.explore.nftExploreList

export const selectMetaExplore = (state: Store) => state.explore.metaExplore
export const { clearNFTExploreList } = slice.actions

export default slice.reducer
