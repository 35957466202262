import { Store } from "./../../../app/type"
import { createSlice } from "@reduxjs/toolkit"
import { StoreAuth } from "./type"

const initialState: StoreAuth = {
  accessToken: "",
  refreshToken: "",
  walletAddress: "",
}

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthentication: (state, action) => {
      state.accessToken = action.payload.accessToken || ""
      state.refreshToken = action.payload.refreshToken || ""
      state.walletAddress = action.payload.walletAddress || ""
    },

    clearToken: (state) => {
      state.accessToken = ""
      state.refreshToken = ""
      state.walletAddress = ""
    },
  },
  extraReducers: {},
})

export const { setAuthentication, clearToken } = slice.actions
export const selectAccessToken = (state: Store) => state.auth.accessToken
export const selectWalletAddress = (state: Store) => state.auth.walletAddress

export default slice.reducer
