import { Avatar, Badge, Typography } from "antd";
import "./cartItem.scss";

interface CartItemDropdownProps {
  countItem: number | null;
  url: string;
  title: string;
  description: string;
  opv: string;
  price: string;
  id: string;
}

const CartItemDropdown = ({
  countItem = null,
  url = "",
  title = "",
  description = "",
  opv = "",
  price = "",
  id = "",
}: CartItemDropdownProps) => {
  return (
    <div className="cart-item-wrapper">
      <div className="cart-item" key={id}>
        <div className="cart-item-title">
          <Badge count={countItem}>
            <Avatar
              className="title-avatar"
              shape="square"
              src={url}
              alt={title}
            />
          </Badge>
          <div className="title-content">
            <Typography className="title-name">{title}</Typography>
            <Typography className="title-description">{description}</Typography>
          </div>
        </div>
        <div className="cart-item-content">
          <Typography className="content-opv">{opv} MBC</Typography>
          <Typography className="content-price">{price}$</Typography>
        </div>
      </div>
    </div>
  );
};

export default CartItemDropdown;
