import { post } from "@/network/axiosClient"
import { getAuthorizationHeader } from "@/utils/authentication"
import { useCallback } from "react"

export default function useGetPreListing() {
  const getPreListing = useCallback(async (payload: any) => {
    const results = await post(`/transactions/pre-listing`, payload, {
      headers: getAuthorizationHeader(),
    })

    if (results?.status === 201) {
      return results?.data?.data
    } else {
      return null
    }
  }, [])

  return getPreListing
}
