import { createAsyncThunk } from "@reduxjs/toolkit"
import { StatusCodeResponse } from "../../../../network/constants"
import homeAuctionServices, {
  topCollectionServices,
  trendingCollectionServices,
} from "../../../../network/homepage/homeAuction/services"
import { hideLoading, showLoading } from "../../../common/loading/store/slice"
import { GetAuctionParams } from "./../../../../network/homepage/homeAuction/type"

export const getAuction = createAsyncThunk(
  "home/AUCTION",
  async (params: GetAuctionParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await homeAuctionServices.getAuction(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data
    }
    return rejectWithValue("Error")
  }
)

export const getTrendingCollection = createAsyncThunk(
  "home/TRENDING",
  async (params: any, { rejectWithValue, dispatch }) => {
    const res = await trendingCollectionServices.getTrendingCollection(params)
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data
    }
    return rejectWithValue("Error")
  }
)
export const changeTypeSetList = createAsyncThunk(
  "home/SET_LIST",
  async (params: any, { rejectWithValue, dispatch }) => {
    return params
  }
)

export const getTopCollection = createAsyncThunk(
  "home/TOP",
  async (params: any, { rejectWithValue, dispatch }) => {
    const res = await topCollectionServices.getTopCollection(params)
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data
    }
    return rejectWithValue("Error")
  }
)
