import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
// import { changeMessage } from "../toastMessage/store/slice";
import { Typography, Input } from "antd";
import {
  SIDEBAR_WIDTH_DEFAULT,
  SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE,
} from "../layout/verticalLayout/sideBar";
import { Link } from "react-router-dom";
import { routes } from "@/router/constants";
import { envelopeIcon } from "@/assets/icons";
import "./index.scss";

const Footer = ({
  isActive,
  borderRadius,
  label,
  logo,
  onSelect,
  closeNavbar,
  isMobile,
  ...props
}: any) => {
  const dispath = useDispatch();
  // const [selectedIcon, setSelectedIcon] = useState<number>();

  // const copyUrl = () => {
  //   let currentUrl = "Contact@openlivenft.com";
  //   navigator.clipboard.writeText(currentUrl);
  //   dispath(
  //     changeMessage({
  //       message: "Copy link success",
  //       statusCode: 200,
  //     })
  //   );
  // };

  const footerStyled = useMemo(() => {
    if (!isMobile) {
      if (!closeNavbar) {
        return {
          left: `${SIDEBAR_WIDTH_DEFAULT}px`,
          width: `calc(100% - ${SIDEBAR_WIDTH_DEFAULT}px)`,
        };
      }
      return {
        left: `${SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE}px`,
        width: `calc(100% - ${SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE}px)`,
      };
    }
    return {
      left: "0",
      width: "100%",
    };
  }, [closeNavbar, isMobile]);

  return (
    <footer>
      <div className="footer-container px-8" style={footerStyled}>
        <div className="space-y-5" style={{ flex: "1 1 5%" }}>
          <img
            src={require("@/assets/images/new-design/logo-header1.png")}
            alt="monbase logo"
            className="w-[209px] h-[50px]"
          />
          <p className="max-w-[288px] text-[14px] text-[#676472]">
            The largest NFT marketplace platform. Buy, sell and discover NFTs
            across BNB Chain.
          </p>
        </div>
        <div className="flex flex-[2] gap-[73px]">
          <div className="footer-right">
            <div className="footer-right-content">
              <Typography className="content-title">Marketplace</Typography>
              <div className="content-description">
                <Typography className="description-link">
                  <Link to={routes.EXPLORE_NFT}>Explore</Link>
                </Typography>
                <Typography className="description-link">
                  <a href="#">Help Center</a>
                </Typography>
              </div>
            </div>
            <div className="footer-right-content">
              <Typography className="content-title">Links</Typography>
              <div className="content-description">
                <Typography className="description-link">
                  <a href="#">About Us</a>
                </Typography>
                <Typography className="description-link">
                  <a
                    href="https://nftdocument.monbase.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Docs
                  </a>
                </Typography>
                <Typography className="description-link">
                  <a href="#">Support</a>
                </Typography>
              </div>
            </div>
            <div className="footer-right-content">
              <Typography className="content-title">Join Community</Typography>
              <div className="content-description">
                <Typography className="description-link">
                  <a
                    href="https://x.com/monbaseglobal"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitter
                  </a>
                </Typography>
                <Typography className="description-link">
                  <a
                    href="https://t.me/MonbaseGlobal_Announcements"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Telegram Announcements
                  </a>
                </Typography>
                <Typography className="description-link">
                  <a
                    href="https://t.me/monbaseglobalchat"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Telegram Chat
                  </a>
                </Typography>
                <Typography className="description-link">
                  <a
                    href="https://www.facebook.com/MonbaseCEX"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </Typography>
                {/* <Typography className="description-link">
								<a href="#" target="_blank" rel="noreferrer">
									Linkedin
								</a>
							</Typography> */}
                <Typography className="description-link">
                  <a
                    href="https://www.youtube.com/@monbase.global"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Youtube
                  </a>
                </Typography>
              </div>
            </div>

            <div className="footer-right-content max-w-[284px]">
              <div className="footer-left-title">
                <Typography>Stay in the loop</Typography>
              </div>
              <div className="footer-left-content">
                {/* <Typography className="content-description">
              Join our mailing list to stay in the loop with our newest feature
              releases, tips and tricks for navigating Monbase NFT
            </Typography> */}
                <Input
                  className="input-address"
                  placeholder="Your e-mail"
                  suffix={<img src={envelopeIcon} alt="envelope icon" />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-t-[#E2E2E4] py-[30px] flex items-center justify-center gap-[60px]">
        <span className="text-[14px] text-[#676472]">© Monbase NFT, Inc.</span>
        <div className="flex flex-col md:flex-row mb:items-center mb:gap-5">
          <a href="/" className="text-[14px] text-[#676472]">
            Community Guidelines
          </a>
          <a href="/" className="text-[14px] text-[#676472]">
            Term
          </a>
          <a href="/" className="text-[14px] text-[#676472]">
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
