// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-wrapper .group-categories-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  overflow: scroll;
  overflow-y: hidden;
  max-width: 100%; }
  @media (max-width: 768px) {
    .header-wrapper .group-categories-wrapper {
      gap: 16px; } }
  .header-wrapper .group-categories-wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 16px;
    width: 0em;
    height: 0em; }
  .header-wrapper .group-categories-wrapper::-webkit-scrollbar {
    width: 0px;
    height: 0px; }
  .header-wrapper .group-categories-wrapper .btn-text {
    color: var(--grayscale-light-secondary, #666);
    /* Desk/L */
    font-family: "Arabic Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    height: 48px; }
    @media (max-width: 768px) {
      .header-wrapper .group-categories-wrapper .btn-text {
        color: var(--grayscale-light-secondary, #666);
        /* Mobile/L-Title */
        font-family: "Arabic Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        /* 133.333% */
        height: 32px; } }
  .header-wrapper .group-categories-wrapper .btn-text.active {
    border-radius: 8px;
    background: var(--grayscale-light-90, #e6e6e6); }
`, "",{"version":3,"sources":["webpack://./src/components/common/menu/headerMenu/components/buttons/groupCategories/groupCategories.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,kBAAkB;EAClB,eAAe,EAAA;EACf;IARF;MASI,SAAS,EAAA,EAqCZ;EA9CD;IAYI,gBAAgB;IAChB,mBAAmB;IACnB,UAAU;IACV,WAAW,EAAA;EAff;IAkBI,UAAU;IACV,WAAW,EAAA;EAnBf;IAuBI,6CAA6C;IAC7C,WAAA;IACA,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IAAE,SAAA;IACnB,YAAY,EAAA;IACZ;MA/BJ;QAgCM,6CAA6C;QAC7C,mBAAA;QACA,yBAAyB;QACzB,eAAe;QACf,kBAAkB;QAClB,gBAAgB;QAChB,iBAAiB;QAAE,aAAA;QACnB,YAAY,EAAA,EAEf;EAzCH;IA2CI,kBAAkB;IAClB,8CAA8C,EAAA","sourcesContent":[".header-wrapper .group-categories-wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  gap: 24px;\n  overflow: scroll;\n  overflow-y: hidden;\n  max-width: 100%;\n  @media (max-width: 768px) {\n    gap: 16px;\n  }\n  &::-webkit-scrollbar-thumb {\n    background: #888;\n    border-radius: 16px;\n    width: 0em;\n    height: 0em;\n  }\n  &::-webkit-scrollbar {\n    width: 0px;\n    height: 0px;\n  }\n\n  .btn-text {\n    color: var(--grayscale-light-secondary, #666);\n    /* Desk/L */\n    font-family: \"Arabic Pro\";\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px; /* 150% */\n    height: 48px;\n    @media (max-width: 768px) {\n      color: var(--grayscale-light-secondary, #666);\n      /* Mobile/L-Title */\n      font-family: \"Arabic Pro\";\n      font-size: 12px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: 16px; /* 133.333% */\n      height: 32px;\n    }\n  }\n  .btn-text.active {\n    border-radius: 8px;\n    background: var(--grayscale-light-90, #e6e6e6);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
