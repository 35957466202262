import { getAuthorizationHeader } from "../../utils/authentication"
import { get } from "../axiosClient"
import { GetWatchListResponse } from "./type"

const watchListServices = {
  getWatchList(): Promise<GetWatchListResponse> {
    return get("/v1/user/watch-list", {
      headers: getAuthorizationHeader(),
    })
  },
}

export default watchListServices
