import "./cartFooter.scss"

interface ICartFooterProps {
  children: React.ReactNode
  buttonContent?: React.ReactNode
  className?: string
}

const CartFooter = ({ children, buttonContent }: ICartFooterProps) => {
  return (
    <div className="cart-footer-wrapper">
      <div className="cart-footer-container">
        {children}
        {buttonContent}
      </div>
    </div>
  )
}

export default CartFooter
