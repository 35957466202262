import { useMemo } from "react";
import HeaderLeft from "./components/HeaderLeft";
import HeaderRight from "./components/HeaderRight";
import "./headerMenu.scss";
import {
  SIDEBAR_WIDTH_DEFAULT,
  SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE,
} from "../../layout/verticalLayout/sideBar";
import GroupCategoriesButton from "./components/buttons/groupCategories";
import { useLocation } from "react-router-dom";
import { routes } from "@/router/constants";

interface IHeaderMenuProps {
  closeNavbar: boolean;
  isMobile: boolean;
  setCloseNavbar: (closeNavbar: boolean) => void;
  isSigned: boolean;
}
const HeaderMenu = ({
  closeNavbar,
  isMobile,
  setCloseNavbar,
  isSigned,
}: IHeaderMenuProps) => {
  const { pathname } = useLocation();

  const headerStyled = useMemo(() => {
    if (!isMobile) {
      if (!closeNavbar) {
        return {
          left: `${SIDEBAR_WIDTH_DEFAULT}px`,
          width: `calc(100% - ${SIDEBAR_WIDTH_DEFAULT}px)`,
        };
      }
      return {
        left: `${SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE}px`,
        width: `calc(100% - ${SIDEBAR_WIDTH_DEFAULT_AFTER_CLOSE}px)`,
      };
    }
    return {
      left: "0",
      width: "100%",
    };
  }, [closeNavbar, isMobile]);

  const pathNameProps = useMemo(() => {
    const res = {
      addOnClassname: "",
      title: undefined,
      showAffixHeader: false,
      note: undefined,
    };

    try {
      if (pathname === routes.LAUNCHPAD) {
        res.addOnClassname = " header-wrapper-launchpad";
        res.showAffixHeader = true;
        res.title = "Launchpad";
      }
      if (pathname === routes.RANKING) {
        res.addOnClassname = " header-wrapper-launchpad";
        res.showAffixHeader = true;
        res.title = "Collections Stats";
      }

      if (pathname === routes.EXPLORE_NFT) {
        res.addOnClassname = " header-wrapper-launchpad";
        res.showAffixHeader = true;
        res.title = "Explore";
      }

      if (pathname === routes.SETTINGS_LAYOUT) {
        res.addOnClassname = " header-wrapper-launchpad";
        res.showAffixHeader = true;
        res.title = "Settings";
      }

      if (pathname === routes.CREATE_NFT) {
        res.addOnClassname = " header-wrapper-launchpad";
        res.showAffixHeader = true;
        res.title = "Create an NFT";
        res.note =
          "Once your item is minted you will not be able to change any of its information.";
      }

      if (pathname === routes.CREATE_COLLECTION) {
        res.addOnClassname = " header-wrapper-launchpad";
        res.showAffixHeader = true;
        res.title = "Create a new collection";
      }

      return res;
    } catch (error) {
      return res;
    }
  }, [pathname]);

  return (
    <div className={"launchpad-header-wrapper"}>
      <div
        className={"header-wrapper" + pathNameProps.addOnClassname}
        style={headerStyled}
      >
        <HeaderLeft />
        <HeaderRight
          setCloseNavbar={setCloseNavbar}
          closeNavbar={closeNavbar}
          isSigned={isSigned}
        />
        {pathname?.includes("reward") ? <></> : <GroupCategoriesButton />}
      </div>
      {pathNameProps?.showAffixHeader ? (
        <div className="launchpad-header-container">
          <h1>{pathNameProps.title}</h1>
          {pathNameProps.note ? <span>{pathNameProps.note}</span> : <></>}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HeaderMenu;
