import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  hideLoading,
  showLoading,
} from "../../components/common/loading/store/slice"
import { StatusCodeResponse } from "../constants"
import CollectionServices from "./services"
import { GetListCollectionRequest, GetUserCollectionRequest } from "./type"

export const getUserCollection = createAsyncThunk(
  "GET_USER_COLLECTION",
  async (params: GetUserCollectionRequest, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await CollectionServices.getUserCollection(params)
    dispatch(hideLoading())

    if (res.status === StatusCodeResponse.SUCCESS) {
      return {
        data: res.data.data,
        meta: {
          limit: res.data.limit,
          page: res.data.page,
          totalRecord: res.data.totalRecord,
        },
      }
    }
    return rejectWithValue(null)
  }
)

export const getUserCollectionSearch = createAsyncThunk(
  "GET_USER_COLLECTION_SEARCH",
  async (params: GetUserCollectionRequest, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await CollectionServices.getUserCollection(params)
    dispatch(hideLoading())

    if (res.status === StatusCodeResponse.SUCCESS) {
      return {
        data: res.data.data,
        meta: {
          limit: res.data.limit,
          page: res.data.page,
          totalRecord: res.data.totalRecord,
        },
      }
    }
    return rejectWithValue(null)
  }
)

export const getListCollection = createAsyncThunk(
  "GET_LIST_COLLECTION",
  async (params: any, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await CollectionServices.getListCollection(params)
    dispatch(hideLoading())

    if (res.status === StatusCodeResponse.CREATED) {
      return res.data.data
    }

    return rejectWithValue(null)
  }
)

export const getListNFTCollection = createAsyncThunk(
  "GET_LIST_NFT_COLLECTION",
  async (params: any, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await CollectionServices.getListNFTCollection(params)
    dispatch(hideLoading())

    if (res.status === StatusCodeResponse.SUCCESS) {
      return {
        data: res.data.data,
        meta: {
          limit: res.data.limit,
          page: res.data.page,
          totalRecord: res.data.totalRecord,
        },
      }
    }

    return rejectWithValue(null)
  }
)

export const getCollectionDetail = createAsyncThunk(
  "GET_COLLECTION_DETAIL",
  async (id: string, { rejectWithValue, dispatch }) => {
    const res = await CollectionServices.getCollectionDetail(id)

    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data.data
    }

    return rejectWithValue(null)
  }
)

// export const getStatsCollectionDetail = createAsyncThunk(
//   "GET_STATS_COLLECTION",
//   async (params: any, { rejectWithValue, dispatch }) => {
//     dispatch(showLoading())
//     const res = await CollectionServices.getStatsCollection(params)
//     dispatch(hideLoading())

//     if (res.status === StatusCodeResponse.SUCCESS) {
//       return {
//         data: res.data.data,
//       }
//     }

//     return rejectWithValue(null)
//   }
// )
