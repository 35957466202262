import { getAuthorizationHeader } from "../../../utils/authentication"
import { getParamsForApiList } from "../../../utils/util"
import { get, patch } from "../../axiosClient"
import {
    GetLatestTransactionParams,
    GetLatestTransactionResponse
} from "./latestTransaction"

const latestTransactionServices = {
    getListTransaction(params: GetLatestTransactionParams): Promise<GetLatestTransactionResponse> {
        return get(`/market-place/transaction${getParamsForApiList(params)}`)
    },
}

export default latestTransactionServices
