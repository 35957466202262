import { createAsyncThunk } from "@reduxjs/toolkit"
import { StatusCodeResponse } from "../../../../network/constants"
import { UpdateProfileParams } from "../../../../network/myProfile/types"
import { UploadImageParams } from "../../../../network/uploadImage/type"
import { changeProfile } from "../../../myProfile/store/thunk"
import { uploadImageConstants } from "./../../../../constants/index"
import uploadImageServices from "../../../../network/uploadImage/services"
import { changeMessage } from "../../toastMessage/store/slice"
import { setTypeFile } from "./slice"
import { hideLoading, showLoading } from "../../loading/store/slice"
import { checkUploadSuccess, checkUploadFail } from "../checkUpload/store/slice"

export const uploadImage = createAsyncThunk(
  "UPLOAD_IMAGE",
  async (params: UploadImageParams, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await uploadImageServices.uploadImage(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.CREATED) {
        dispatch(checkUploadSuccess())
        dispatch(
          changeMessage({
            message: "Upload image successfully",
            statusCode: res.status,
          })
        )
        dispatch(setTypeFile(params.typeFile))
        return res.data
      } else {
        dispatch(
          changeMessage({
            message: "Upload image failed",
            statusCode: res.status,
          })
        )
        dispatch(checkUploadFail())
        return rejectWithValue(res.status)
      }
    } catch (error) {
      dispatch(
        changeMessage({
          message: "Upload image failed",
          statusCode: StatusCodeResponse.SYSTEM_ERROR,
        })
      )
      dispatch(checkUploadFail())
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)

export const uploadVideo: any = createAsyncThunk(
  "UPLOAD_VIDEO",
  async (params: any, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await uploadImageServices.uploadVideo(params)
    dispatch(hideLoading())
    try {
      if (res.status === StatusCodeResponse.CREATED) {
        dispatch(checkUploadSuccess())
        dispatch(
          changeMessage({
            message: "Upload video successfully",
            statusCode: res.status,
          })
        )
        dispatch(setTypeFile(params.typeFile))
        return res.data
      } else {
        dispatch(
          changeMessage({
            message: "Upload video failed",
            statusCode: res.status,
          })
        )
        dispatch(checkUploadFail())
        return rejectWithValue(res.status)
      }
    } catch (error) {
      dispatch(
        changeMessage({
          message: "Upload image failed",
          statusCode: StatusCodeResponse.SYSTEM_ERROR,
        })
      )
      dispatch(checkUploadFail())
      return rejectWithValue(StatusCodeResponse.SYSTEM_ERROR)
    }
  }
)
