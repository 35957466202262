import { createSlice } from "@reduxjs/toolkit"

import { Store } from "../../../../app/type"
import {
  Transaction,
  MetaListTransaction,
} from "../../../../network/homepage/latestTransaction/latestTransaction"
import { getListTransaction } from "./thunk"
import { LatestTransactionStore } from "./latestTransactions"

const initialState: LatestTransactionStore = {
  transactionList: <Transaction[]>[],
  metaTransaction: <MetaListTransaction>{},
}

const slice = createSlice({
  name: "latestTransaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListTransaction.fulfilled, (state, action) => {
      state.transactionList = JSON.parse(JSON.stringify(action.payload?.data))
      state.metaTransaction = {
        ...state.metaTransaction,
        limit: action.payload?.limit,
        page: action.payload?.page,
        totalRecord: action.payload?.totalRecord,
      }
    })
  },
})

export const selectTransactionList = (state: Store) =>
  state.latestTransaction.transactionList

export const selectMetaTransaction = (state: Store) =>
  state.latestTransaction.metaTransaction

export default slice.reducer
