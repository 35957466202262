// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.information-payment-wrapper .pd-1 {
  padding: 0em 1em; }

.information-payment-wrapper .anticon-up {
  cursor: pointer;
  color: #050505;
  display: flex;
  align-items: center; }
  .information-payment-wrapper .anticon-up :hover {
    opacity: 0.7; }

.information-payment-wrapper .cart-content-payment {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--grayscale-light-90, #e6e6e6);
  align-self: stretch;
  transition: all 0.5s ease; }
  .information-payment-wrapper .cart-content-payment .content-payment-total {
    padding: 1em 0;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--grayscale-light-90, #e6e6e6); }
    .information-payment-wrapper .cart-content-payment .content-payment-total .payment-total {
      flex: 1 1;
      display: flex;
      gap: 0.25em; }
  .information-payment-wrapper .cart-content-payment .cart-content-checkout {
    padding: 1em 1em;
    display: flex;
    justify-content: space-between;
    border-radius: 0.5em;
    background: var(--grayscale-light-94, #f0f0f0); }
  .information-payment-wrapper .cart-content-payment .content-payment-fee {
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    gap: 1em; }
    .information-payment-wrapper .cart-content-payment .content-payment-fee .payment-fee-stack {
      display: flex;
      justify-content: space-between; }
`, "",{"version":3,"sources":["webpack://./src/components/common/menu/headerMenu/components/dropdowns/informationPaymentStep.scss"],"names":[],"mappings":"AAAA;EAEI,gBAAgB,EAAA;;AAFpB;EAKI,eAAe;EACf,cAAc;EACd,aAAa;EACb,mBAAmB,EAAA;EARvB;IAUM,YAAY,EAAA;;AAVlB;EAcI,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,oDAAoD;EACpD,mBAAmB;EACnB,yBAAyB,EAAA;EAnB7B;IAsBM,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,2DAA2D,EAAA;IAzBjE;MA4BQ,SAAO;MACP,aAAa;MACb,WAAW,EAAA;EA9BnB;IAkCM,gBAAgB;IAChB,aAAa;IACb,8BAA8B;IAC9B,oBAAoB;IACpB,8CAA8C,EAAA;EAtCpD;IA0CM,cAAc;IAEd,aAAa;IACb,sBAAsB;IACtB,QAAQ,EAAA;IA9Cd;MAiDQ,aAAa;MACb,8BAA8B,EAAA","sourcesContent":[".information-payment-wrapper {\n  .pd-1 {\n    padding: 0em 1em;\n  }\n  .anticon-up {\n    cursor: pointer;\n    color: #050505;\n    display: flex;\n    align-items: center;\n    :hover {\n      opacity: 0.7;\n    }\n  }\n  .cart-content-payment {\n    display: flex;\n    flex-direction: column;\n    border-radius: 8px;\n    border: 1px solid var(--grayscale-light-90, #e6e6e6);\n    align-self: stretch;\n    transition: all 0.5s ease;\n\n    .content-payment-total {\n      padding: 1em 0;\n      display: flex;\n      flex-direction: row;\n      border-bottom: 1px solid var(--grayscale-light-90, #e6e6e6);\n\n      .payment-total {\n        flex: 1;\n        display: flex;\n        gap: 0.25em;\n      }\n    }\n    .cart-content-checkout {\n      padding: 1em 1em;\n      display: flex;\n      justify-content: space-between;\n      border-radius: 0.5em;\n      background: var(--grayscale-light-94, #f0f0f0);\n    }\n\n    .content-payment-fee {\n      padding: 1em 0;\n\n      display: flex;\n      flex-direction: column;\n      gap: 1em;\n\n      .payment-fee-stack {\n        display: flex;\n        justify-content: space-between;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
