import {
  selectSearchCollectionList,
  selectSearchNftList,
  selectSearchProfileList,
} from "@/components/homepage/auction/store/slice";
import {
  searchCollection,
  searchNfts,
  searchProfile,
} from "@/components/homepage/auction/store/thunk";
import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Avatar, Input, Typography } from "antd";
import { isAddress } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./search.scss";
import { Link } from "react-router-dom";

const EXPLORE_LINKS: any = {
  NFTs: "/explore?view=nfts",
  Collections: "/explore?view=collections",
  Users: "/explore?view=users",
};

const renderTitle = (type: string, setSelectSearch: any) => (
  <>
    <div className="flex gap-3 title-search">
      <span
        className={type === "NFTs" ? "active" : ""}
        onClick={() => setSelectSearch("NFTs")}
      >
        NFTs
      </span>
      <span
        className={type === "Collections" ? "active" : ""}
        onClick={() => setSelectSearch("Collections")}
      >
        Collections
      </span>
      <span
        className={type === "Users" ? "active" : ""}
        onClick={() => setSelectSearch("Users")}
      >
        Users
      </span>
    </div>
  </>
);

const renderItem = (type: string, data: any) => ({
  value: data?.value?.index,
  label: (
    <Link
      to={
        type === "Collections"
          ? `/collections/${data?.value?.index}/detail`
          : type === "Users"
          ? `/profile-view/${data?.value?.address}`
          : `/nft/detail/${data?.value?.index}`
      }
    >
      <div className="collectionCell-wrapper">
        {data?.value?.isVideo ? (
          <div className="video-container">
            <iframe
              className="collectionCell-video"
              src={`${data?.value?.image}?autoplay=1&loop=1&autopause=0&controls=0&muted=1`}
              title={data?.value?.name}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            ></iframe>
          </div>
        ) : (
          <Avatar
            className="collectionCell-avatar"
            shape="square"
            src={data?.value?.image}
          />
        )}

        <Typography className="collectionCell-title item-search">
          <p className="text-ellipsis-custom lg:text-base text-xs">
            {data?.value?.name}
          </p>
          <span className="text-ellipsis-custom lg:text-base text-xs">
            {data?.value?.description} {type === "Users" ? "followers" : ""}
          </span>
        </Typography>
      </div>
    </Link>
  ),
});
const renderFooter = (
  isEmpty: boolean,
  selectSearch: string,
  keyWordSearch: string
) => ({
  value: "footer",
  label: (
    <>
      {isEmpty ? (
        <div className="footer-search">
          We couldn’t find anything with this criteria
        </div>
      ) : (
        <Link to={`${EXPLORE_LINKS[selectSearch]}&name=${keyWordSearch}`}>
          <div className="footer-search">Press Enter to search all items</div>
        </Link>
      )}
    </>
  ),
});

const SearchInput = ({
  visibleMobileSearch,
  whiteBg = false,
}: {
  visibleMobileSearch?: boolean;
  whiteBg?:boolean;
}) => {
  const dispatch = useDispatch();
  const dataProfile = useSelector(selectSearchProfileList);
  const dataCollection = useSelector(selectSearchCollectionList);
  const dataNft = useSelector(selectSearchNftList);

  const paramsSearch = {
    skip: 0,
    limit: 10,
    sort: {
      field: "created_at",
      order: -1,
    },
    filter: [
      {
        name: "",
        value: "",
        operator: "regex",
        mode: "swm",
      },
      {
        name: "status",
        value: "SHOW",
        operator: "eq",
      },
    ],
  };

  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>();
  const [selectSearch, setSelectSearch] = useState("NFTs");
  const [optionsData, setOptionsData] = useState<any>([]);
  const [keyWordSearch, setKeyWordSearch] = useState<any>();

  const clearDataSearch = () => {
    let dataOptions: any = [];
    setKeyWordSearch("");
    setOptionsData([
      dataOptions,
      renderFooter(false, selectSearch, keyWordSearch),
    ]);
    setIsSearch(false);
  };

  const onSearch = async (data: any) => {
    try {
      const value = data?.toLowerCase();
      paramsSearch.filter[0].value = value;

      if (selectSearch === "NFTs") {
        if (isAddress(value)) {
          paramsSearch.filter[0].name = "ownerAddress";
          await dispatch(searchNfts(paramsSearch));
        } else {
          paramsSearch.filter[0].name = "name";
          await dispatch(searchNfts(paramsSearch));
        }
      } else if (selectSearch === "Users") {
        if (isAddress(value)) {
          await dispatch(
            searchProfile({
              address: value,
            })
          );
        } else {
          paramsSearch.filter[0].name = "displayName";
          await dispatch(searchProfile(paramsSearch));
        }
      } else if (selectSearch === "Collections") {
        if (isAddress(value)) {
          paramsSearch.filter[0].name = "contractAddress";
          await dispatch(searchCollection(paramsSearch));
        } else {
          paramsSearch.filter[0].name = "name";
          await dispatch(searchCollection(paramsSearch));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    setOptionsData([[], renderFooter(true, selectSearch, keyWordSearch)]);

    onSearch(keyWordSearch);
  }, [keyWordSearch, selectSearch]);

  useEffect(() => {
    let dataOptions: any = [];
    if (dataProfile) {
      if (dataProfile?.docs?.length > 0) {
        dataOptions = dataProfile?.docs.map((item: any) => {
          return renderItem(selectSearch, {
            label: item?.bio,
            value: {
              index: item?.id,
              name: item?.displayName,
              address: item?.user?.walletAddress,
              description: item?.description
                ? item?.description
                : item?.followers,
              image: item?.avatar,
            },
          });
        });
        setOptionsData([
          ...dataOptions,
          renderFooter(false, selectSearch, keyWordSearch),
        ]);
      } else {
        const itemUser = {
          label: dataProfile?.bio,
          value: {
            index: dataProfile?.id,
            name: dataProfile?.displayName,
            address: dataProfile?.user?.walletAddress,
            description: dataProfile?.description
              ? dataProfile?.description
              : dataProfile?.user?.walletAddress,
            image: dataProfile?.avatar,
          },
        };
        setOptionsData([
          renderItem(selectSearch, itemUser),
          renderFooter(false, selectSearch, keyWordSearch),
        ]);
      }
    } else {
      setOptionsData([
        dataOptions,
        renderFooter(true, selectSearch, keyWordSearch),
      ]);
    }
  }, [dataProfile, selectSearch]);

  useEffect(() => {
    let dataOptions: any = [];
    if (dataCollection) {
      if (dataCollection?.docs?.length > 0) {
        dataOptions = dataCollection?.docs.map((item: any) => {
          return renderItem(selectSearch, {
            label: item?.name,
            value: {
              index: item?.id,
              name: item?.displayName ? item?.displayName : item?.name,
              address: item?.contractAddress,
              description: item?.description,
              image: item?.logoUrl,
            },
          });
        });
        setOptionsData([
          ...dataOptions,
          renderFooter(false, selectSearch, keyWordSearch),
        ]);
      }
    } else {
      setOptionsData([
        dataOptions,
        renderFooter(true, selectSearch, keyWordSearch),
      ]);
    }
  }, [dataCollection, selectSearch]);

  useEffect(() => {
    let dataOptions: any = [];
    if (dataNft) {
      if (dataNft?.docs?.length > 0) {
        dataOptions = dataNft?.docs.map((item: any) => {
          return renderItem(selectSearch, {
            label: item?.name,
            value: {
              index: item?.id,
              name: item?.name,
              address: item?.frozenAddress,
              description: item?.description,
              image:
                item?.animationUrl && item?.animationUrl !== "Unknown"
                  ? item?.animationUrl
                  : item?.mediaUrl && item?.mediaUrl !== "Unknown"
                  ? item?.mediaUrl
                  : require("@/assets/images/default-avatar.png"),
              isVideo:
                item?.animationUrl && item?.animationUrl !== "Unknown"
                  ? true
                  : false,
            },
          });
        });
        setOptionsData([
          ...dataOptions,
          renderFooter(false, selectSearch, keyWordSearch),
        ]);
      }
    } else {
      setOptionsData([
        dataOptions,
        renderFooter(true, selectSearch, keyWordSearch),
      ]);
    }
  }, [dataNft, selectSearch]);

  useEffect(() => {
    if (!visibleMobileSearch) clearDataSearch();
  }, [visibleMobileSearch]);

  const options = [
    {
      label: renderTitle(selectSearch, setSelectSearch),
      options:
        isSearch && optionsData[0]?.value
          ? optionsData
          : [
              renderFooter(
                isSearch && !optionsData[0]?.value,
                selectSearch,
                keyWordSearch
              ),
            ],
    },
  ];

  function handleSearch(e: any, changeTypeSearch: boolean) {
    const value = e.target.value;
    if (value !== "") {
      onSearch(value);
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    setIsOpen(true);
    !changeTypeSearch && setKeyWordSearch(value);
  }

  return (
    <>
      <AutoComplete
        className="container-search certain-category-search-dropdown"
        getPopupContainer={(trigger) => trigger.parentElement}
        dropdownMatchSelectWidth={500}
        options={options}
        listHeight={442}
        value={keyWordSearch}
        onBlur={() => setIsOpen(false)}
        open={isOpen}
        onFocus={() => setIsOpen(true)}
        dropdownClassName="search-dropdown"
      >
        <Input
          className={"input-search" + ` ${whiteBg ? "bg-light" : ""}`}
          placeholder="Search for items, collections or users"
          prefix={<SearchOutlined />}
          suffix={
            <div className="icon-right" onClick={clearDataSearch}>
              {isSearch ? "X" : "/"}
            </div>
          }
          onInput={(e) => handleSearch(e, false)}
        />
      </AutoComplete>
    </>
  );
};

export default SearchInput;
