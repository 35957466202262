// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.tabs-select .ant-tabs {
  height: 100%; }

div.tabs-select .ant-tabs-nav {
  height: 100%;
  margin: 0; }
  div.tabs-select .ant-tabs-nav::before {
    border: unset !important; }

div.tabs-select .ant-tabs-nav-wrap {
  color: #45cdf8;
  padding: 2px;
  background: rgba(16, 13, 31, 0.3);
  border: 2px solid #3ddcec;
  border-radius: 30px;
  box-sizing: border-box; }

div.tabs-select .ant-tabs-nav-list {
  width: 100%;
  display: flex; }

div.tabs-select .ant-tabs-tab {
  padding: 4px 16px;
  margin: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  min-width: 80px;
  min-height: 30px; }

div.tabs-select .ant-tabs-tab-btn {
  font-weight: bold;
  line-height: 20px;
  white-space: pre-wrap; }

div.tabs-select .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important; }

div.tabs-select .ant-tabs-ink-bar {
  background: linear-gradient(90deg, #384cff 0%, #2486f9 0.01%, #3ddcec 100%);
  border-radius: 80px;
  height: 100% !important;
  z-index: -1; }
`, "",{"version":3,"sources":["webpack://./src/components/common/tabSelect/styles.scss"],"names":[],"mappings":"AAAA;EAEI,YAAY,EAAA;;AAFhB;EAMI,YAAY;EACZ,SAAS,EAAA;EAPb;IAUM,wBAAwB,EAAA;;AAV9B;EAeI,cAAc;EACd,YAAY;EACZ,iCAAiC;EACjC,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB,EAAA;;AApB1B;EAwBI,WAAW;EACX,aAAa,EAAA;;AAzBjB;EA8BI,iBAAiB;EACjB,SAAS;EACT,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,eAAe;EACf,gBAAgB,EAAA;;AArCpB;EA6CI,iBAAiB;EACjB,iBAAiB;EACjB,qBAAqB,EAAA;;AA/CzB;EAoDM,yBAAyB,EAAA;;AApD/B;EA0DI,2EAA2E;EAC3E,mBAAmB;EACnB,uBAAuB;EACvB,WAAW,EAAA","sourcesContent":["div.tabs-select {\n  .ant-tabs {\n    height: 100%;\n  }\n\n  .ant-tabs-nav {\n    height: 100%;\n    margin: 0;\n\n    &::before {\n      border: unset !important;\n    }\n  }\n\n  .ant-tabs-nav-wrap {\n    color: #45cdf8;\n    padding: 2px;\n    background: rgba(16, 13, 31, 0.3);\n    border: 2px solid #3ddcec;\n    border-radius: 30px;\n    box-sizing: border-box;\n  }\n\n  .ant-tabs-nav-list {\n    width: 100%;\n    display: flex;\n  }\n\n  // item tabs\n  .ant-tabs-tab {\n    padding: 4px 16px;\n    margin: 0;\n    display: flex;\n    text-align: center;\n    justify-content: center;\n    width: 100%;\n    min-width: 80px;\n    min-height: 30px;\n    // &:hover,\n    // &:active {\n    //   color: $primary-color;\n    // }\n  }\n\n  .ant-tabs-tab-btn {\n    font-weight: bold;\n    line-height: 20px;\n    white-space: pre-wrap;\n  }\n\n  .ant-tabs-tab-active {\n    .ant-tabs-tab-btn {\n      color: #ffffff !important;\n    }\n  }\n\n  // animation\n  .ant-tabs-ink-bar {\n    background: linear-gradient(90deg, #384cff 0%, #2486f9 0.01%, #3ddcec 100%);\n    border-radius: 80px;\n    height: 100% !important;\n    z-index: -1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
