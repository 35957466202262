import "./styles.scss"

const DesignByYourWay = () => {
  return (
   // <div className="text-design">
    <div>
      {/* <h2>DESIGN LIFE BY YOUR WAY!</h2> */}
    </div>
  )
}

export default DesignByYourWay
