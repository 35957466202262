import { StatusCodeResponse } from "./../constants"
import { createAsyncThunk } from "@reduxjs/toolkit"
import categoryNFTServices from "./services"
import {
  hideLoading,
  showLoading,
} from "../../components/common/loading/store/slice"

export const getCategoryNFT = createAsyncThunk(
  "get/CATEGORY_NFT",
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await categoryNFTServices.getCategoryNFT()
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      return res.data.data
    }
    return rejectWithValue(res.status)
  }
)
