import { selectMyProfile } from "@/components/myProfile/store/slice"
import CollectionServices from "@/network/collection/services"
import { StatusCodeResponse } from "@/network/constants"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"

const useCheckWatchList = (id: string, isWatchListData = false) => {
  const myProfile = useSelector(selectMyProfile)
  const [data, setData] = useState(false)

  const fetchData = useCallback(async () => {
    if (id && !isWatchListData) {
      try {
        await CollectionServices.checkWatchList(id)
          .then((res: any) => {
            if (res?.status === StatusCodeResponse.SUCCESS && res?.data?.data) {
              setData(true)
            }
          })
          .catch((e: any) => {
            setData(false)
          })
      } catch (error) {
        setData(false)
      }
    }
  }, [id, isWatchListData])

  useEffect(() => {
    if (myProfile) {
      fetchData()
    }
  }, [fetchData, myProfile])

  return { checkWatchList: data, getCheckWatchList: fetchData }
}

export default useCheckWatchList
