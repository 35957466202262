import { createSlice } from "@reduxjs/toolkit"

import { Store } from "../../../../app/type"
import { Collection } from "../../../../network/collection/type"
import { MetaAuction } from "../../../../network/homepage/homeAuction/type"
import {
  getAuction,
  getBanner,
  searchCollection,
  searchNfts,
  searchProfile,
} from "./thunk"
import { HomeAuctionStore } from "./type"
import {
  getTrendingCollection,
  getTopCollection,
  changeTypeSetList,
} from "../../trending/store/thunk"

const initialState: HomeAuctionStore = {
  auctionList: <Collection[]>[],
  metaAuction: <MetaAuction>{},
  banner: "",
  searchProfileList: "",
  searchCollectionList: "",
  searchNftsList: "",
  trendingCollectionList: "",
  topCollectionList: "",
  typeGetList: "Trending",
}

const slice = createSlice({
  name: "homeAuction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAuction.fulfilled, (state, action) => {
      if (action.type === "home/AUCTION/fulfilled") {
        state.auctionList = action.payload.data
        state.metaAuction = {
          ...state.metaAuction,
          limit: action.payload?.limit,
          page: action.payload?.page,
          totalRecord: action.payload?.totalRecord,
        }
      }
    })

    builder.addCase(getBanner.fulfilled, (state, action) => {
      if (action.type === "banner/SEARCH/fulfilled") {
        state.banner = action.payload
      }
    })

    builder.addCase(searchProfile.fulfilled, (state, action) => {
      if (action.type === "search/PROFILE/fulfilled") {
        state.searchProfileList = action.payload
      }
    })
    builder.addCase(searchCollection.fulfilled, (state, action) => {
      if (action.type === "search/COLLECTION/fulfilled") {
        state.searchCollectionList = action.payload
      }
    })
    builder.addCase(searchNfts.fulfilled, (state, action) => {
      if (action.type === "search/NFT/fulfilled") {
        state.searchNftsList = action.payload
      }
    })

    builder.addCase(getTrendingCollection.fulfilled, (state, action) => {
      if (action.type === "home/TRENDING/fulfilled") {
        state.trendingCollectionList = action.payload
      }
    })
    builder.addCase(getTopCollection.fulfilled, (state, action) => {
      if (action.type === "home/TOP/fulfilled") {
        state.topCollectionList = action.payload
      }
    })
    builder.addCase(changeTypeSetList.fulfilled, (state, action) => {
      if (action.type === "home/SET_LIST/fulfilled") {
        state.typeGetList = action.payload
      }
    })
  },
})

export const selectAuctionList = (state: Store) => state.homeAuction.auctionList
export const selectMetaAuction = (state: Store) => state.homeAuction.metaAuction
export const selectBanner = (state: Store) => state.homeAuction.banner

export const selectSearchProfileList = (state: Store) =>
  state.homeAuction.searchProfileList
export const selectSearchCollectionList = (state: Store) =>
  state.homeAuction.searchCollectionList
export const selectSearchNftList = (state: Store) =>
  state.homeAuction.searchNftsList

export const selectTrendingCollectionList = (state: Store) =>
  state.homeAuction.trendingCollectionList
export const selectTopCollectionList = (state: Store) =>
  state.homeAuction.topCollectionList
export const selectTypeGetList = (state: Store) => state.homeAuction.typeGetList
export default slice.reducer
