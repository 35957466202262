import { getAuthorizationHeader } from "../../utils/authentication"
import { uploadFile } from "../axiosClient"
import { UploadImageParams, UploadImageResponse } from "./type"

const uploadImageServices = {
  uploadImage(params: UploadImageParams): Promise<UploadImageResponse> {
    return uploadFile("upload", params.file, {
      headers: {
        ...getAuthorizationHeader(),
        "Content-Type": "multipart/form-data",
      },
      timeout: 100000,
    })
  },

  uploadVideo(params: any): Promise<any> {
    return uploadFile("upload/vimeo", params.file, {
      headers: {
        ...getAuthorizationHeader(),
        "Content-Type": "multipart/form-data",
      },
      timeout: 100000,
    })
  },
}

export default uploadImageServices
