// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 991px) {
  .modal-add-fund {
    position: fixed;
    top: auto;
    bottom: 0;
    padding-bottom: 0;
    min-width: 100%;
    margin: 0; } }

.modal-add-fund .ant-modal-content {
  border-radius: 16px 16px 0 0;
  background: #fefefe; }
  @media (min-width: 992px) {
    .modal-add-fund .ant-modal-content {
      border-radius: 16px; } }

.modal-add-fund .ant-modal-close svg {
  width: 24px;
  height: 24px; }
  .modal-add-fund .ant-modal-close svg path {
    fill: #050505; }

.modal-add-fund .ant-modal-header {
  border-radius: 16px;
  border-bottom: 0;
  padding: 16px;
  padding-top: 24px;
  padding-bottom: 8px; }
  @media (min-width: 991px) {
    .modal-add-fund .ant-modal-header {
      padding: 32px;
      padding-bottom: 12px; } }
  .modal-add-fund .ant-modal-header .ant-modal-title {
    color: #050505;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: 700; }
    @media (min-width: 991px) {
      .modal-add-fund .ant-modal-header .ant-modal-title {
        font-size: 24px;
        line-height: 32px; } }

.modal-add-fund .ant-modal-body {
  padding: 16px 16px 24px; }
  @media (min-width: 991px) {
    .modal-add-fund .ant-modal-body {
      padding: 32px; } }
`, "",{"version":3,"sources":["webpack://./src/components/modalAddFund/styles.scss"],"names":[],"mappings":"AACE;EADF;IAEI,eAAe;IACf,SAAS;IACT,SAAS;IACT,iBAAiB;IACjB,eAAe;IACf,SAAS,EAAA,EAwDZ;;AA/DD;EAWI,4BAA4B;EAC5B,mBAAmB,EAAA;EAEnB;IAdJ;MAeM,mBAAmB,EAAA,EAEtB;;AAjBH;EAqBM,WAAW;EACX,YAAY,EAAA;EAtBlB;IAyBQ,aAAa,EAAA;;AAzBrB;EA+BI,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,iBAAiB;EACjB,mBAAmB,EAAA;EAEnB;IArCJ;MAsCM,aAAa;MACb,oBAAoB,EAAA,EAevB;EAtDH;IA2CM,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB,EAAA;IAEhB;MAjDN;QAkDQ,eAAe;QACf,iBAAiB,EAAA,EAEpB;;AArDL;EAyDI,uBAAuB,EAAA;EAEvB;IA3DJ;MA4DM,aAAa,EAAA,EAEhB","sourcesContent":[".modal-add-fund {\n  @media (max-width: 991px) {\n    position: fixed;\n    top: auto;\n    bottom: 0;\n    padding-bottom: 0;\n    min-width: 100%;\n    margin: 0;\n  }\n\n  .ant-modal-content {\n    border-radius: 16px 16px 0 0;\n    background: #fefefe;\n\n    @media (min-width: 992px) {\n      border-radius: 16px;\n    }\n  }\n\n  .ant-modal-close {\n    svg {\n      width: 24px;\n      height: 24px;\n\n      path {\n        fill: #050505;\n      }\n    }\n  }\n\n  .ant-modal-header {\n    border-radius: 16px;\n    border-bottom: 0;\n    padding: 16px;\n    padding-top: 24px;\n    padding-bottom: 8px;\n\n    @media (min-width: 991px) {\n      padding: 32px;\n      padding-bottom: 12px;\n    }\n\n    .ant-modal-title {\n      color: #050505;\n      font-size: 16px;\n      line-height: 24px;\n      font-style: normal;\n      font-weight: 700;\n\n      @media (min-width: 991px) {\n        font-size: 24px;\n        line-height: 32px;\n      }\n    }\n  }\n\n  .ant-modal-body {\n    padding: 16px 16px 24px;\n\n    @media (min-width: 991px) {\n      padding: 32px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
