import { useCallback, useEffect, useState } from "react";
import useOPVPriceContract from "./useOPVPriceContract";
import { get } from "@/network/axiosClient";
import { StatusCodeResponse } from "@/network/constants";

const useGetOpvPrice = (): any => {
  const contractMethod = useOPVPriceContract();

  const [data, setData] = useState<any>(0);

  const getData = useCallback(async () => {
    try {
      const result: any = await get("/coins/price/mbc-usdt");
      if (result?.status === StatusCodeResponse.SUCCESS) {
        setData(+result?.data?.data[0]?.p);
      }
    } catch (error) {
      console.error("useGetOpvPrice", error);
    }
    // }
  }, []);

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [getData]);

  return [data, contractMethod];
};

export default useGetOpvPrice;
