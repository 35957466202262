// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Arabic Pro";
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body > iframe {
  display: none;
}

@media (min-width: 768px) {
  .tablet-show {
    display: block !important;
  }
  .mobile-show {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .tablet-show {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
}

.w-75 {
  width: 75%;
}

.div-center {
  margin-left: auto;
  margin-right: auto;
}

.text-bold {
  font-weight: bold;
}

.ant-drawer-header {
  background: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,yBAAyB;EACzB,gBAAgB;EAChB,mCAAmC;EACnC,kCAAkC;EAClC,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,wBAAwB;EAC1B;AACF;AACA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Arabic Pro\";\n  font-weight: 500;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  overflow-x: hidden;\n}\n\nbody > iframe {\n  display: none;\n}\n\n@media (min-width: 768px) {\n  .tablet-show {\n    display: block !important;\n  }\n  .mobile-show {\n    display: none !important;\n  }\n}\n@media (max-width: 767px) {\n  .tablet-show {\n    display: none !important;\n  }\n  .mobile-show {\n    display: block !important;\n  }\n}\n\n.w-75 {\n  width: 75%;\n}\n\n.div-center {\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.text-bold {\n  font-weight: bold;\n}\n\n.ant-drawer-header {\n  background: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
