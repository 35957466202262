import { Typography } from "antd"
import "./informationPaymentStep.scss"
import { UpOutlined } from "@ant-design/icons"
import { STEP_CART } from "./CartDropdown"
import { useState } from "react"

interface IProcessToPaymentStepProps {
	step: STEP_CART
	totalOPV: number
	totalPrice: number
}

const InformationPaymentStep = ({
	step,
	totalOPV = 0,
	totalPrice = 0,
}: IProcessToPaymentStepProps) => {
	const [isVisible, setIsVisible] = useState(false)
	const handleVisible = () => {
		setIsVisible(!isVisible)
	}
	if (step === STEP_CART.PROCESS_PAY) {
		return (
			<div className="information-payment-wrapper">
				<div className="cart-content-payment pd-1">
					<div className="content-payment-total ">
						<div className="payment-total">
							<Typography>Total</Typography>
							<Typography>
								{totalOPV || 0} MBC ({totalPrice || 0}$)
							</Typography>
						</div>
						<UpOutlined
							onClick={handleVisible}
							style={{ transform: isVisible ? "rotate(180deg)" : "none" }}
						/>
					</div>
					{!isVisible && (
						<div className="content-payment-fee">
							<div className="payment-fee-stack">
								<Typography>Listing price</Typography>
								<Typography>
									{" "}
									{totalOPV || 0} MBC ({totalPrice || 0}$)
								</Typography>
							</div>
							{/* <div className="payment-fee-stack">
                <Typography>Gas fee</Typography>
                <Typography> 1.405 MBC (140$)</Typography>
              </div> */}
						</div>
					)}
				</div>
			</div>
		)
	}
	return (
		<div className="information-payment-wrapper">
			<div className="cart-content-payment ">
				<div className="cart-content-checkout">
					<Typography>Total</Typography>
					{totalOPV} MBC ({totalPrice}$)
				</div>
			</div>
		</div>
	)
}
export default InformationPaymentStep
