import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  hideLoading,
  showLoading,
} from "../../components/common/loading/store/slice"
import { changeMessage } from "../../components/common/toastMessage/store/slice"
import { StatusCodeResponse } from "../constants"
import NFTServices from "./services"
import { PostNFTRequest } from "./type"

export const createNFT = createAsyncThunk(
  "post/CREATE_NFT",
  async (params: PostNFTRequest, { rejectWithValue, dispatch }) => {
    dispatch(showLoading())
    const res = await NFTServices.createNFT(params)
    dispatch(hideLoading())
    if (res.status === StatusCodeResponse.SUCCESS) {
      dispatch(
        changeMessage({
          message: "Create NFT successfully",
          statusCode: res.status,
        })
      )

      return res.data?.data
    } else {
      dispatch(
        changeMessage({
          message: "Create NFT failed",
          statusCode: res.status,
        })
      )
      return rejectWithValue(res.status)
    }
  }
)
